<template>
  <div id="js-loader" class="loader">
    <div class="loader-animation"></div>
  </div>
  <div class="page_header">
    <div class="mv_cloud">
      <div class="right_area">
        <div class="img04">
          <img
            src="@/assets/front_component/images/assets/back02.png"
            alt="雲"
          />
        </div>
      </div>
      <div class="left_area">
        <div class="img01">
          <img
            src="@/assets/front_component/images/assets/back01.png"
            alt="雲"
          />
        </div>
      </div>
    </div>
    <div class="en_ttl en"><span>STAFF</span></div>
    <div class="content_wrap">
      <h2>役員・社員紹介</h2>
      <div class="mascot_wrap">
        <img
          src="@/assets/front_component/images/assets/meguru05.png"
          alt="めぐる君"
        />
      </div>
    </div>
  </div>
  <main>
    <div class="section_all_wrap">
      <div class="content_in_wrap">
        <div class="tab_wrap">
          <ul class="tab_list">
            <li class="yakuin active" @click="officerList(officer_id)">
              <div class="icon_wrap">
                <img
                  src="@/assets/front_component/images/assets/staff01_out.png"
                  alt="役員"
                  class="mouse_out"
                />
                <img
                  src="@/assets/front_component/images/assets/staff01_over.png"
                  alt="役員"
                  class="mouse_over"
                />
                <img
                  src="@/assets/front_component/images/assets/staff01_active.png"
                  alt="役員"
                  class="mouse_active"
                />
              </div>
              <h3>経営企画室</h3>
            </li>
            <li class="toukatu" @click="internalList(internalControl_id)">
              <div class="icon_wrap">
                <img
                  src="@/assets/front_component/images/assets/staff02_out.png"
                  alt="内部統括部"
                  class="mouse_out"
                />
                <img
                  src="@/assets/front_component/images/assets/staff02_over.png"
                  alt="内部統括部"
                  class="mouse_over"
                />
                <img
                  src="@/assets/front_component/images/assets/staff02_active.png"
                  alt="内部統括部"
                  class="mouse_active"
                />
              </div>
              <h3>内部統括部</h3>
            </li>
            <li class="eigyo" @click="csList(cs_id)">
              <div class="icon_wrap">
                <img
                  src="@/assets/front_component/images/assets/staff03_out.png"
                  alt="CS 営業部"
                  class="mouse_out"
                />
                <img
                  src="@/assets/front_component/images/assets/staff03_over.png"
                  alt="CS 営業部"
                  class="mouse_over"
                />
                <img
                  src="@/assets/front_component/images/assets/staff03_active.png"
                  alt="CS 営業部"
                  class="mouse_active"
                />
              </div>
              <h3>CS 営業部</h3>
            </li>
            <li class="control" @click="controlList(control_id)">
              <div class="icon_wrap">
                <img
                  src="@/assets/front_component/images/assets/staff04_out.png"
                  alt="コントロール部"
                  class="mouse_out"
                />
                <img
                  src="@/assets/front_component/images/assets/staff04_over.png"
                  alt="コントロール部"
                  class="mouse_over"
                />
                <img
                  src="@/assets/front_component/images/assets/staff04_active.png"
                  alt="コントロール部"
                  class="mouse_active"
                />
              </div>
              <h3>コントロール部</h3>
            </li>
            <li class="area" @click="areaList(areaService_id)">
              <div class="icon_wrap">
                <img
                  src="@/assets/front_component/images/assets/staff05_out.png"
                  alt="エリアサービス部"
                  class="mouse_out"
                />
                <img
                  src="@/assets/front_component/images/assets/staff05_over.png"
                  alt="エリアサービス部"
                  class="mouse_over"
                />
                <img
                  src="@/assets/front_component/images/assets/staff05_active.png"
                  alt="エリアサービス部"
                  class="mouse_active"
                />
              </div>
              <h3>エリアサービス部</h3>
            </li>
            <li class="kankyo" @click="environmentList(environmentService_id)">
              <div class="icon_wrap">
                <img
                  src="@/assets/front_component/images/assets/staff06_out.png"
                  alt="環境サービス部"
                  class="mouse_out"
                />
                <img
                  src="@/assets/front_component/images/assets/staff06_over.png"
                  alt="環境サービス部"
                  class="mouse_over"
                />
                <img
                  src="@/assets/front_component/images/assets/staff06_active.png"
                  alt="環境サービス部"
                  class="mouse_active"
                />
              </div>
              <h3>環境サービス部</h3>
            </li>
            <li class="risaikuru" @click="recyclingList(recycling_id)">
              <div class="icon_wrap">
                <img
                  src="@/assets/front_component/images/assets/staff07_out.png"
                  alt="リサイクル部"
                  class="mouse_out"
                />
                <img
                  src="@/assets/front_component/images/assets/staff07_over.png"
                  alt="リサイクル部"
                  class="mouse_over"
                />
                <img
                  src="@/assets/front_component/images/assets/staff07_active.png"
                  alt="リサイクル部"
                  class="mouse_active"
                />
              </div>
              <h3>リサイクル部</h3>
            </li>
          </ul>
        </div>
      </div>

      <div class="content_in_wrap">
        <div id="breadcrumb">
          <ul class="breadcrumb_list">
            <li>
              <router-link :to="{ name: 'Front top' }">ホーム</router-link>
            </li>
            <li><span>役員・社員紹介</span></li>
          </ul>
        </div>
        <div class="content">
          <div class="innar">
            <div v-if="phone" class="link_list yakuin active">
              <div v-if="chairman_show">
                <h3 class="ttl_04">会長</h3>
                <div class="staff_wrap">
                  <div class="staff_list big">
                    <div
                      v-for="(items, line_index) in chairman_array"
                      :key="line_index"
                    >
                      <div class="staff_line">
                        <div
                          class="staff_item"
                          :data-wrapper="line_index + 'chairman'"
                          :data-modal="'modal' + item_index"
                          v-for="(item, item_index) in items"
                          :key="item_index"
                        >
                          <div
                            class="user_wrap"
                            @click="getChallageSdgsByUserId(item.id)"
                          >
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage: 'url(' + item.imgpath + ')',
                              }"
                              v-if="item.imgpath"
                            ></div>
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage:
                                  'url(' +
                                  require('@/assets/front_component/images/test/test04.png') +
                                  ')',
                              }"
                              v-else
                            ></div>
                            <div class="user_name">
                              <p class="name">
                                {{ item.last_name }} {{ item.first_name }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        :id="'function_wrapper_' + line_index + 'chairman'"
                        class="
                          functions_detail_wrapper
                          approvel-sdgs-challage-wrapper
                          approvel-sdgs-challage-wrapper
                        "
                      >
                        <div
                          class="functions_detail"
                          :class="'modal' + item_index"
                          v-for="(item, item_index) in items"
                          :key="item_index"
                        >
                          <div class="left_area">
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage: 'url(' + item.imgpath + ')',
                              }"
                              v-if="item.imgpath"
                            ></div>
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage:
                                  'url(' +
                                  require('@/assets/front_component/images/test/test04.png') +
                                  ')',
                              }"
                              v-else
                            ></div>
                          </div>
                          <div class="right_area">
                            <div class="txt_wrap">
                              <div class="group">代表取締役社長</div>
                              <div class="name">
                                {{ item.last_name }} {{ item.first_name }}
                              </div>
                              <p
                                class="txt"
                                v-html="htmlText(item.introduction_com)"
                              ></p>
                            </div>
                          </div>

                          <div
                            v-if="sdgsChallengeApprovedLoader == true"
                            class="p_t50 p_b50"
                          >
                            <div class="half-circle-spinner">
                              <div class="circle circle-1"></div>
                              <div class="circle circle-2"></div>
                            </div>
                          </div>
                          <template v-if="sdgsChallgeApproved.length > 0">
                            <hr />
                            <div
                              class="
                                sdgs-challage-approvel sdgs-challage-approvel-sp
                              "
                            >
                              <div class="thisterm_wrap">
                                <div class="thisterm_head">
                                  <h3 class="thisterm_ttl challenge">
                                    チャレンジシート
                                  </h3>
                                </div>
                                <ol class="challge-ul challge-ul-sp">
                                  <template
                                    v-for="sdgsChallge in sdgsChallgeApproved"
                                  >
                                    <li
                                      :key="sdgsChallge.id"
                                      v-if="sdgsChallge.type == 'challenge'"
                                    >
                                      <div v-html="sdgsChallge.content"></div>
                                    </li>
                                  </template>
                                </ol>
                              </div>
                            </div>
                            <hr />
                            <div
                              class="
                                sdgs-challage-approvel sdgs-challage-approvel-sp
                              "
                            >
                              <div class="thisterm_wrap">
                                <div class="thisterm_head">
                                  <h3 class="thisterm_ttl sdgs">
                                    SDGs個人目標
                                  </h3>
                                </div>
                                <ol class="challge-ul challge-ul-sp">
                                  <template
                                    v-for="sdgsChallge in sdgsChallgeApproved"
                                  >
                                    <li
                                      :key="sdgsChallge.id"
                                      v-if="sdgsChallge.type == 'sdgs'"
                                    >
                                      <div v-html="sdgsChallge.content"></div>
                                    </li>
                                  </template>
                                </ol>
                              </div>
                            </div>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="ceo_show">
                <h3 class="ttl_04">代表取締役社長</h3>
                <div class="staff_wrap">
                  <div class="staff_list big">
                    <div
                      v-for="(items, line_index) in ceo_array"
                      :key="line_index"
                    >
                      <div class="staff_line">
                        <div
                          class="staff_item"
                          :data-wrapper="line_index + 'ceo'"
                          :data-modal="'modal' + item_index"
                          v-for="(item, item_index) in items"
                          :key="item_index"
                        >
                          <div
                            class="user_wrap"
                            @click="getChallageSdgsByUserId(item.id)"
                          >
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage: 'url(' + item.imgpath + ')',
                              }"
                              v-if="item.imgpath"
                            ></div>
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage:
                                  'url(' +
                                  require('@/assets/front_component/images/test/test04.png') +
                                  ')',
                              }"
                              v-else
                            ></div>
                            <div class="user_name">
                              <p class="name">
                                {{ item.last_name }} {{ item.first_name }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        :id="'function_wrapper_' + line_index + 'ceo'"
                        class="
                          functions_detail_wrapper
                          approvel-sdgs-challage-wrapper
                          approvel-sdgs-challage-wrapper
                        "
                      >
                        <div
                          class="functions_detail"
                          :class="'modal' + item_index"
                          v-for="(item, item_index) in items"
                          :key="item_index"
                        >
                          <div class="left_area">
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage: 'url(' + item.imgpath + ')',
                              }"
                              v-if="item.imgpath"
                            ></div>
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage:
                                  'url(' +
                                  require('@/assets/front_component/images/test/test04.png') +
                                  ')',
                              }"
                              v-else
                            ></div>
                          </div>
                          <div class="right_area">
                            <div class="txt_wrap">
                              <div class="group">代表取締役社長</div>
                              <div class="name">
                                {{ item.last_name }} {{ item.first_name }}
                              </div>
                              <p
                                class="txt"
                                v-html="htmlText(item.introduction_com)"
                              ></p>
                            </div>
                          </div>

                          <div
                            v-if="sdgsChallengeApprovedLoader == true"
                            class="p_t50 p_b50"
                          >
                            <div class="half-circle-spinner">
                              <div class="circle circle-1"></div>
                              <div class="circle circle-2"></div>
                            </div>
                          </div>
                          <template v-if="sdgsChallgeApproved.length > 0">
                            <hr />
                            <div
                              class="
                                sdgs-challage-approvel sdgs-challage-approvel-sp
                              "
                            >
                              <div class="thisterm_wrap">
                                <div class="thisterm_head">
                                  <h3 class="thisterm_ttl challenge">
                                    チャレンジシート
                                  </h3>
                                </div>
                                <ol class="challge-ul challge-ul-sp">
                                  <template
                                    v-for="sdgsChallge in sdgsChallgeApproved"
                                  >
                                    <li
                                      :key="sdgsChallge.id"
                                      v-if="sdgsChallge.type == 'challenge'"
                                    >
                                      <div v-html="sdgsChallge.content"></div>
                                    </li>
                                  </template>
                                </ol>
                              </div>
                            </div>
                            <hr />
                            <div
                              class="
                                sdgs-challage-approvel sdgs-challage-approvel-sp
                              "
                            >
                              <div class="thisterm_wrap">
                                <div class="thisterm_head">
                                  <h3 class="thisterm_ttl sdgs">
                                    SDGs個人目標
                                  </h3>
                                </div>
                                <ol class="challge-ul challge-ul-sp">
                                  <template
                                    v-for="sdgsChallge in sdgsChallgeApproved"
                                  >
                                    <li
                                      :key="sdgsChallge.id"
                                      v-if="sdgsChallge.type == 'sdgs'"
                                    >
                                      <div v-html="sdgsChallge.content"></div>
                                    </li>
                                  </template>
                                </ol>
                              </div>
                            </div>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="new_officer_array.length != 0">
                <h3 class="ttl_04">役員</h3>
                <div class="staff_wrap">
                  <div class="staff_list big">
                    <div
                      v-for="(items, line_index) in new_officer_array"
                      :key="line_index"
                    >
                      <div class="staff_line">
                        <div
                          class="staff_item"
                          :data-wrapper="line_index + 'officer'"
                          :data-modal="'modal' + item_index"
                          v-for="(item, item_index) in items"
                          :key="item_index"
                        >
                          <div
                            class="user_wrap"
                            @click="getChallageSdgsByUserId(item.id)"
                          >
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage: 'url(' + item.imgpath + ')',
                              }"
                              v-if="item.imgpath"
                            ></div>
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage:
                                  'url(' +
                                  require('@/assets/front_component/images/test/test04.png') +
                                  ')',
                              }"
                              v-else
                            ></div>
                            <div class="user_name">
                              <p class="name">
                                {{ item.last_name }} {{ item.first_name }}
                              </p>
                              <br />
                              <span
                                class="director"
                                v-if="item.group && item.group.group_role"
                                >{{ item.group.group_role }}</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        :id="'function_wrapper_' + line_index + 'officer'"
                        class="
                          functions_detail_wrapper
                          approvel-sdgs-challage-wrapper
                        "
                      >
                        <div
                          class="functions_detail"
                          :class="'modal' + item_index"
                          v-for="(item, item_index) in items"
                          :key="item_index"
                        >
                          <div class="left_area">
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage: 'url(' + item.imgpath + ')',
                              }"
                              v-if="item.imgpath"
                            ></div>
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage:
                                  'url(' +
                                  require('@/assets/front_component/images/test/test04.png') +
                                  ')',
                              }"
                              v-else
                            ></div>
                          </div>
                          <div class="right_area">
                            <div class="txt_wrap">
                              <div class="group">役員</div>
                              <div class="name">
                                {{ item.last_name }} {{ item.first_name }}
                              </div>
                              <p
                                class="txt"
                                v-html="htmlText(item.introduction_com)"
                              ></p>
                            </div>
                          </div>
                          <div
                            v-if="sdgsChallengeApprovedLoader == true"
                            class="p_t50 p_b50"
                          >
                            <div class="half-circle-spinner">
                              <div class="circle circle-1"></div>
                              <div class="circle circle-2"></div>
                            </div>
                          </div>
                          <template v-if="sdgsChallgeApproved.length > 0">
                            <hr />
                            <div
                              class="
                                sdgs-challage-approvel sdgs-challage-approvel-sp
                              "
                            >
                              <div class="thisterm_wrap">
                                <div class="thisterm_head">
                                  <h3 class="thisterm_ttl challenge">
                                    チャレンジシート
                                  </h3>
                                </div>
                                <ol class="challge-ul challge-ul-sp">
                                  <template
                                    v-for="sdgsChallge in sdgsChallgeApproved"
                                  >
                                    <li
                                      :key="sdgsChallge.id"
                                      v-if="sdgsChallge.type == 'challenge'"
                                    >
                                      <div v-html="sdgsChallge.content"></div>
                                    </li>
                                  </template>
                                </ol>
                              </div>
                            </div>
                            <hr />
                            <div
                              class="
                                sdgs-challage-approvel sdgs-challage-approvel-sp
                              "
                            >
                              <div class="thisterm_wrap">
                                <div class="thisterm_head">
                                  <h3 class="thisterm_ttl sdgs">
                                    SDGs個人目標
                                  </h3>
                                </div>
                                <ol class="challge-ul challge-ul-sp">
                                  <template
                                    v-for="sdgsChallge in sdgsChallgeApproved"
                                  >
                                    <li
                                      :key="sdgsChallge.id"
                                      v-if="sdgsChallge.type == 'sdgs'"
                                    >
                                      <div v-html="sdgsChallge.content"></div>
                                    </li>
                                  </template>
                                </ol>
                              </div>
                            </div>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--  MFUX-1451 役員・社員紹介ページの改修  @auther Elavarasan @data 21/04/2022  staticProductionData-->

              <div v-if="staticProductionData.length != 0">
                <h3 class="ttl_04 hieght-45">
                  <!-- その他 -->
                </h3>
                <div class="staff_wrap">
                  <div class="staff_list big">
                    <div
                      v-for="(items, line_index) in staticProductionData"
                      :key="line_index"
                    >
                      <div class="staff_line">
                        <div
                          class="staff_item"
                          :data-wrapper="line_index + 'officer_prd'"
                          :data-modal="'modal' + item_index"
                          v-for="(item, item_index) in items"
                          :key="item_index"
                        >
                          <div class="user_wrap">
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage: 'url(' + item.imgpath + ')',
                              }"
                              v-if="item.imgpath"
                            ></div>
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage:
                                  'url(' +
                                  require('@/assets/front_component/images/test/test04.png') +
                                  ')',
                              }"
                              v-else
                            ></div>
                            <div class="user_name">
                              <p class="name">
                                {{ item.last_name }} {{ item.first_name }}
                              </p>
                              <br />
                              <span
                                class="director"
                                v-if="item.group && item.group.group_role"
                                >{{ item.group.group_role }}</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        :id="'function_wrapper_' + line_index + 'officer_prd'"
                        class="
                          functions_detail_wrapper
                          approvel-sdgs-challage-wrapper
                        "
                      >
                        <div
                          class="functions_detail"
                          :class="'modal' + item_index"
                          v-for="(item, item_index) in items"
                          :key="item_index"
                        >
                          <div class="left_area">
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage: 'url(' + item.imgpath + ')',
                              }"
                              v-if="item.imgpath"
                            ></div>
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage:
                                  'url(' +
                                  require('@/assets/front_component/images/test/test04.png') +
                                  ')',
                              }"
                              v-else
                            ></div>
                          </div>
                          <div class="right_area">
                            <div class="txt_wrap">
                              <div class="group hieght-45">
                                <!-- その他 -->
                              </div>
                              <div class="name">
                                {{ item.last_name }} {{ item.first_name }}
                              </div>
                              <p
                                class="txt"
                                v-html="htmlText(item.introduction_com)"
                              ></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- static data removed 16/05/2022 -->
              <div v-else-if="officer_show == false" class="p_t50 p_b50">
                <div class="half-circle-spinner">
                  <div class="circle circle-1"></div>
                  <div class="circle circle-2"></div>
                </div>
              </div>

              <div v-if="officerUnder_array.length != 0">
                <div
                  class="staff_wrap"
                  v-for="(under_items, under_index) in officerUnder_array"
                  :key="under_index"
                >
                  <h4 class="ttl_05">{{ under_items.name }}</h4>
                  <div class="staff_in_wrap" v-if="under_items.users != ''">
                    <div class="staff_list small">
                      <div
                        v-for="(items, line_index) in under_items.users"
                        :key="line_index"
                      >
                        <div class="staff_line">
                          <div
                            class="staff_item"
                            :data-wrapper="
                              line_index + 'officerUnder' + under_index
                            "
                            :data-modal="'modal' + item_index"
                            v-for="(item, item_index) in items"
                            :key="item_index"
                          >
                            <div
                              class="user_wrap"
                              @click="getChallageSdgsByUserId(item.id)"
                            >
                              <div
                                class="img_wrap"
                                :style="{
                                  backgroundImage: 'url(' + item.imgpath + ')',
                                }"
                                v-if="item.imgpath"
                              ></div>
                              <div
                                class="img_wrap"
                                :style="{
                                  backgroundImage:
                                    'url(' +
                                    require('@/assets/front_component/images/test/test04.png') +
                                    ')',
                                }"
                                v-else
                              ></div>
                              <div class="user_name">
                                <p class="name">
                                  {{ item.last_name }} {{ item.first_name }}
                                </p>
                                <br />
                                <span
                                  class="director"
                                  v-if="item.group && item.group.group_role"
                                  >{{ item.group.group_role }}</span
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          :id="
                            'function_wrapper_' +
                            line_index +
                            'officerUnder' +
                            under_index
                          "
                          class="
                            functions_detail_wrapper
                            approvel-sdgs-challage-wrapper
                          "
                        >
                          <div
                            class="functions_detail"
                            :class="'modal' + item_index"
                            v-for="(item, item_index) in items"
                            :key="item_index"
                          >
                            <div class="left_area">
                              <div
                                class="img_wrap"
                                :style="{
                                  backgroundImage: 'url(' + item.imgpath + ')',
                                }"
                                v-if="item.imgpath"
                              ></div>
                              <div
                                class="img_wrap"
                                :style="{
                                  backgroundImage:
                                    'url(' +
                                    require('@/assets/front_component/images/test/test04.png') +
                                    ')',
                                }"
                                v-else
                              ></div>
                            </div>
                            <div class="right_area">
                              <div class="txt_wrap">
                                <div class="group">
                                  役員{{ under_items.name }}
                                </div>
                                <div class="name">
                                  {{ item.last_name }} {{ item.first_name }}
                                </div>
                                <p
                                  class="txt"
                                  v-html="htmlText(item.introduction_com)"
                                ></p>
                              </div>
                            </div>
                            <div
                              v-if="sdgsChallengeApprovedLoader == true"
                              class="p_t50 p_b50"
                            >
                              <div class="half-circle-spinner">
                                <div class="circle circle-1"></div>
                                <div class="circle circle-2"></div>
                              </div>
                            </div>
                            <template v-if="sdgsChallgeApproved.length > 0">
                              <hr />
                              <div
                                class="
                                  sdgs-challage-approvel
                                  sdgs-challage-approvel-sp
                                "
                              >
                                <div class="thisterm_wrap">
                                  <div class="thisterm_head">
                                    <h3 class="thisterm_ttl challenge">
                                      チャレンジシート
                                    </h3>
                                  </div>
                                  <ol class="challge-ul challge-ul-sp">
                                    <template
                                      v-for="sdgsChallge in sdgsChallgeApproved"
                                    >
                                      <li
                                        :key="sdgsChallge.id"
                                        v-if="sdgsChallge.type == 'challenge'"
                                      >
                                        <div v-html="sdgsChallge.content"></div>
                                      </li>
                                    </template>
                                  </ol>
                                </div>
                              </div>
                              <hr />
                              <div
                                class="
                                  sdgs-challage-approvel
                                  sdgs-challage-approvel-sp
                                "
                              >
                                <div class="thisterm_wrap">
                                  <div class="thisterm_head">
                                    <h3 class="thisterm_ttl sdgs">
                                      SDGs個人目標
                                    </h3>
                                  </div>
                                  <ol class="challge-ul challge-ul-sp">
                                    <template
                                      v-for="sdgsChallge in sdgsChallgeApproved"
                                    >
                                      <li
                                        :key="sdgsChallge.id"
                                        v-if="sdgsChallge.type == 'sdgs'"
                                      >
                                        <div v-html="sdgsChallge.content"></div>
                                      </li>
                                    </template>
                                  </ol>
                                </div>
                              </div>
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div v-else class="p_t50 p_b50">
                    <div class="half-circle-spinner">
                      <div class="circle circle-1"></div>
                      <div class="circle circle-2"></div>
                    </div>
                  </div> -->
                </div>
              </div>
              <div v-else-if="officerUnder_show == false" class="p_t100 p_b100">
                <div class="half-circle-spinner">
                  <div class="circle circle-1"></div>
                  <div class="circle circle-2"></div>
                </div>
              </div>
            </div>
            <div v-else class="link_list yakuin active">
              <div v-if="chairman_show">
                <h3 class="ttl_04">会長</h3>
                <div class="staff_wrap">
                  <div class="staff_list big">
                    <div
                      v-for="(items, line_index) in chairman_array"
                      :key="line_index"
                    >
                      <div class="staff_line">
                        <div
                          class="staff_item"
                          :data-wrapper="line_index + 'chairman'"
                          :data-modal="'modal' + item_index"
                          v-for="(item, item_index) in items"
                          :key="item_index"
                        >
                          <div
                            class="user_wrap"
                            @click="getChallageSdgsByUserId(item.id)"
                          >
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage: 'url(' + item.imgpath + ')',
                              }"
                              v-if="item.imgpath"
                            ></div>
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage:
                                  'url(' +
                                  require('@/assets/front_component/images/test/test04.png') +
                                  ')',
                              }"
                              v-else
                            ></div>
                            <div class="user_name">
                              <p class="name">
                                {{ item.last_name }} {{ item.first_name }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        :id="'function_wrapper_' + line_index + 'chairman'"
                        class="
                          functions_detail_wrapper
                          approvel-sdgs-challage-wrapper
                          approvel-sdgs-challage-wrapper
                        "
                      >
                        <div
                          class="functions_detail"
                          :class="'modal' + item_index"
                          v-for="(item, item_index) in items"
                          :key="item_index"
                        >
                          <div class="left_area">
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage: 'url(' + item.imgpath + ')',
                              }"
                              v-if="item.imgpath"
                            ></div>
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage:
                                  'url(' +
                                  require('@/assets/front_component/images/test/test04.png') +
                                  ')',
                              }"
                              v-else
                            ></div>
                          </div>
                          <div class="right_area">
                            <div class="txt_wrap">
                              <div class="group">会長</div>
                              <div class="name">
                                {{ item.last_name }} {{ item.first_name }}
                              </div>
                              <p
                                class="txt"
                                v-html="htmlText(item.introduction_com)"
                              ></p>
                            </div>
                          </div>
                          <div
                            v-if="sdgsChallengeApprovedLoader == true"
                            class="p_t50 p_b50"
                          >
                            <div class="half-circle-spinner">
                              <div class="circle circle-1"></div>
                              <div class="circle circle-2"></div>
                            </div>
                          </div>
                          <template v-if="sdgsChallgeApproved.length > 0">
                            <hr />
                            <div
                              class="
                                sdgs-challage-approvel sdgs-challage-approvel-sp
                              "
                            >
                              <div class="thisterm_wrap">
                                <div class="thisterm_head">
                                  <h3 class="thisterm_ttl challenge">
                                    チャレンジシート
                                  </h3>
                                </div>
                                <ol class="challge-ul challge-ul-sp">
                                  <template
                                    v-for="sdgsChallge in sdgsChallgeApproved"
                                  >
                                    <li
                                      :key="sdgsChallge.id"
                                      v-if="sdgsChallge.type == 'challenge'"
                                    >
                                      <div v-html="sdgsChallge.content"></div>
                                    </li>
                                  </template>
                                </ol>
                              </div>
                            </div>

                            <hr />
                            <div
                              class="
                                sdgs-challage-approvel sdgs-challage-approvel-sp
                              "
                            >
                              <div class="thisterm_wrap">
                                <div class="thisterm_head">
                                  <h3 class="thisterm_ttl sdgs">
                                    SDGs個人目標
                                  </h3>
                                </div>
                                <ol class="challge-ul challge-ul-sp">
                                  <template
                                    v-for="sdgsChallge in sdgsChallgeApproved"
                                  >
                                    <li
                                      :key="sdgsChallge.id"
                                      v-if="sdgsChallge.type == 'sdgs'"
                                    >
                                      <div v-html="sdgsChallge.content"></div>
                                    </li>
                                  </template>
                                </ol>
                              </div>
                            </div>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="ceo_show">
                <h3 class="ttl_04">代表取締役社長</h3>
                <div class="staff_wrap">
                  <div class="staff_list big">
                    <div
                      v-for="(items, line_index) in ceo_array"
                      :key="line_index"
                    >
                      <div class="staff_line">
                        <div
                          class="staff_item"
                          :data-wrapper="line_index + 'ceo'"
                          :data-modal="'modal' + item_index"
                          v-for="(item, item_index) in items"
                          :key="item_index"
                        >
                          <div
                            class="user_wrap"
                            @click="getChallageSdgsByUserId(item.id)"
                          >
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage: 'url(' + item.imgpath + ')',
                              }"
                              v-if="item.imgpath"
                            ></div>
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage:
                                  'url(' +
                                  require('@/assets/front_component/images/test/test04.png') +
                                  ')',
                              }"
                              v-else
                            ></div>
                            <div class="user_name">
                              <p class="name">
                                {{ item.last_name }} {{ item.first_name }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        :id="'function_wrapper_' + line_index + 'ceo'"
                        class="
                          functions_detail_wrapper
                          approvel-sdgs-challage-wrapper
                          approvel-sdgs-challage-wrapper
                        "
                      >
                        <div
                          class="functions_detail"
                          :class="'modal' + item_index"
                          v-for="(item, item_index) in items"
                          :key="item_index"
                        >
                          <div class="left_area">
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage: 'url(' + item.imgpath + ')',
                              }"
                              v-if="item.imgpath"
                            ></div>
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage:
                                  'url(' +
                                  require('@/assets/front_component/images/test/test04.png') +
                                  ')',
                              }"
                              v-else
                            ></div>
                          </div>
                          <div class="right_area">
                            <div class="txt_wrap">
                              <div class="group">代表取締役社長</div>
                              <div class="name">
                                {{ item.last_name }} {{ item.first_name }}
                              </div>
                              <p
                                class="txt"
                                v-html="htmlText(item.introduction_com)"
                              ></p>
                            </div>
                          </div>
                          <div
                            v-if="sdgsChallengeApprovedLoader == true"
                            class="p_t50 p_b50"
                          >
                            <div class="half-circle-spinner">
                              <div class="circle circle-1"></div>
                              <div class="circle circle-2"></div>
                            </div>
                          </div>
                          <template v-if="sdgsChallgeApproved.length > 0">
                            <hr />
                            <div
                              class="
                                sdgs-challage-approvel sdgs-challage-approvel-sp
                              "
                            >
                              <div class="thisterm_wrap">
                                <div class="thisterm_head">
                                  <h3 class="thisterm_ttl challenge">
                                    チャレンジシート
                                  </h3>
                                </div>
                                <ol class="challge-ul challge-ul-sp">
                                  <template
                                    v-for="sdgsChallge in sdgsChallgeApproved"
                                  >
                                    <li
                                      :key="sdgsChallge.id"
                                      v-if="sdgsChallge.type == 'challenge'"
                                    >
                                      <div v-html="sdgsChallge.content"></div>
                                    </li>
                                  </template>
                                </ol>
                              </div>
                            </div>

                            <hr />
                            <div
                              class="
                                sdgs-challage-approvel sdgs-challage-approvel-sp
                              "
                            >
                              <div class="thisterm_wrap">
                                <div class="thisterm_head">
                                  <h3 class="thisterm_ttl sdgs">
                                    SDGs個人目標
                                  </h3>
                                </div>
                                <ol class="challge-ul challge-ul-sp">
                                  <template
                                    v-for="sdgsChallge in sdgsChallgeApproved"
                                  >
                                    <li
                                      :key="sdgsChallge.id"
                                      v-if="sdgsChallge.type == 'sdgs'"
                                    >
                                      <div v-html="sdgsChallge.content"></div>
                                    </li>
                                  </template>
                                </ol>
                              </div>
                            </div>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="new_officer_array.length != 0">
                <h3 class="ttl_04">役員</h3>
                <div class="staff_wrap">
                  <div class="staff_list big">
                    <div
                      v-for="(items, line_index) in new_officer_array"
                      :key="line_index"
                    >
                      <div class="staff_line">
                        <div
                          class="staff_item"
                          :data-wrapper="line_index + 'officer'"
                          :data-modal="'modal' + item_index"
                          v-for="(item, item_index) in items"
                          :key="item_index"
                        >
                          <div
                            class="user_wrap"
                            @click="getChallageSdgsByUserId(item.id)"
                          >
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage: 'url(' + item.imgpath + ')',
                              }"
                              v-if="item.imgpath"
                            ></div>
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage:
                                  'url(' +
                                  require('@/assets/front_component/images/test/test04.png') +
                                  ')',
                              }"
                              v-else
                            ></div>
                            <div class="user_name">
                              <p class="name">
                                {{ item.last_name }} {{ item.first_name }}
                              </p>
                              <br />
                              <span
                                class="director"
                                v-if="item.group && item.group.group_role"
                                >{{ item.group.group_role }}</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        :id="'function_wrapper_' + line_index + 'officer'"
                        class="
                          functions_detail_wrapper
                          approvel-sdgs-challage-wrapper
                        "
                      >
                        <div
                          class="functions_detail"
                          :class="'modal' + item_index"
                          v-for="(item, item_index) in items"
                          :key="item_index"
                        >
                          <div class="left_area">
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage: 'url(' + item.imgpath + ')',
                              }"
                              v-if="item.imgpath"
                            ></div>
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage:
                                  'url(' +
                                  require('@/assets/front_component/images/test/test04.png') +
                                  ')',
                              }"
                              v-else
                            ></div>
                          </div>
                          <div class="right_area">
                            <div class="txt_wrap">
                              <div class="group">役員</div>
                              <div class="name">
                                {{ item.last_name }} {{ item.first_name }}
                              </div>
                              <p
                                class="txt"
                                v-html="htmlText(item.introduction_com)"
                              ></p>
                            </div>
                          </div>
                          <div
                            v-if="sdgsChallengeApprovedLoader == true"
                            class="p_t50 p_b50"
                          >
                            <div class="half-circle-spinner">
                              <div class="circle circle-1"></div>
                              <div class="circle circle-2"></div>
                            </div>
                          </div>
                          <template v-if="sdgsChallgeApproved.length > 0">
                            <hr />
                            <div
                              class="
                                sdgs-challage-approvel sdgs-challage-approvel-sp
                              "
                            >
                              <div class="thisterm_wrap">
                                <div class="thisterm_head">
                                  <h3 class="thisterm_ttl challenge">
                                    チャレンジシート
                                  </h3>
                                </div>
                                <ol class="challge-ul challge-ul-sp">
                                  <template
                                    v-for="sdgsChallge in sdgsChallgeApproved"
                                  >
                                    <li
                                      :key="sdgsChallge.id"
                                      v-if="sdgsChallge.type == 'challenge'"
                                    >
                                      <div v-html="sdgsChallge.content"></div>
                                    </li>
                                  </template>
                                </ol>
                              </div>
                            </div>
                            <hr />
                            <div
                              class="
                                sdgs-challage-approvel sdgs-challage-approvel-sp
                              "
                            >
                              <div class="thisterm_wrap">
                                <div class="thisterm_head">
                                  <h3 class="thisterm_ttl sdgs">
                                    SDGs個人目標
                                  </h3>
                                </div>
                                <ol class="challge-ul challge-ul-sp">
                                  <template
                                    v-for="sdgsChallge in sdgsChallgeApproved"
                                  >
                                    <li
                                      :key="sdgsChallge.id"
                                      v-if="sdgsChallge.type == 'sdgs'"
                                    >
                                      <div v-html="sdgsChallge.content"></div>
                                    </li>
                                  </template>
                                </ol>
                              </div>
                            </div>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--  MFUX-1451 役員・社員紹介ページの改修  @auther Elavarasan @data 21/04/2022-->
              <div v-if="staticProductionData.length != 0">
                <h3 class="ttl_04 hieght-45">
                  <!-- その他 -->
                </h3>
                <div class="staff_wrap">
                  <div class="staff_list big">
                    <div
                      v-for="(items, line_index) in staticProductionData"
                      :key="line_index"
                    >
                      <div class="staff_line">
                        <div
                          class="staff_item"
                          :data-wrapper="line_index + 'officer_prd'"
                          :data-modal="'modal' + item_index"
                          v-for="(item, item_index) in items"
                          :key="item_index"
                        >
                          <div
                            class="user_wrap"
                            @click="getChallageSdgsByUserId(item.id)"
                          >
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage: 'url(' + item.imgpath + ')',
                              }"
                              v-if="item.imgpath"
                            ></div>
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage:
                                  'url(' +
                                  require('@/assets/front_component/images/test/test04.png') +
                                  ')',
                              }"
                              v-else
                            ></div>
                            <div class="user_name">
                              <p class="name">
                                {{ item.last_name }} {{ item.first_name }}
                              </p>
                              <br />
                              <span
                                class="director"
                                v-if="item.group && item.group.group_role"
                                >{{ item.group.group_role }}</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        :id="'function_wrapper_' + line_index + 'officer_prd'"
                        class="
                          functions_detail_wrapper
                          approvel-sdgs-challage-wrapper
                        "
                      >
                        <div
                          class="functions_detail"
                          :class="'modal' + item_index"
                          v-for="(item, item_index) in items"
                          :key="item_index"
                        >
                          <div class="left_area">
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage: 'url(' + item.imgpath + ')',
                              }"
                              v-if="item.imgpath"
                            ></div>
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage:
                                  'url(' +
                                  require('@/assets/front_component/images/test/test04.png') +
                                  ')',
                              }"
                              v-else
                            ></div>
                          </div>
                          <div class="right_area">
                            <div class="txt_wrap">
                              <div class="group hieght-45">
                                <!-- その他 -->
                              </div>
                              <div class="name">
                                {{ item.last_name }} {{ item.first_name }}
                              </div>
                              <p
                                class="txt"
                                v-html="htmlText(item.introduction_com)"
                              ></p>
                            </div>
                          </div>
                          <div
                            v-if="sdgsChallengeApprovedLoader == true"
                            class="p_t50 p_b50"
                          >
                            <div class="half-circle-spinner">
                              <div class="circle circle-1"></div>
                              <div class="circle circle-2"></div>
                            </div>
                          </div>
                          <template v-if="sdgsChallgeApproved.length > 0">
                            <hr />
                            <div
                              class="
                                sdgs-challage-approvel sdgs-challage-approvel-sp
                              "
                            >
                              <div class="thisterm_wrap">
                                <div class="thisterm_head">
                                  <h3 class="thisterm_ttl challenge">
                                    チャレンジシート
                                  </h3>
                                </div>
                                <ol class="challge-ul challge-ul-sp">
                                  <template
                                    v-for="sdgsChallge in sdgsChallgeApproved"
                                  >
                                    <li
                                      :key="sdgsChallge.id"
                                      v-if="sdgsChallge.type == 'challenge'"
                                    >
                                      <div v-html="sdgsChallge.content"></div>
                                    </li>
                                  </template>
                                </ol>
                              </div>
                            </div>

                            <hr />
                            <div
                              class="
                                sdgs-challage-approvel sdgs-challage-approvel-sp
                              "
                            >
                              <div class="thisterm_wrap">
                                <div class="thisterm_head">
                                  <h3 class="thisterm_ttl sdgs">
                                    SDGs個人目標
                                  </h3>
                                </div>
                                <ol class="challge-ul challge-ul-sp">
                                  <template
                                    v-for="sdgsChallge in sdgsChallgeApproved"
                                  >
                                    <li
                                      :key="sdgsChallge.id"
                                      v-if="sdgsChallge.type == 'sdgs'"
                                    >
                                      <div v-html="sdgsChallge.content"></div>
                                    </li>
                                  </template>
                                </ol>
                              </div>
                            </div>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else-if="officer_show == false" class="p_t50 p_b50">
                <div class="half-circle-spinner">
                  <div class="circle circle-1"></div>
                  <div class="circle circle-2"></div>
                </div>
              </div>

              <div v-if="officerUnder_array.length != 0">
                <div
                  class="staff_wrap"
                  v-for="(under_items, under_index) in officerUnder_array"
                  :key="under_index"
                >
                  <h4 class="ttl_05">{{ under_items.name }}</h4>
                  <div class="staff_in_wrap" v-if="under_items.users != ''">
                    <div class="staff_list small">
                      <div
                        v-for="(items, line_index) in under_items.users"
                        :key="line_index"
                      >
                        <div class="staff_line">
                          <div
                            class="staff_item"
                            :data-wrapper="
                              line_index + 'officerUnder' + under_index
                            "
                            :data-modal="'modal' + item_index"
                            v-for="(item, item_index) in items"
                            :key="item_index"
                          >
                            <div class="user_wrap">
                              <div
                                class="img_wrap"
                                :style="{
                                  backgroundImage: 'url(' + item.imgpath + ')',
                                }"
                                v-if="item.imgpath"
                              ></div>
                              <div
                                class="img_wrap"
                                :style="{
                                  backgroundImage:
                                    'url(' +
                                    require('@/assets/front_component/images/test/test04.png') +
                                    ')',
                                }"
                                v-else
                              ></div>
                              <div class="user_name">
                                <p class="name">
                                  {{ item.last_name }} {{ item.first_name }}
                                </p>
                                <br />
                                <span
                                  class="director"
                                  v-if="item.group && item.group.group_role"
                                  >{{ item.group.group_role }}</span
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          :id="
                            'function_wrapper_' +
                            line_index +
                            'officerUnder' +
                            under_index
                          "
                          class="
                            functions_detail_wrapper
                            approvel-sdgs-challage-wrapper
                          "
                        >
                          <div
                            class="functions_detail"
                            :class="'modal' + item_index"
                            v-for="(item, item_index) in items"
                            :key="item_index"
                          >
                            <div class="left_area">
                              <div
                                class="img_wrap"
                                :style="{
                                  backgroundImage: 'url(' + item.imgpath + ')',
                                }"
                                v-if="item.imgpath"
                              ></div>
                              <div
                                class="img_wrap"
                                :style="{
                                  backgroundImage:
                                    'url(' +
                                    require('@/assets/front_component/images/test/test04.png') +
                                    ')',
                                }"
                                v-else
                              ></div>
                            </div>
                            <div class="right_area">
                              <div class="txt_wrap">
                                <div class="group">
                                  役員{{ under_items.name }}
                                </div>
                                <div class="name">
                                  {{ item.last_name }} {{ item.first_name }}
                                </div>
                                <p
                                  class="txt"
                                  v-html="htmlText(item.introduction_com)"
                                ></p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div v-else class="p_t50 p_b50">
                    <div class="half-circle-spinner">
                      <div class="circle circle-1"></div>
                      <div class="circle circle-2"></div>
                    </div>
                  </div> -->
                </div>
              </div>
              <div v-else-if="officerUnder_show == false" class="p_t100 p_b100">
                <div class="half-circle-spinner">
                  <div class="circle circle-1"></div>
                  <div class="circle circle-2"></div>
                </div>
              </div>
            </div>

            <div v-if="phone" class="link_list toukatu">
              <h3 class="ttl_04">内部統括部</h3>
              <div v-if="internalControl_array.length != 0">
                <div class="staff_wrap">
                  <div class="staff_list big">
                    <div
                      v-for="(items, line_index) in internalControl_array"
                      :key="line_index"
                    >
                      <div class="staff_line">
                        <div
                          class="staff_item"
                          :data-wrapper="line_index + 'toukatu'"
                          :data-modal="'modal' + item_index"
                          v-for="(item, item_index) in items"
                          :key="item_index"
                        >
                          <div
                            class="user_wrap"
                            @click="getChallageSdgsByUserId(item.id)"
                          >
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage: 'url(' + item.imgpath + ')',
                              }"
                              v-if="item.imgpath"
                            ></div>
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage:
                                  'url(' +
                                  require('@/assets/front_component/images/test/test04.png') +
                                  ')',
                              }"
                              v-else
                            ></div>
                            <div class="user_name">
                              <p class="name">
                                {{ item.last_name }} {{ item.first_name }}
                              </p>
                              <br />
                              <span
                                class="director"
                                v-if="item.group && item.group.group_role"
                                >{{ item.group.group_role }}</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        :id="'function_wrapper_' + line_index + 'toukatu'"
                        class="
                          functions_detail_wrapper
                          approvel-sdgs-challage-wrapper
                        "
                      >
                        <div
                          class="functions_detail"
                          :class="'modal' + item_index"
                          v-for="(item, item_index) in items"
                          :key="item_index"
                        >
                          <div class="left_area">
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage: 'url(' + item.imgpath + ')',
                              }"
                              v-if="item.imgpath"
                            ></div>
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage:
                                  'url(' +
                                  require('@/assets/front_component/images/test/test04.png') +
                                  ')',
                              }"
                              v-else
                            ></div>
                          </div>
                          <div class="right_area">
                            <div class="txt_wrap">
                              <div class="group">内部統括部</div>
                              <div class="name">
                                {{ item.last_name }} {{ item.first_name }}
                              </div>
                              <p
                                class="txt"
                                v-html="htmlText(item.introduction_com)"
                              ></p>
                            </div>
                          </div>
                          <div
                            v-if="sdgsChallengeApprovedLoader == true"
                            class="p_t50 p_b50"
                          >
                            <div class="half-circle-spinner">
                              <div class="circle circle-1"></div>
                              <div class="circle circle-2"></div>
                            </div>
                          </div>
                          <template v-if="sdgsChallgeApproved.length > 0">
                            <hr />
                            <div
                              class="
                                sdgs-challage-approvel sdgs-challage-approvel-sp
                              "
                            >
                              <div class="thisterm_wrap">
                                <div class="thisterm_head">
                                  <h3 class="thisterm_ttl challenge">
                                    チャレンジシート
                                  </h3>
                                </div>
                                <ol class="challge-ul challge-ul-sp">
                                  <template
                                    v-for="sdgsChallge in sdgsChallgeApproved"
                                  >
                                    <li
                                      :key="sdgsChallge.id"
                                      v-if="sdgsChallge.type == 'challenge'"
                                    >
                                      <div v-html="sdgsChallge.content"></div>
                                    </li>
                                  </template>
                                </ol>
                              </div>
                            </div>
                            <hr />
                            <div
                              class="
                                sdgs-challage-approvel sdgs-challage-approvel-sp
                              "
                            >
                              <div class="thisterm_wrap">
                                <div class="thisterm_head">
                                  <h3 class="thisterm_ttl sdgs">
                                    SDGs個人目標
                                  </h3>
                                </div>
                                <ol class="challge-ul challge-ul-sp">
                                  <template
                                    v-for="sdgsChallge in sdgsChallgeApproved"
                                  >
                                    <li
                                      :key="sdgsChallge.id"
                                      v-if="sdgsChallge.type == 'sdgs'"
                                    >
                                      <div v-html="sdgsChallge.content"></div>
                                    </li>
                                  </template>
                                </ol>
                              </div>
                            </div>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-else-if="internalControl_show == false"
                class="p_t50 p_b50"
              >
                <div class="half-circle-spinner">
                  <div class="circle circle-1"></div>
                  <div class="circle circle-2"></div>
                </div>
              </div>

              <div v-if="internalControlUnder_show">
                <div
                  class="staff_wrap"
                  v-for="(
                    under_items, under_index
                  ) in internalControlUnder_array"
                  :key="under_index"
                >
                  <h4 class="ttl_05">{{ under_items.name }}</h4>
                  <div class="staff_in_wrap" v-if="under_items.users != ''">
                    <div class="staff_list small">
                      <div
                        v-for="(items, line_index) in under_items.users"
                        :key="line_index"
                      >
                        <div class="staff_line">
                          <div
                            class="staff_item"
                            :data-wrapper="
                              line_index + 'toukatuUnder' + under_index
                            "
                            :data-modal="'modal' + item_index"
                            v-for="(item, item_index) in items"
                            :key="item_index"
                          >
                            <!-- // MFUX-1451 役員・社員紹介ページの改修  @auther Elavarasan @data 21/04/2022  staticProductionData -->
                            <!-- static data removed 16/05/2022 -->
                            <div
                              class="user_wrap"
                              @click="getChallageSdgsByUserId(item.id)"
                            >
                              <div
                                class="img_wrap"
                                :style="{
                                  backgroundImage: 'url(' + item.imgpath + ')',
                                }"
                                v-if="item.imgpath"
                              ></div>
                              <div
                                class="img_wrap"
                                :style="{
                                  backgroundImage:
                                    'url(' +
                                    require('@/assets/front_component/images/test/test04.png') +
                                    ')',
                                }"
                                v-else
                              ></div>
                              <div class="user_name">
                                <p class="name">
                                  {{ item.last_name }} {{ item.first_name }}
                                </p>
                                <br />
                                <span
                                  class="director"
                                  v-if="item.group && item.group.group_role"
                                  >{{ item.group.group_role }}</span
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          :id="
                            'function_wrapper_' +
                            line_index +
                            'toukatuUnder' +
                            under_index
                          "
                          class="
                            functions_detail_wrapper
                            approvel-sdgs-challage-wrapper
                          "
                        >
                          <div
                            class="functions_detail"
                            :class="'modal' + item_index"
                            v-for="(item, item_index) in items"
                            :key="item_index"
                          >
                            <div class="left_area">
                              <div
                                class="img_wrap"
                                :style="{
                                  backgroundImage: 'url(' + item.imgpath + ')',
                                }"
                                v-if="item.imgpath"
                              ></div>
                              <div
                                class="img_wrap"
                                :style="{
                                  backgroundImage:
                                    'url(' +
                                    require('@/assets/front_component/images/test/test04.png') +
                                    ')',
                                }"
                                v-else
                              ></div>
                            </div>
                            <div class="right_area">
                              <div class="txt_wrap">
                                <div class="group">
                                  内部統括部 {{ under_items.name }}
                                </div>
                                <div class="name">
                                  {{ item.last_name }} {{ item.first_name }}
                                </div>
                                <p
                                  class="txt"
                                  v-html="htmlText(item.introduction_com)"
                                ></p>
                              </div>
                            </div>
                            <div
                              v-if="sdgsChallengeApprovedLoader == true"
                              class="p_t50 p_b50"
                            >
                              <div class="half-circle-spinner">
                                <div class="circle circle-1"></div>
                                <div class="circle circle-2"></div>
                              </div>
                            </div>
                            <template v-if="sdgsChallgeApproved.length > 0">
                              <hr />
                              <div
                                class="
                                  sdgs-challage-approvel
                                  sdgs-challage-approvel-sp
                                "
                              >
                                <div class="thisterm_wrap">
                                  <div class="thisterm_head">
                                    <h3 class="thisterm_ttl challenge">
                                      チャレンジシート
                                    </h3>
                                  </div>
                                  <ol class="challge-ul challge-ul-sp">
                                    <template
                                      v-for="sdgsChallge in sdgsChallgeApproved"
                                    >
                                      <li
                                        :key="sdgsChallge.id"
                                        v-if="sdgsChallge.type == 'challenge'"
                                      >
                                        <div v-html="sdgsChallge.content"></div>
                                      </li>
                                    </template>
                                  </ol>
                                </div>
                              </div>
                              <hr />
                              <div
                                class="
                                  sdgs-challage-approvel
                                  sdgs-challage-approvel-sp
                                "
                              >
                                <div class="thisterm_wrap">
                                  <div class="thisterm_head">
                                    <h3 class="thisterm_ttl sdgs">
                                      SDGs個人目標
                                    </h3>
                                  </div>
                                  <ol class="challge-ul challge-ul-sp">
                                    <template
                                      v-for="sdgsChallge in sdgsChallgeApproved"
                                    >
                                      <li
                                        :key="sdgsChallge.id"
                                        v-if="sdgsChallge.type == 'sdgs'"
                                      >
                                        <div v-html="sdgsChallge.content"></div>
                                      </li>
                                    </template>
                                  </ol>
                                </div>
                              </div>
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div v-else class="p_t50 p_b50">
                    <div class="half-circle-spinner">
                      <div class="circle circle-1"></div>
                      <div class="circle circle-2"></div>
                    </div>
                  </div> -->
                </div>
              </div>
              <div
                v-else-if="internalControlUnder_show == false"
                class="p_t100 p_b100"
              >
                <div class="half-circle-spinner">
                  <div class="circle circle-1"></div>
                  <div class="circle circle-2"></div>
                </div>
              </div>
            </div>
            <div v-else class="link_list toukatu">
              <h3 class="ttl_04">内部統括部</h3>
              <div v-if="internalControl_array.length != 0">
                <div class="staff_wrap">
                  <div class="staff_list big">
                    <div
                      v-for="(items, line_index) in internalControl_array"
                      :key="line_index"
                    >
                      <div class="staff_line">
                        <div
                          class="staff_item"
                          :data-wrapper="line_index + 'toukatu'"
                          :data-modal="'modal' + item_index"
                          v-for="(item, item_index) in items"
                          :key="item_index"
                        >
                          <div
                            class="user_wrap"
                            @click="getChallageSdgsByUserId(item.id)"
                          >
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage: 'url(' + item.imgpath + ')',
                              }"
                              v-if="item.imgpath"
                            ></div>
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage:
                                  'url(' +
                                  require('@/assets/front_component/images/test/test04.png') +
                                  ')',
                              }"
                              v-else
                            ></div>
                            <div class="user_name">
                              <p class="name">
                                {{ item.last_name }} {{ item.first_name }}
                              </p>
                              <br />
                              <span
                                class="director"
                                v-if="item.group && item.group.group_role"
                                >{{ item.group.group_role }}</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        :id="'function_wrapper_' + line_index + 'toukatu'"
                        class="
                          functions_detail_wrapper
                          approvel-sdgs-challage-wrapper
                        "
                      >
                        <div
                          class="functions_detail"
                          :class="'modal' + item_index"
                          v-for="(item, item_index) in items"
                          :key="item_index"
                        >
                          <div class="left_area">
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage: 'url(' + item.imgpath + ')',
                              }"
                              v-if="item.imgpath"
                            ></div>
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage:
                                  'url(' +
                                  require('@/assets/front_component/images/test/test04.png') +
                                  ')',
                              }"
                              v-else
                            ></div>
                          </div>
                          <div class="right_area">
                            <div class="txt_wrap">
                              <div class="group">内部統括部</div>
                              <div class="name">
                                {{ item.last_name }} {{ item.first_name }}
                              </div>
                              <p
                                class="txt"
                                v-html="htmlText(item.introduction_com)"
                              ></p>
                            </div>
                          </div>
                          <div
                            v-if="sdgsChallengeApprovedLoader == true"
                            class="p_t50 p_b50"
                          >
                            <div class="half-circle-spinner">
                              <div class="circle circle-1"></div>
                              <div class="circle circle-2"></div>
                            </div>
                          </div>
                          <template v-if="sdgsChallgeApproved.length > 0">
                            <hr />
                            <div
                              class="
                                sdgs-challage-approvel sdgs-challage-approvel-sp
                              "
                            >
                              <div class="thisterm_wrap">
                                <div class="thisterm_head">
                                  <h3 class="thisterm_ttl challenge">
                                    チャレンジシート
                                  </h3>
                                </div>
                                <ol class="challge-ul challge-ul-sp">
                                  <template
                                    v-for="sdgsChallge in sdgsChallgeApproved"
                                  >
                                    <li
                                      :key="sdgsChallge.id"
                                      v-if="sdgsChallge.type == 'challenge'"
                                    >
                                      <div v-html="sdgsChallge.content"></div>
                                    </li>
                                  </template>
                                </ol>
                              </div>
                            </div>
                            <hr />
                            <div
                              class="
                                sdgs-challage-approvel sdgs-challage-approvel-sp
                              "
                            >
                              <div class="thisterm_wrap">
                                <div class="thisterm_head">
                                  <h3 class="thisterm_ttl sdgs">
                                    SDGs個人目標
                                  </h3>
                                </div>
                                <ol class="challge-ul challge-ul-sp">
                                  <template
                                    v-for="sdgsChallge in sdgsChallgeApproved"
                                  >
                                    <li
                                      :key="sdgsChallge.id"
                                      v-if="sdgsChallge.type == 'sdgs'"
                                    >
                                      <div v-html="sdgsChallge.content"></div>
                                    </li>
                                  </template>
                                </ol>
                              </div>
                            </div>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-else-if="internalControl_show == false"
                class="p_t50 p_b50"
              >
                <div class="half-circle-spinner">
                  <div class="circle circle-1"></div>
                  <div class="circle circle-2"></div>
                </div>
              </div>

              <div v-if="internalControlUnder_show">
                <div
                  class="staff_wrap"
                  v-for="(
                    under_items, under_index
                  ) in internalControlUnder_array"
                  :key="under_index"
                >
                  <h4 class="ttl_05">{{ under_items.name }}</h4>
                  <div class="staff_in_wrap" v-if="under_items.users != ''">
                    <div class="staff_list small">
                      <div
                        v-for="(items, line_index) in under_items.users"
                        :key="line_index"
                      >
                        <div class="staff_line">
                          <div
                            class="staff_item"
                            :data-wrapper="
                              line_index + 'toukatuUnder' + under_index
                            "
                            :data-modal="'modal' + item_index"
                            v-for="(item, item_index) in items"
                            :key="item_index"
                          >
                            <!-- // MFUX-1451 役員・社員紹介ページの改修  @auther Elavarasan @data 21/04/2022  restrict user -->

                            <div
                              class="user_wrap"
                              @click="getChallageSdgsByUserId(item.id)"
                            >
                              <div
                                class="img_wrap"
                                :style="{
                                  backgroundImage: 'url(' + item.imgpath + ')',
                                }"
                                v-if="item.imgpath"
                              ></div>
                              <div
                                class="img_wrap"
                                :style="{
                                  backgroundImage:
                                    'url(' +
                                    require('@/assets/front_component/images/test/test04.png') +
                                    ')',
                                }"
                                v-else
                              ></div>
                              <div class="user_name">
                                <p class="name">
                                  {{ item.last_name }} {{ item.first_name }}
                                </p>
                                <br />
                                <span
                                  class="director"
                                  v-if="item.group && item.group.group_role"
                                  >{{ item.group.group_role }}</span
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          :id="
                            'function_wrapper_' +
                            line_index +
                            'toukatuUnder' +
                            under_index
                          "
                          class="
                            functions_detail_wrapper
                            approvel-sdgs-challage-wrapper
                          "
                        >
                          <div
                            class="functions_detail"
                            :class="'modal' + item_index"
                            v-for="(item, item_index) in items"
                            :key="item_index"
                          >
                            <div class="left_area">
                              <div
                                class="img_wrap"
                                :style="{
                                  backgroundImage: 'url(' + item.imgpath + ')',
                                }"
                                v-if="item.imgpath"
                              ></div>
                              <div
                                class="img_wrap"
                                :style="{
                                  backgroundImage:
                                    'url(' +
                                    require('@/assets/front_component/images/test/test04.png') +
                                    ')',
                                }"
                                v-else
                              ></div>
                            </div>
                            <div class="right_area">
                              <div class="txt_wrap">
                                <div class="group">
                                  内部統括部 {{ under_items.name }}
                                </div>
                                <div class="name">
                                  {{ item.last_name }} {{ item.first_name }}
                                </div>
                                <p
                                  class="txt"
                                  v-html="htmlText(item.introduction_com)"
                                ></p>
                              </div>
                            </div>
                            <div
                              v-if="sdgsChallengeApprovedLoader == true"
                              class="p_t50 p_b50"
                            >
                              <div class="half-circle-spinner">
                                <div class="circle circle-1"></div>
                                <div class="circle circle-2"></div>
                              </div>
                            </div>
                            <template v-if="sdgsChallgeApproved.length > 0">
                              <hr />
                              <div
                                class="
                                  sdgs-challage-approvel
                                  sdgs-challage-approvel-sp
                                "
                              >
                                <div class="thisterm_wrap">
                                  <div class="thisterm_head">
                                    <h3 class="thisterm_ttl challenge">
                                      チャレンジシート
                                    </h3>
                                  </div>
                                  <ol class="challge-ul challge-ul-sp">
                                    <template
                                      v-for="sdgsChallge in sdgsChallgeApproved"
                                    >
                                      <li
                                        :key="sdgsChallge.id"
                                        v-if="sdgsChallge.type == 'challenge'"
                                      >
                                        <div v-html="sdgsChallge.content"></div>
                                      </li>
                                    </template>
                                  </ol>
                                </div>
                              </div>
                              <hr />
                              <div
                                class="
                                  sdgs-challage-approvel
                                  sdgs-challage-approvel-sp
                                "
                              >
                                <div class="thisterm_wrap">
                                  <div class="thisterm_head">
                                    <h3 class="thisterm_ttl sdgs">
                                      SDGs個人目標
                                    </h3>
                                  </div>
                                  <ol class="challge-ul challge-ul-sp">
                                    <template
                                      v-for="sdgsChallge in sdgsChallgeApproved"
                                    >
                                      <li
                                        :key="sdgsChallge.id"
                                        v-if="sdgsChallge.type == 'sdgs'"
                                      >
                                        <div v-html="sdgsChallge.content"></div>
                                      </li>
                                    </template>
                                  </ol>
                                </div>
                              </div>
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div v-else class="p_t50 p_b50">
                    <div class="half-circle-spinner">
                      <div class="circle circle-1"></div>
                      <div class="circle circle-2"></div>
                    </div>
                  </div> -->
                </div>
              </div>
              <div
                v-else-if="internalControlUnder_show == false"
                class="p_t100 p_b100"
              >
                <div class="half-circle-spinner">
                  <div class="circle circle-1"></div>
                  <div class="circle circle-2"></div>
                </div>
              </div>
            </div>

            <div v-if="phone" class="link_list eigyo">
              <h3 class="ttl_04">CS 営業部</h3>
              <div v-if="cs_array.length != 0">
                <div class="staff_wrap">
                  <div class="staff_list big">
                    <div
                      v-for="(items, line_index) in cs_array"
                      :key="line_index"
                    >
                      <div class="staff_line">
                        <div
                          class="staff_item"
                          :data-wrapper="line_index + 'eigyo'"
                          :data-modal="'modal' + item_index"
                          v-for="(item, item_index) in items"
                          :key="item_index"
                        >
                          <div
                            class="user_wrap"
                            @click="getChallageSdgsByUserId(item.id)"
                          >
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage: 'url(' + item.imgpath + ')',
                              }"
                              v-if="item.imgpath"
                            ></div>
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage:
                                  'url(' +
                                  require('@/assets/front_component/images/test/test04.png') +
                                  ')',
                              }"
                              v-else
                            ></div>
                            <div class="user_name">
                              <p class="name">
                                {{ item.last_name }} {{ item.first_name }}
                              </p>
                              <br />
                              <span
                                class="director"
                                v-if="item.group && item.group.group_role"
                                >{{ item.group.group_role }}</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        :id="'function_wrapper_' + line_index + 'eigyo'"
                        class="
                          functions_detail_wrapper
                          approvel-sdgs-challage-wrapper
                        "
                      >
                        <div
                          class="functions_detail"
                          :class="'modal' + item_index"
                          v-for="(item, item_index) in items"
                          :key="item_index"
                        >
                          <div class="left_area">
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage: 'url(' + item.imgpath + ')',
                              }"
                              v-if="item.imgpath"
                            ></div>
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage:
                                  'url(' +
                                  require('@/assets/front_component/images/test/test04.png') +
                                  ')',
                              }"
                              v-else
                            ></div>
                          </div>
                          <div class="right_area">
                            <div class="txt_wrap">
                              <div class="group">CS 営業部</div>
                              <div class="name">
                                {{ item.last_name }} {{ item.first_name }}
                              </div>
                              <p
                                class="txt"
                                v-html="htmlText(item.introduction_com)"
                              ></p>
                            </div>
                          </div>
                          <div
                            v-if="sdgsChallengeApprovedLoader == true"
                            class="p_t50 p_b50"
                          >
                            <div class="half-circle-spinner">
                              <div class="circle circle-1"></div>
                              <div class="circle circle-2"></div>
                            </div>
                          </div>
                          <template v-if="sdgsChallgeApproved.length > 0">
                            <hr />
                            <div
                              class="
                                sdgs-challage-approvel sdgs-challage-approvel-sp
                              "
                            >
                              <div class="thisterm_wrap">
                                <div class="thisterm_head">
                                  <h3 class="thisterm_ttl challenge">
                                    チャレンジシート
                                  </h3>
                                </div>
                                <ol class="challge-ul challge-ul-sp">
                                  <template
                                    v-for="sdgsChallge in sdgsChallgeApproved"
                                  >
                                    <li
                                      :key="sdgsChallge.id"
                                      v-if="sdgsChallge.type == 'challenge'"
                                    >
                                      <div v-html="sdgsChallge.content"></div>
                                    </li>
                                  </template>
                                </ol>
                              </div>
                            </div>
                            <hr />
                            <div
                              class="
                                sdgs-challage-approvel sdgs-challage-approvel-sp
                              "
                            >
                              <div class="thisterm_wrap">
                                <div class="thisterm_head">
                                  <h3 class="thisterm_ttl sdgs">
                                    SDGs個人目標
                                  </h3>
                                </div>
                                <ol class="challge-ul challge-ul-sp">
                                  <template
                                    v-for="sdgsChallge in sdgsChallgeApproved"
                                  >
                                    <li
                                      :key="sdgsChallge.id"
                                      v-if="sdgsChallge.type == 'sdgs'"
                                    >
                                      <div v-html="sdgsChallge.content"></div>
                                    </li>
                                  </template>
                                </ol>
                              </div>
                            </div>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else-if="cs_show == false" class="p_t50 p_b50">
                <div class="half-circle-spinner">
                  <div class="circle circle-1"></div>
                  <div class="circle circle-2"></div>
                </div>
              </div>

              <div v-if="csUnder_show">
                <div
                  class="staff_wrap"
                  v-for="(under_items, under_index) in csUnder_array"
                  :key="under_index"
                >
                  <h4 class="ttl_05">{{ under_items.name }}</h4>
                  <div class="staff_in_wrap" v-if="under_items.users">
                    <div class="staff_list small">
                      <div
                        v-for="(items, line_index) in under_items.users"
                        :key="line_index"
                      >
                        <div class="staff_line">
                          <div
                            class="staff_item"
                            :data-wrapper="
                              line_index + 'eigyoUnder' + under_index
                            "
                            :data-modal="'modal' + item_index"
                            v-for="(item, item_index) in items"
                            :key="item_index"
                          >
                            <div
                              class="user_wrap"
                              @click="getChallageSdgsByUserId(item.id)"
                            >
                              <div
                                class="img_wrap"
                                :style="{
                                  backgroundImage: 'url(' + item.imgpath + ')',
                                }"
                                v-if="item.imgpath"
                              ></div>
                              <div
                                class="img_wrap"
                                :style="{
                                  backgroundImage:
                                    'url(' +
                                    require('@/assets/front_component/images/test/test04.png') +
                                    ')',
                                }"
                                v-else
                              ></div>
                              <div class="user_name">
                                <p class="name">
                                  {{ item.last_name }} {{ item.first_name }}
                                </p>
                                <br />
                                <span
                                  class="director"
                                  v-if="item.group && item.group.group_role"
                                  >{{ item.group.group_role }}</span
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          :id="
                            'function_wrapper_' +
                            line_index +
                            'eigyoUnder' +
                            under_index
                          "
                          class="
                            functions_detail_wrapper
                            approvel-sdgs-challage-wrapper
                          "
                        >
                          <div
                            class="functions_detail"
                            :class="'modal' + item_index"
                            v-for="(item, item_index) in items"
                            :key="item_index"
                          >
                            <div class="left_area">
                              <div
                                class="img_wrap"
                                :style="{
                                  backgroundImage: 'url(' + item.imgpath + ')',
                                }"
                                v-if="item.imgpath"
                              ></div>
                              <div
                                class="img_wrap"
                                :style="{
                                  backgroundImage:
                                    'url(' +
                                    require('@/assets/front_component/images/test/test04.png') +
                                    ')',
                                }"
                                v-else
                              ></div>
                            </div>
                            <div class="right_area">
                              <div class="txt_wrap">
                                <div class="group">
                                  CS 営業部 {{ under_items.name }}
                                </div>
                                <div class="name">
                                  {{ item.last_name }} {{ item.first_name }}
                                </div>
                                <p
                                  class="txt"
                                  v-html="htmlText(item.introduction_com)"
                                ></p>
                              </div>
                            </div>
                            <div
                              v-if="sdgsChallengeApprovedLoader == true"
                              class="p_t50 p_b50"
                            >
                              <div class="half-circle-spinner">
                                <div class="circle circle-1"></div>
                                <div class="circle circle-2"></div>
                              </div>
                            </div>
                            <template v-if="sdgsChallgeApproved.length > 0">
                              <hr />
                              <div
                                class="
                                  sdgs-challage-approvel
                                  sdgs-challage-approvel-sp
                                "
                              >
                                <div class="thisterm_wrap">
                                  <div class="thisterm_head">
                                    <h3 class="thisterm_ttl challenge">
                                      チャレンジシート
                                    </h3>
                                  </div>
                                  <ol class="challge-ul challge-ul-sp">
                                    <template
                                      v-for="sdgsChallge in sdgsChallgeApproved"
                                    >
                                      <li
                                        :key="sdgsChallge.id"
                                        v-if="sdgsChallge.type == 'challenge'"
                                      >
                                        <div v-html="sdgsChallge.content"></div>
                                      </li>
                                    </template>
                                  </ol>
                                </div>
                              </div>
                              <hr />
                              <div
                                class="
                                  sdgs-challage-approvel
                                  sdgs-challage-approvel-sp
                                "
                              >
                                <div class="thisterm_wrap">
                                  <div class="thisterm_head">
                                    <h3 class="thisterm_ttl sdgs">
                                      SDGs個人目標
                                    </h3>
                                  </div>
                                  <ol class="challge-ul challge-ul-sp">
                                    <template
                                      v-for="sdgsChallge in sdgsChallgeApproved"
                                    >
                                      <li
                                        :key="sdgsChallge.id"
                                        v-if="sdgsChallge.type == 'sdgs'"
                                      >
                                        <div v-html="sdgsChallge.content"></div>
                                      </li>
                                    </template>
                                  </ol>
                                </div>
                              </div>
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div v-else class="p_t50 p_b50">
                    <div class="half-circle-spinner">
                      <div class="circle circle-1"></div>
                      <div class="circle circle-2"></div>
                    </div>
                  </div> -->
                </div>
              </div>
              <div v-else-if="csUnder_array.length != 0" class="p_t100 p_b100">
                <div class="half-circle-spinner">
                  <div class="circle circle-1"></div>
                  <div class="circle circle-2"></div>
                </div>
              </div>
            </div>
            <div v-else class="link_list eigyo">
              <h3 class="ttl_04">CS 営業部</h3>
              <div v-if="cs_array.length != 0">
                <div class="staff_wrap">
                  <div class="staff_list big">
                    <div
                      v-for="(items, line_index) in cs_array"
                      :key="line_index"
                    >
                      <div class="staff_line">
                        <div
                          class="staff_item"
                          :data-wrapper="line_index + 'eigyo'"
                          :data-modal="'modal' + item_index"
                          v-for="(item, item_index) in items"
                          :key="item_index"
                        >
                          <div
                            class="user_wrap"
                            @click="getChallageSdgsByUserId(item.id)"
                          >
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage: 'url(' + item.imgpath + ')',
                              }"
                              v-if="item.imgpath"
                            ></div>
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage:
                                  'url(' +
                                  require('@/assets/front_component/images/test/test04.png') +
                                  ')',
                              }"
                              v-else
                            ></div>
                            <div class="user_name">
                              <p class="name">
                                {{ item.last_name }} {{ item.first_name }}
                              </p>
                              <br />
                              <span
                                class="director"
                                v-if="item.group && item.group.group_role"
                                >{{ item.group.group_role }}</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        :id="'function_wrapper_' + line_index + 'eigyo'"
                        class="
                          functions_detail_wrapper
                          approvel-sdgs-challage-wrapper
                        "
                      >
                        <div
                          class="functions_detail"
                          :class="'modal' + item_index"
                          v-for="(item, item_index) in items"
                          :key="item_index"
                        >
                          <div class="left_area">
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage: 'url(' + item.imgpath + ')',
                              }"
                              v-if="item.imgpath"
                            ></div>
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage:
                                  'url(' +
                                  require('@/assets/front_component/images/test/test04.png') +
                                  ')',
                              }"
                              v-else
                            ></div>
                          </div>
                          <div class="right_area">
                            <div class="txt_wrap">
                              <div class="group">CS 営業部</div>
                              <div class="name">
                                {{ item.last_name }} {{ item.first_name }}
                              </div>
                              <p
                                class="txt"
                                v-html="htmlText(item.introduction_com)"
                              ></p>
                            </div>
                          </div>
                          <div
                            v-if="sdgsChallengeApprovedLoader == true"
                            class="p_t50 p_b50"
                          >
                            <div class="half-circle-spinner">
                              <div class="circle circle-1"></div>
                              <div class="circle circle-2"></div>
                            </div>
                          </div>
                          <template v-if="sdgsChallgeApproved.length > 0">
                            <hr />
                            <div
                              class="
                                sdgs-challage-approvel sdgs-challage-approvel-sp
                              "
                            >
                              <div class="thisterm_wrap">
                                <div class="thisterm_head">
                                  <h3 class="thisterm_ttl challenge">
                                    チャレンジシート
                                  </h3>
                                </div>
                                <ol class="challge-ul challge-ul-sp">
                                  <template
                                    v-for="sdgsChallge in sdgsChallgeApproved"
                                  >
                                    <li
                                      :key="sdgsChallge.id"
                                      v-if="sdgsChallge.type == 'challenge'"
                                    >
                                      <div v-html="sdgsChallge.content"></div>
                                    </li>
                                  </template>
                                </ol>
                              </div>
                            </div>
                            <hr />
                            <div
                              class="
                                sdgs-challage-approvel sdgs-challage-approvel-sp
                              "
                            >
                              <div class="thisterm_wrap">
                                <div class="thisterm_head">
                                  <h3 class="thisterm_ttl sdgs">
                                    SDGs個人目標
                                  </h3>
                                </div>
                                <ol class="challge-ul challge-ul-sp">
                                  <template
                                    v-for="sdgsChallge in sdgsChallgeApproved"
                                  >
                                    <li
                                      :key="sdgsChallge.id"
                                      v-if="sdgsChallge.type == 'sdgs'"
                                    >
                                      <div v-html="sdgsChallge.content"></div>
                                    </li>
                                  </template>
                                </ol>
                              </div>
                            </div>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else-if="cs_show == false" class="p_t50 p_b50">
                <div class="half-circle-spinner">
                  <div class="circle circle-1"></div>
                  <div class="circle circle-2"></div>
                </div>
              </div>

              <div v-if="csUnder_show">
                <div
                  class="staff_wrap"
                  v-for="(under_items, under_index) in csUnder_array"
                  :key="under_index"
                >
                  <h4 class="ttl_05">{{ under_items.name }}</h4>
                  <div class="staff_in_wrap" v-if="under_items.users != ''">
                    <div class="staff_list small">
                      <div
                        v-for="(items, line_index) in under_items.users"
                        :key="line_index"
                      >
                        <div class="staff_line">
                          <div
                            class="staff_item"
                            :data-wrapper="
                              line_index + 'eigyoUnder' + under_index
                            "
                            :data-modal="'modal' + item_index"
                            v-for="(item, item_index) in items"
                            :key="item_index"
                          >
                            <div
                              class="user_wrap"
                              @click="getChallageSdgsByUserId(item.id)"
                            >
                              <div
                                class="img_wrap"
                                :style="{
                                  backgroundImage: 'url(' + item.imgpath + ')',
                                }"
                                v-if="item.imgpath"
                              ></div>
                              <div
                                class="img_wrap"
                                :style="{
                                  backgroundImage:
                                    'url(' +
                                    require('@/assets/front_component/images/test/test04.png') +
                                    ')',
                                }"
                                v-else
                              ></div>
                              <div class="user_name">
                                <p class="name">
                                  {{ item.last_name }} {{ item.first_name }}
                                </p>
                                <br />
                                <span
                                  class="director"
                                  v-if="item.group && item.group.group_role"
                                  >{{ item.group.group_role }}</span
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          :id="
                            'function_wrapper_' +
                            line_index +
                            'eigyoUnder' +
                            under_index
                          "
                          class="
                            functions_detail_wrapper
                            approvel-sdgs-challage-wrapper
                          "
                        >
                          <div
                            class="functions_detail"
                            :class="'modal' + item_index"
                            v-for="(item, item_index) in items"
                            :key="item_index"
                          >
                            <div class="left_area">
                              <div
                                class="img_wrap"
                                :style="{
                                  backgroundImage: 'url(' + item.imgpath + ')',
                                }"
                                v-if="item.imgpath"
                              ></div>
                              <div
                                class="img_wrap"
                                :style="{
                                  backgroundImage:
                                    'url(' +
                                    require('@/assets/front_component/images/test/test04.png') +
                                    ')',
                                }"
                                v-else
                              ></div>
                            </div>
                            <div class="right_area">
                              <div class="txt_wrap">
                                <div class="group">
                                  CS 営業部 {{ under_items.name }}
                                </div>
                                <div class="name">
                                  {{ item.last_name }} {{ item.first_name }}
                                </div>
                                <p
                                  class="txt"
                                  v-html="htmlText(item.introduction_com)"
                                ></p>
                              </div>
                            </div>
                            <div
                              v-if="sdgsChallengeApprovedLoader == true"
                              class="p_t50 p_b50"
                            >
                              <div class="half-circle-spinner">
                                <div class="circle circle-1"></div>
                                <div class="circle circle-2"></div>
                              </div>
                            </div>
                            <template v-if="sdgsChallgeApproved.length > 0">
                              <hr />
                              <div
                                class="
                                  sdgs-challage-approvel
                                  sdgs-challage-approvel-sp
                                "
                              >
                                <div class="thisterm_wrap">
                                  <div class="thisterm_head">
                                    <h3 class="thisterm_ttl challenge">
                                      チャレンジシート
                                    </h3>
                                  </div>
                                  <ol class="challge-ul challge-ul-sp">
                                    <template
                                      v-for="sdgsChallge in sdgsChallgeApproved"
                                    >
                                      <li
                                        :key="sdgsChallge.id"
                                        v-if="sdgsChallge.type == 'challenge'"
                                      >
                                        <div v-html="sdgsChallge.content"></div>
                                      </li>
                                    </template>
                                  </ol>
                                </div>
                              </div>
                              <hr />
                              <div
                                class="
                                  sdgs-challage-approvel
                                  sdgs-challage-approvel-sp
                                "
                              >
                                <div class="thisterm_wrap">
                                  <div class="thisterm_head">
                                    <h3 class="thisterm_ttl sdgs">
                                      SDGs個人目標
                                    </h3>
                                  </div>
                                  <ol class="challge-ul challge-ul-sp">
                                    <template
                                      v-for="sdgsChallge in sdgsChallgeApproved"
                                    >
                                      <li
                                        :key="sdgsChallge.id"
                                        v-if="sdgsChallge.type == 'sdgs'"
                                      >
                                        <div v-html="sdgsChallge.content"></div>
                                      </li>
                                    </template>
                                  </ol>
                                </div>
                              </div>
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div v-else class="p_t50 p_b50">
                    <div class="half-circle-spinner">
                      <div class="circle circle-1"></div>
                      <div class="circle circle-2"></div>
                    </div>
                  </div> -->
                </div>
              </div>
              <div v-else-if="csUnder_array.length != 0" class="p_t100 p_b100">
                <div class="half-circle-spinner">
                  <div class="circle circle-1"></div>
                  <div class="circle circle-2"></div>
                </div>
              </div>
            </div>

            <div v-if="phone" class="link_list control">
              <h3 class="ttl_04">コントロール部</h3>
              <div v-if="control_array.length != 0">
                <div class="staff_wrap">
                  <div class="staff_list big">
                    <div
                      v-for="(items, line_index) in control_array"
                      :key="line_index"
                    >
                      <div class="staff_line">
                        <div
                          class="staff_item"
                          :data-wrapper="line_index + 'control'"
                          :data-modal="'modal' + item_index"
                          v-for="(item, item_index) in items"
                          :key="item_index"
                        >
                          <div
                            class="user_wrap"
                            @click="getChallageSdgsByUserId(item.id)"
                          >
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage: 'url(' + item.imgpath + ')',
                              }"
                              v-if="item.imgpath"
                            ></div>
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage:
                                  'url(' +
                                  require('@/assets/front_component/images/test/test04.png') +
                                  ')',
                              }"
                              v-else
                            ></div>
                            <div class="user_name">
                              <p class="name">
                                {{ item.last_name }} {{ item.first_name }}
                              </p>
                              <br />
                              <span
                                class="director"
                                v-if="item.group && item.group.group_role"
                                >{{ item.group.group_role }}</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        :id="'function_wrapper_' + line_index + 'control'"
                        class="
                          functions_detail_wrapper
                          approvel-sdgs-challage-wrapper
                        "
                      >
                        <div
                          class="functions_detail"
                          :class="'modal' + item_index"
                          v-for="(item, item_index) in items"
                          :key="item_index"
                        >
                          <div class="left_area">
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage: 'url(' + item.imgpath + ')',
                              }"
                              v-if="item.imgpath"
                            ></div>
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage:
                                  'url(' +
                                  require('@/assets/front_component/images/test/test04.png') +
                                  ')',
                              }"
                              v-else
                            ></div>
                          </div>
                          <div class="right_area">
                            <div class="txt_wrap">
                              <div class="group">コントロール部</div>
                              <div class="name">
                                {{ item.last_name }} {{ item.first_name }}
                              </div>
                              <p
                                class="txt"
                                v-html="htmlText(item.introduction_com)"
                              ></p>
                            </div>
                          </div>
                          <div
                            v-if="sdgsChallengeApprovedLoader == true"
                            class="p_t50 p_b50"
                          >
                            <div class="half-circle-spinner">
                              <div class="circle circle-1"></div>
                              <div class="circle circle-2"></div>
                            </div>
                          </div>
                          <template v-if="sdgsChallgeApproved.length > 0">
                            <hr />
                            <div
                              class="
                                sdgs-challage-approvel sdgs-challage-approvel-sp
                              "
                            >
                              <div class="thisterm_wrap">
                                <div class="thisterm_head">
                                  <h3 class="thisterm_ttl challenge">
                                    チャレンジシート
                                  </h3>
                                </div>
                                <ol class="challge-ul challge-ul-sp">
                                  <template
                                    v-for="sdgsChallge in sdgsChallgeApproved"
                                  >
                                    <li
                                      :key="sdgsChallge.id"
                                      v-if="sdgsChallge.type == 'challenge'"
                                    >
                                      <div v-html="sdgsChallge.content"></div>
                                    </li>
                                  </template>
                                </ol>
                              </div>
                            </div>
                            <hr />
                            <div
                              class="
                                sdgs-challage-approvel sdgs-challage-approvel-sp
                              "
                            >
                              <div class="thisterm_wrap">
                                <div class="thisterm_head">
                                  <h3 class="thisterm_ttl sdgs">
                                    SDGs個人目標
                                  </h3>
                                </div>
                                <ol class="challge-ul challge-ul-sp">
                                  <template
                                    v-for="sdgsChallge in sdgsChallgeApproved"
                                  >
                                    <li
                                      :key="sdgsChallge.id"
                                      v-if="sdgsChallge.type == 'sdgs'"
                                    >
                                      <div v-html="sdgsChallge.content"></div>
                                    </li>
                                  </template>
                                </ol>
                              </div>
                            </div>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else-if="control_show == false" class="p_t50 p_b50">
                <div class="half-circle-spinner">
                  <div class="circle circle-1"></div>
                  <div class="circle circle-2"></div>
                </div>
              </div>

              <div v-if="controlUnder_show">
                <div
                  class="staff_wrap"
                  v-for="(under_items, under_index) in controlUnder_array"
                  :key="under_index"
                >
                  <h4 class="ttl_05">{{ under_items.name }}</h4>
                  <div class="staff_in_wrap" v-if="under_items.users != ''">
                    <div class="staff_list small">
                      <div
                        v-for="(items, line_index) in under_items.users"
                        :key="line_index"
                      >
                        <div class="staff_line">
                          <div
                            class="staff_item"
                            :data-wrapper="
                              line_index + 'controlUnder' + under_index
                            "
                            :data-modal="'modal' + item_index"
                            v-for="(item, item_index) in items"
                            :key="item_index"
                          >
                            <div
                              class="user_wrap"
                              @click="getChallageSdgsByUserId(item.id)"
                            >
                              <div
                                class="img_wrap"
                                :style="{
                                  backgroundImage: 'url(' + item.imgpath + ')',
                                }"
                                v-if="item.imgpath"
                              ></div>
                              <div
                                class="img_wrap"
                                :style="{
                                  backgroundImage:
                                    'url(' +
                                    require('@/assets/front_component/images/test/test04.png') +
                                    ')',
                                }"
                                v-else
                              ></div>
                              <div class="user_name">
                                <p class="name">
                                  {{ item.last_name }} {{ item.first_name }}
                                </p>
                                <br />
                                <span
                                  class="director"
                                  v-if="item.group && item.group.group_role"
                                  >{{ item.group.group_role }}</span
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          :id="
                            'function_wrapper_' +
                            line_index +
                            'controlUnder' +
                            under_index
                          "
                          class="
                            functions_detail_wrapper
                            approvel-sdgs-challage-wrapper
                          "
                        >
                          <div
                            class="functions_detail"
                            :class="'modal' + item_index"
                            v-for="(item, item_index) in items"
                            :key="item_index"
                          >
                            <div class="left_area">
                              <div
                                class="img_wrap"
                                :style="{
                                  backgroundImage: 'url(' + item.imgpath + ')',
                                }"
                                v-if="item.imgpath"
                              ></div>
                              <div
                                class="img_wrap"
                                :style="{
                                  backgroundImage:
                                    'url(' +
                                    require('@/assets/front_component/images/test/test04.png') +
                                    ')',
                                }"
                                v-else
                              ></div>
                            </div>
                            <div class="right_area">
                              <div class="txt_wrap">
                                <div class="group">
                                  コントロール部 {{ under_items.name }}
                                </div>
                                <div class="name">
                                  {{ item.last_name }} {{ item.first_name }}
                                </div>
                                <p
                                  class="txt"
                                  v-html="htmlText(item.introduction_com)"
                                ></p>
                              </div>
                            </div>
                            <div
                              v-if="sdgsChallengeApprovedLoader == true"
                              class="p_t50 p_b50"
                            >
                              <div class="half-circle-spinner">
                                <div class="circle circle-1"></div>
                                <div class="circle circle-2"></div>
                              </div>
                            </div>
                            <template v-if="sdgsChallgeApproved.length > 0">
                              <hr />
                              <div
                                class="
                                  sdgs-challage-approvel
                                  sdgs-challage-approvel-sp
                                "
                              >
                                <div class="thisterm_wrap">
                                  <div class="thisterm_head">
                                    <h3 class="thisterm_ttl challenge">
                                      チャレンジシート
                                    </h3>
                                  </div>
                                  <ol class="challge-ul challge-ul-sp">
                                    <template
                                      v-for="sdgsChallge in sdgsChallgeApproved"
                                    >
                                      <li
                                        :key="sdgsChallge.id"
                                        v-if="sdgsChallge.type == 'challenge'"
                                      >
                                        <div v-html="sdgsChallge.content"></div>
                                      </li>
                                    </template>
                                  </ol>
                                </div>
                              </div>
                              <hr />
                              <div
                                class="
                                  sdgs-challage-approvel
                                  sdgs-challage-approvel-sp
                                "
                              >
                                <div class="thisterm_wrap">
                                  <div class="thisterm_head">
                                    <h3 class="thisterm_ttl sdgs">
                                      SDGs個人目標
                                    </h3>
                                  </div>
                                  <ol class="challge-ul challge-ul-sp">
                                    <template
                                      v-for="sdgsChallge in sdgsChallgeApproved"
                                    >
                                      <li
                                        :key="sdgsChallge.id"
                                        v-if="sdgsChallge.type == 'sdgs'"
                                      >
                                        <div v-html="sdgsChallge.content"></div>
                                      </li>
                                    </template>
                                  </ol>
                                </div>
                              </div>
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div v-else class="p_t50 p_b50">
                    <div class="half-circle-spinner">
                      <div class="circle circle-1"></div>
                      <div class="circle circle-2"></div>
                    </div>
                  </div> -->
                </div>
              </div>
              <div
                v-else-if="controlUnder_array.length != 0"
                class="p_t100 p_b100"
              >
                <div class="half-circle-spinner">
                  <div class="circle circle-1"></div>
                  <div class="circle circle-2"></div>
                </div>
              </div>
            </div>
            <div v-else class="link_list control">
              <h3 class="ttl_04">コントロール部</h3>
              <div v-if="control_array.length != 0">
                <div class="staff_wrap">
                  <div class="staff_list big">
                    <div
                      v-for="(items, line_index) in control_array"
                      :key="line_index"
                    >
                      <div class="staff_line">
                        <div
                          class="staff_item"
                          :data-wrapper="line_index + 'control'"
                          :data-modal="'modal' + item_index"
                          v-for="(item, item_index) in items"
                          :key="item_index"
                        >
                          <div
                            class="user_wrap"
                            @click="getChallageSdgsByUserId(item.id)"
                          >
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage: 'url(' + item.imgpath + ')',
                              }"
                              v-if="item.imgpath"
                            ></div>
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage:
                                  'url(' +
                                  require('@/assets/front_component/images/test/test04.png') +
                                  ')',
                              }"
                              v-else
                            ></div>
                            <div class="user_name">
                              <p class="name">
                                {{ item.last_name }} {{ item.first_name }}
                              </p>
                              <br />
                              <span
                                class="director"
                                v-if="item.group && item.group.group_role"
                                >{{ item.group.group_role }}</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        :id="'function_wrapper_' + line_index + 'control'"
                        class="
                          functions_detail_wrapper
                          approvel-sdgs-challage-wrapper
                        "
                      >
                        <div
                          class="functions_detail"
                          :class="'modal' + item_index"
                          v-for="(item, item_index) in items"
                          :key="item_index"
                        >
                          <div class="left_area">
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage: 'url(' + item.imgpath + ')',
                              }"
                              v-if="item.imgpath"
                            ></div>
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage:
                                  'url(' +
                                  require('@/assets/front_component/images/test/test04.png') +
                                  ')',
                              }"
                              v-else
                            ></div>
                          </div>
                          <div class="right_area">
                            <div class="txt_wrap">
                              <div class="group">コントロール部</div>
                              <div class="name">
                                {{ item.last_name }} {{ item.first_name }}
                              </div>
                              <p
                                class="txt"
                                v-html="htmlText(item.introduction_com)"
                              ></p>
                            </div>
                          </div>
                          <div
                            v-if="sdgsChallengeApprovedLoader == true"
                            class="p_t50 p_b50"
                          >
                            <div class="half-circle-spinner">
                              <div class="circle circle-1"></div>
                              <div class="circle circle-2"></div>
                            </div>
                          </div>
                          <template v-if="sdgsChallgeApproved.length > 0">
                            <hr />
                            <div
                              class="
                                sdgs-challage-approvel sdgs-challage-approvel-sp
                              "
                            >
                              <div class="thisterm_wrap">
                                <div class="thisterm_head">
                                  <h3 class="thisterm_ttl challenge">
                                    チャレンジシート
                                  </h3>
                                </div>
                                <ol class="challge-ul challge-ul-sp">
                                  <template
                                    v-for="sdgsChallge in sdgsChallgeApproved"
                                  >
                                    <li
                                      :key="sdgsChallge.id"
                                      v-if="sdgsChallge.type == 'challenge'"
                                    >
                                      <div v-html="sdgsChallge.content"></div>
                                    </li>
                                  </template>
                                </ol>
                              </div>
                            </div>
                            <hr />
                            <div
                              class="
                                sdgs-challage-approvel sdgs-challage-approvel-sp
                              "
                            >
                              <div class="thisterm_wrap">
                                <div class="thisterm_head">
                                  <h3 class="thisterm_ttl sdgs">
                                    SDGs個人目標
                                  </h3>
                                </div>
                                <ol class="challge-ul challge-ul-sp">
                                  <template
                                    v-for="sdgsChallge in sdgsChallgeApproved"
                                  >
                                    <li
                                      :key="sdgsChallge.id"
                                      v-if="sdgsChallge.type == 'sdgs'"
                                    >
                                      <div v-html="sdgsChallge.content"></div>
                                    </li>
                                  </template>
                                </ol>
                              </div>
                            </div>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else-if="control_show == false" class="p_t50 p_b50">
                <div class="half-circle-spinner">
                  <div class="circle circle-1"></div>
                  <div class="circle circle-2"></div>
                </div>
              </div>

              <div v-if="controlUnder_show">
                <div
                  class="staff_wrap"
                  v-for="(under_items, under_index) in controlUnder_array"
                  :key="under_index"
                >
                  <h4 class="ttl_05">{{ under_items.name }}</h4>
                  <div class="staff_in_wrap" v-if="under_items.users != ''">
                    <div class="staff_list small">
                      <div
                        v-for="(items, line_index) in under_items.users"
                        :key="line_index"
                      >
                        <div class="staff_line">
                          <div
                            class="staff_item"
                            :data-wrapper="
                              line_index + 'controlUnder' + under_index
                            "
                            :data-modal="'modal' + item_index"
                            v-for="(item, item_index) in items"
                            :key="item_index"
                          >
                            <div
                              class="user_wrap"
                              @click="getChallageSdgsByUserId(item.id)"
                            >
                              <div
                                class="img_wrap"
                                :style="{
                                  backgroundImage: 'url(' + item.imgpath + ')',
                                }"
                                v-if="item.imgpath"
                              ></div>
                              <div
                                class="img_wrap"
                                :style="{
                                  backgroundImage:
                                    'url(' +
                                    require('@/assets/front_component/images/test/test04.png') +
                                    ')',
                                }"
                                v-else
                              ></div>
                              <div class="user_name">
                                <p class="name">
                                  {{ item.last_name }} {{ item.first_name }}
                                </p>
                                <br />
                                <span
                                  class="director"
                                  v-if="item.group && item.group.group_role"
                                  >{{ item.group.group_role }}</span
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          :id="
                            'function_wrapper_' +
                            line_index +
                            'controlUnder' +
                            under_index
                          "
                          class="
                            functions_detail_wrapper
                            approvel-sdgs-challage-wrapper
                          "
                        >
                          <div
                            class="functions_detail"
                            :class="'modal' + item_index"
                            v-for="(item, item_index) in items"
                            :key="item_index"
                          >
                            <div class="left_area">
                              <div
                                class="img_wrap"
                                :style="{
                                  backgroundImage: 'url(' + item.imgpath + ')',
                                }"
                                v-if="item.imgpath"
                              ></div>
                              <div
                                class="img_wrap"
                                :style="{
                                  backgroundImage:
                                    'url(' +
                                    require('@/assets/front_component/images/test/test04.png') +
                                    ')',
                                }"
                                v-else
                              ></div>
                            </div>
                            <div class="right_area">
                              <div class="txt_wrap">
                                <div class="group">
                                  コントロール部 {{ under_items.name }}
                                </div>
                                <div class="name">
                                  {{ item.last_name }} {{ item.first_name }}
                                </div>
                                <p
                                  class="txt"
                                  v-html="htmlText(item.introduction_com)"
                                ></p>
                              </div>
                            </div>
                            <div
                              v-if="sdgsChallengeApprovedLoader == true"
                              class="p_t50 p_b50"
                            >
                              <div class="half-circle-spinner">
                                <div class="circle circle-1"></div>
                                <div class="circle circle-2"></div>
                              </div>
                            </div>
                            <template v-if="sdgsChallgeApproved.length > 0">
                              <hr />
                              <div
                                class="
                                  sdgs-challage-approvel
                                  sdgs-challage-approvel-sp
                                "
                              >
                                <div class="thisterm_wrap">
                                  <div class="thisterm_head">
                                    <h3 class="thisterm_ttl challenge">
                                      チャレンジシート
                                    </h3>
                                  </div>
                                  <ol class="challge-ul challge-ul-sp">
                                    <template
                                      v-for="sdgsChallge in sdgsChallgeApproved"
                                    >
                                      <li
                                        :key="sdgsChallge.id"
                                        v-if="sdgsChallge.type == 'challenge'"
                                      >
                                        <div v-html="sdgsChallge.content"></div>
                                      </li>
                                    </template>
                                  </ol>
                                </div>
                              </div>
                              <hr />
                              <div
                                class="
                                  sdgs-challage-approvel
                                  sdgs-challage-approvel-sp
                                "
                              >
                                <div class="thisterm_wrap">
                                  <div class="thisterm_head">
                                    <h3 class="thisterm_ttl sdgs">
                                      SDGs個人目標
                                    </h3>
                                  </div>
                                  <ol class="challge-ul challge-ul-sp">
                                    <template
                                      v-for="sdgsChallge in sdgsChallgeApproved"
                                    >
                                      <li
                                        :key="sdgsChallge.id"
                                        v-if="sdgsChallge.type == 'sdgs'"
                                      >
                                        <div v-html="sdgsChallge.content"></div>
                                      </li>
                                    </template>
                                  </ol>
                                </div>
                              </div>
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div v-else class="p_t50 p_b50">
                    <div class="half-circle-spinner">
                      <div class="circle circle-1"></div>
                      <div class="circle circle-2"></div>
                    </div>
                  </div> -->
                </div>
              </div>
              <div
                v-else-if="controlUnder_array.length != 0"
                class="p_t100 p_b100"
              >
                <div class="half-circle-spinner">
                  <div class="circle circle-1"></div>
                  <div class="circle circle-2"></div>
                </div>
              </div>
            </div>

            <div v-if="phone" class="link_list area">
              <h3 class="ttl_04">エリアサービス部</h3>
              <div v-if="areaService_array.length != 0">
                <div class="staff_wrap">
                  <div class="staff_list big">
                    <div
                      v-for="(items, line_index) in areaService_array"
                      :key="line_index"
                    >
                      <div class="staff_line">
                        <div
                          class="staff_item"
                          :data-wrapper="line_index + 'areaService'"
                          :data-modal="'modal' + item_index"
                          v-for="(item, item_index) in items"
                          :key="item_index"
                        >
                          <div
                            class="user_wrap"
                            @click="getChallageSdgsByUserId(item.id)"
                          >
                          
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage: 'url(' + item.imgpath + ')',
                              }"
                              v-if="item.imgpath"
                            ></div>
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage:
                                  'url(' +
                                  require('@/assets/front_component/images/test/test04.png') +
                                  ')',
                              }"
                              v-else
                            ></div>
                            <div class="user_name">
                              <p class="name">
                                {{ item.last_name }} {{ item.first_name }}
                              </p>
                              <br />
                              <span
                                class="director"
                                v-if="item.group && item.group.group_role"
                                >{{ item.group.group_role }}</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        :id="'function_wrapper_' + line_index + 'areaService'"
                        class="
                          functions_detail_wrapper
                          approvel-sdgs-challage-wrapper
                        "
                      >
                        <div
                          class="functions_detail"
                          :class="'modal' + item_index"
                          v-for="(item, item_index) in items"
                          :key="item_index"
                        >
                          <div class="left_area">
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage: 'url(' + item.imgpath + ')',
                              }"
                              v-if="item.imgpath"
                            ></div>
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage:
                                  'url(' +
                                  require('@/assets/front_component/images/test/test04.png') +
                                  ')',
                              }"
                              v-else
                            ></div>
                          </div>
                          <div class="right_area">
                            <div class="txt_wrap">
                              <div class="group">エリアサービス部</div>
                              <div class="name">
                                {{ item.last_name }} {{ item.first_name }}
                              </div>
                              <p
                                class="txt"
                                v-html="htmlText(item.introduction_com)"
                              ></p>
                            </div>
                          </div>
                          <div
                            v-if="sdgsChallengeApprovedLoader == true"
                            class="p_t50 p_b50"
                          >
                            <div class="half-circle-spinner">
                              <div class="circle circle-1"></div>
                              <div class="circle circle-2"></div>
                            </div>
                          </div>
                          <template v-if="sdgsChallgeApproved.length > 0">
                            <hr />
                            <div
                              class="
                                sdgs-challage-approvel sdgs-challage-approvel-sp
                              "
                            >
                              <div class="thisterm_wrap">
                                <div class="thisterm_head">
                                  <h3 class="thisterm_ttl challenge">
                                    チャレンジシート
                                  </h3>
                                </div>
                                <ol class="challge-ul challge-ul-sp">
                                  <template
                                    v-for="sdgsChallge in sdgsChallgeApproved"
                                  >
                                    <li
                                      :key="sdgsChallge.id"
                                      v-if="sdgsChallge.type == 'challenge'"
                                    >
                                      <div v-html="sdgsChallge.content"></div>
                                    </li>
                                  </template>
                                </ol>
                              </div>
                            </div>
                            <hr />
                            <div
                              class="
                                sdgs-challage-approvel sdgs-challage-approvel-sp
                              "
                            >
                              <div class="thisterm_wrap">
                                <div class="thisterm_head">
                                  <h3 class="thisterm_ttl sdgs">
                                    SDGs個人目標
                                  </h3>
                                </div>
                                <ol class="challge-ul challge-ul-sp">
                                  <template
                                    v-for="sdgsChallge in sdgsChallgeApproved"
                                  >
                                    <li
                                      :key="sdgsChallge.id"
                                      v-if="sdgsChallge.type == 'sdgs'"
                                    >
                                      <div v-html="sdgsChallge.content"></div>
                                    </li>
                                  </template>
                                </ol>
                              </div>
                            </div>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else-if="areaService_show == false" class="p_t50 p_b50">
                <div class="half-circle-spinner">
                  <div class="circle circle-1"></div>
                  <div class="circle circle-2"></div>
                </div>
              </div>

              <div v-if="areaServiceUnder_show">
                <div
                  class="staff_wrap"
                  v-for="(under_items, under_index) in areaServiceUnder_array"
                  :key="under_index"
                >
                  <h4 class="ttl_05">{{ under_items.name }}</h4>
                  <div class="staff_in_wrap" v-if="under_items.users != ''">
                    <div class="staff_list small">
                      <div
                        v-for="(items, line_index) in under_items.users"
                        :key="line_index"
                      >
                        <div class="staff_line">
                          <div
                            class="staff_item"
                            :data-wrapper="
                              line_index + 'areaServiceUnder' + under_index
                            "
                            :data-modal="'modal' + item_index"
                            v-for="(item, item_index) in items"
                            :key="item_index"
                          >
                            <div
                              class="user_wrap"
                              @click="getChallageSdgsByUserId(item.id)"
                            >
                              <div
                                class="img_wrap"
                                :style="{
                                  backgroundImage: 'url(' + item.imgpath + ')',
                                }"
                                v-if="item.imgpath"
                              ></div>
                              <div
                                class="img_wrap"
                                :style="{
                                  backgroundImage:
                                    'url(' +
                                    require('@/assets/front_component/images/test/test04.png') +
                                    ')',
                                }"
                                v-else
                              ></div>
                              <div class="user_name">
                                <p class="name">
                                  {{ item.last_name }} {{ item.first_name }}
                                </p>
                                <br />
                                <span
                                  class="director"
                                  v-if="item.group && item.group.group_role"
                                  >{{ item.group.group_role }}</span
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          :id="
                            'function_wrapper_' +
                            line_index +
                            'areaServiceUnder' +
                            under_index
                          "
                          class="
                            functions_detail_wrapper
                            approvel-sdgs-challage-wrapper
                          "
                        >
                          <div
                            class="functions_detail"
                            :class="'modal' + item_index"
                            v-for="(item, item_index) in items"
                            :key="item_index"
                          >
                            <div class="left_area">
                              <div
                                class="img_wrap"
                                :style="{
                                  backgroundImage: 'url(' + item.imgpath + ')',
                                }"
                                v-if="item.imgpath"
                              ></div>
                              <div
                                class="img_wrap"
                                :style="{
                                  backgroundImage:
                                    'url(' +
                                    require('@/assets/front_component/images/test/test04.png') +
                                    ')',
                                }"
                                v-else
                              ></div>
                            </div>
                            <div class="right_area">
                              <div class="txt_wrap">
                                <div class="group">
                                  エリアサービス部 {{ under_items.name }}
                                </div>
                                <div class="name">
                                  {{ item.last_name }} {{ item.first_name }}
                                </div>
                                <p
                                  class="txt"
                                  v-html="htmlText(item.introduction_com)"
                                ></p>
                              </div>
                            </div>
                            <div
                              v-if="sdgsChallengeApprovedLoader == true"
                              class="p_t50 p_b50"
                            >
                              <div class="half-circle-spinner">
                                <div class="circle circle-1"></div>
                                <div class="circle circle-2"></div>
                              </div>
                            </div>
                            <template v-if="sdgsChallgeApproved.length > 0">
                              <hr />
                              <div
                                class="
                                  sdgs-challage-approvel
                                  sdgs-challage-approvel-sp
                                "
                              >
                                <div class="thisterm_wrap">
                                  <div class="thisterm_head">
                                    <h3 class="thisterm_ttl challenge">
                                      チャレンジシート
                                    </h3>
                                  </div>
                                  <ol class="challge-ul challge-ul-sp">
                                    <template
                                      v-for="sdgsChallge in sdgsChallgeApproved"
                                    >
                                      <li
                                        :key="sdgsChallge.id"
                                        v-if="sdgsChallge.type == 'challenge'"
                                      >
                                        <div v-html="sdgsChallge.content"></div>
                                      </li>
                                    </template>
                                  </ol>
                                </div>
                              </div>
                              <hr />
                              <div
                                class="
                                  sdgs-challage-approvel
                                  sdgs-challage-approvel-sp
                                "
                              >
                                <div class="thisterm_wrap">
                                  <div class="thisterm_head">
                                    <h3 class="thisterm_ttl sdgs">
                                      SDGs個人目標
                                    </h3>
                                  </div>
                                  <ol class="challge-ul challge-ul-sp">
                                    <template
                                      v-for="sdgsChallge in sdgsChallgeApproved"
                                    >
                                      <li
                                        :key="sdgsChallge.id"
                                        v-if="sdgsChallge.type == 'sdgs'"
                                      >
                                        <div v-html="sdgsChallge.content"></div>
                                      </li>
                                    </template>
                                  </ol>
                                </div>
                              </div>
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div v-else class="p_t50 p_b50">
                    <div class="half-circle-spinner">
                      <div class="circle circle-1"></div>
                      <div class="circle circle-2"></div>
                    </div>
                  </div> -->
                </div>
              </div>
              <div
                v-else-if="areaServiceUnder_array.length != 0"
                class="p_t100 p_b100"
              >
                <div class="half-circle-spinner">
                  <div class="circle circle-1"></div>
                  <div class="circle circle-2"></div>
                </div>
              </div>
            </div>
            <div v-else class="link_list area">
              <h3 class="ttl_04">エリアサービス部</h3>
              <div v-if="areaService_array.length != 0">
                <div class="staff_wrap">
                  <div class="staff_list big">
                    <div
                      v-for="(items, line_index) in areaService_array"
                      :key="line_index"
                    >
                      <div class="staff_line">
                        <div
                          class="staff_item"
                          :data-wrapper="line_index + 'areaService'"
                          :data-modal="'modal' + item_index"
                          v-for="(item, item_index) in items"
                          :key="item_index"
                        >
                          <div
                            class="user_wrap"
                            @click="getChallageSdgsByUserId(item.id)"
                          >
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage: 'url(' + item.imgpath + ')',
                              }"
                              v-if="item.imgpath"
                            ></div>
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage:
                                  'url(' +
                                  require('@/assets/front_component/images/test/test04.png') +
                                  ')',
                              }"
                              v-else
                            ></div>
                            <div class="user_name">
                              <p class="name">
                                {{ item.last_name }} {{ item.first_name }}
                              </p>
                              <br />
                              <span
                                class="director"
                                v-if="item.group && item.group.group_role"
                                >{{ item.group.group_role }}</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        :id="'function_wrapper_' + line_index + 'areaService'"
                        class="
                          functions_detail_wrapper
                          approvel-sdgs-challage-wrapper
                        "
                      >
                        <div
                          class="functions_detail"
                          :class="'modal' + item_index"
                          v-for="(item, item_index) in items"
                          :key="item_index"
                        >
                          <div class="left_area">
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage: 'url(' + item.imgpath + ')',
                              }"
                              v-if="item.imgpath"
                            ></div>
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage:
                                  'url(' +
                                  require('@/assets/front_component/images/test/test04.png') +
                                  ')',
                              }"
                              v-else
                            ></div>
                          </div>
                          <div class="right_area">
                            <div class="txt_wrap">
                              <div class="group">エリアサービス部</div>
                              <div class="name">
                                {{ item.last_name }} {{ item.first_name }}
                              </div>
                              <p
                                class="txt"
                                v-html="htmlText(item.introduction_com)"
                              ></p>
                            </div>
                          </div>
                          <div
                            v-if="sdgsChallengeApprovedLoader == true"
                            class="p_t50 p_b50"
                          >
                            <div class="half-circle-spinner">
                              <div class="circle circle-1"></div>
                              <div class="circle circle-2"></div>
                            </div>
                          </div>
                          <template v-if="sdgsChallgeApproved.length > 0">
                            <hr />
                            <div
                              class="
                                sdgs-challage-approvel sdgs-challage-approvel-sp
                              "
                            >
                              <div class="thisterm_wrap">
                                <div class="thisterm_head">
                                  <h3 class="thisterm_ttl challenge">
                                    チャレンジシート
                                  </h3>
                                </div>
                                <ol class="challge-ul challge-ul-sp">
                                  <template
                                    v-for="sdgsChallge in sdgsChallgeApproved"
                                  >
                                    <li
                                      :key="sdgsChallge.id"
                                      v-if="sdgsChallge.type == 'challenge'"
                                    >
                                      <div v-html="sdgsChallge.content"></div>
                                    </li>
                                  </template>
                                </ol>
                              </div>
                            </div>
                            <hr />
                            <div
                              class="
                                sdgs-challage-approvel sdgs-challage-approvel-sp
                              "
                            >
                              <div class="thisterm_wrap">
                                <div class="thisterm_head">
                                  <h3 class="thisterm_ttl sdgs">
                                    SDGs個人目標
                                  </h3>
                                </div>
                                <ol class="challge-ul challge-ul-sp">
                                  <template
                                    v-for="sdgsChallge in sdgsChallgeApproved"
                                  >
                                    <li
                                      :key="sdgsChallge.id"
                                      v-if="sdgsChallge.type == 'sdgs'"
                                    >
                                      <div v-html="sdgsChallge.content"></div>
                                    </li>
                                  </template>
                                </ol>
                              </div>
                            </div>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else-if="areaService_show == false" class="p_t50 p_b50">
                <div class="half-circle-spinner">
                  <div class="circle circle-1"></div>
                  <div class="circle circle-2"></div>
                </div>
              </div>

              <div v-if="areaServiceUnder_show">
                <div
                  class="staff_wrap"
                  v-for="(under_items, under_index) in areaServiceUnder_array"
                  :key="under_index"
                >
                  <h4 class="ttl_05">{{ under_items.name }}</h4>
                  <div class="staff_in_wrap" v-if="under_items.users != ''">
                    <div class="staff_list small">
                      <div
                        v-for="(items, line_index) in under_items.users"
                        :key="line_index"
                      >
                        <div class="staff_line">
                          <div
                            class="staff_item"
                            :data-wrapper="
                              line_index + 'areaServiceUnder' + under_index
                            "
                            :data-modal="'modal' + item_index"
                            v-for="(item, item_index) in items"
                            :key="item_index"
                          >
                            <div
                              class="user_wrap"
                              @click="getChallageSdgsByUserId(item.id)"
                            >
                              <div
                                class="img_wrap"
                                :style="{
                                  backgroundImage: 'url(' + item.imgpath + ')',
                                }"
                                v-if="item.imgpath"
                              ></div>
                              <div
                                class="img_wrap"
                                :style="{
                                  backgroundImage:
                                    'url(' +
                                    require('@/assets/front_component/images/test/test04.png') +
                                    ')',
                                }"
                                v-else
                              ></div>
                              <div class="user_name">
                                <p class="name">
                                  {{ item.last_name }} {{ item.first_name }}
                                </p>
                                <br />
                                <span
                                  class="director"
                                  v-if="item.group && item.group.group_role"
                                  >{{ item.group.group_role }}</span
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          :id="
                            'function_wrapper_' +
                            line_index +
                            'areaServiceUnder' +
                            under_index
                          "
                          class="
                            functions_detail_wrapper
                            approvel-sdgs-challage-wrapper
                          "
                        >
                          <div
                            class="functions_detail"
                            :class="'modal' + item_index"
                            v-for="(item, item_index) in items"
                            :key="item_index"
                          >
                            <div class="left_area">
                              <div
                                class="img_wrap"
                                :style="{
                                  backgroundImage: 'url(' + item.imgpath + ')',
                                }"
                                v-if="item.imgpath"
                              ></div>
                              <div
                                class="img_wrap"
                                :style="{
                                  backgroundImage:
                                    'url(' +
                                    require('@/assets/front_component/images/test/test04.png') +
                                    ')',
                                }"
                                v-else
                              ></div>
                            </div>
                            <div class="right_area">
                              <div class="txt_wrap">
                                <div class="group">
                                  エリアサービス部 {{ under_items.name }}
                                </div>
                                <div class="name">
                                  {{ item.last_name }} {{ item.first_name }}
                                </div>
                                <p
                                  class="txt"
                                  v-html="htmlText(item.introduction_com)"
                                ></p>
                              </div>
                            </div>
                            <div
                              v-if="sdgsChallengeApprovedLoader == true"
                              class="p_t50 p_b50"
                            >
                              <div class="half-circle-spinner">
                                <div class="circle circle-1"></div>
                                <div class="circle circle-2"></div>
                              </div>
                            </div>
                            <template v-if="sdgsChallgeApproved.length > 0">
                              <hr />
                              <div
                                class="
                                  sdgs-challage-approvel
                                  sdgs-challage-approvel-sp
                                "
                              >
                                <div class="thisterm_wrap">
                                  <div class="thisterm_head">
                                    <h3 class="thisterm_ttl challenge">
                                      チャレンジシート
                                    </h3>
                                  </div>
                                  <ol class="challge-ul challge-ul-sp">
                                    <template
                                      v-for="sdgsChallge in sdgsChallgeApproved"
                                    >
                                      <li
                                        :key="sdgsChallge.id"
                                        v-if="sdgsChallge.type == 'challenge'"
                                      >
                                        <div v-html="sdgsChallge.content"></div>
                                      </li>
                                    </template>
                                  </ol>
                                </div>
                              </div>
                              <hr />
                              <div
                                class="
                                  sdgs-challage-approvel
                                  sdgs-challage-approvel-sp
                                "
                              >
                                <div class="thisterm_wrap">
                                  <div class="thisterm_head">
                                    <h3 class="thisterm_ttl sdgs">
                                      SDGs個人目標
                                    </h3>
                                  </div>
                                  <ol class="challge-ul challge-ul-sp">
                                    <template
                                      v-for="sdgsChallge in sdgsChallgeApproved"
                                    >
                                      <li
                                        :key="sdgsChallge.id"
                                        v-if="sdgsChallge.type == 'sdgs'"
                                      >
                                        <div v-html="sdgsChallge.content"></div>
                                      </li>
                                    </template>
                                  </ol>
                                </div>
                              </div>
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div v-else class="p_t50 p_b50">
                    <div class="half-circle-spinner">
                      <div class="circle circle-1"></div>
                      <div class="circle circle-2"></div>
                    </div>
                  </div> -->
                </div>
              </div>
              <div
                v-else-if="areaServiceUnder_array.length != 0"
                class="p_t100 p_b100"
              >
                <div class="half-circle-spinner">
                  <div class="circle circle-1"></div>
                  <div class="circle circle-2"></div>
                </div>
              </div>
            </div>

            <div v-if="phone" class="link_list kankyo">
              <h3 class="ttl_04">環境サービス部</h3>
              <div v-if="environmentService_array.length != 0">
                <div class="staff_wrap">
                  <div class="staff_list big">
                    <div
                      v-for="(items, line_index) in environmentService_array"
                      :key="line_index"
                    >
                      <div class="staff_line">
                        <div
                          class="staff_item"
                          :data-wrapper="line_index + 'environmentService'"
                          :data-modal="'modal' + item_index"
                          v-for="(item, item_index) in items"
                          :key="item_index"
                        >
                          <div
                            class="user_wrap"
                            @click="getChallageSdgsByUserId(item.id)"
                          >
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage: 'url(' + item.imgpath + ')',
                              }"
                              v-if="item.imgpath"
                            ></div>
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage:
                                  'url(' +
                                  require('@/assets/front_component/images/test/test04.png') +
                                  ')',
                              }"
                              v-else
                            ></div>
                            <div class="user_name">
                              <p class="name">
                                {{ item.last_name }} {{ item.first_name }}
                              </p>
                              <br />
                              <span
                                class="director"
                                v-if="item.group && item.group.group_role"
                                >{{ item.group.group_role }}</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        :id="
                          'function_wrapper_' +
                          line_index +
                          'environmentService'
                        "
                        class="
                          functions_detail_wrapper
                          approvel-sdgs-challage-wrapper
                        "
                      >
                        <div
                          class="functions_detail"
                          :class="'modal' + item_index"
                          v-for="(item, item_index) in items"
                          :key="item_index"
                        >
                          <div class="left_area">
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage: 'url(' + item.imgpath + ')',
                              }"
                              v-if="item.imgpath"
                            ></div>
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage:
                                  'url(' +
                                  require('@/assets/front_component/images/test/test04.png') +
                                  ')',
                              }"
                              v-else
                            ></div>
                          </div>
                          <div class="right_area">
                            <div class="txt_wrap">
                              <div class="group">環境サービス部</div>
                              <div class="name">
                                {{ item.last_name }} {{ item.first_name }}
                              </div>
                              <p
                                class="txt"
                                v-html="htmlText(item.introduction_com)"
                              ></p>
                            </div>
                          </div>
                          <div
                            v-if="sdgsChallengeApprovedLoader == true"
                            class="p_t50 p_b50"
                          >
                            <div class="half-circle-spinner">
                              <div class="circle circle-1"></div>
                              <div class="circle circle-2"></div>
                            </div>
                          </div>
                          <template v-if="sdgsChallgeApproved.length > 0">
                            <hr />
                            <div
                              class="
                                sdgs-challage-approvel sdgs-challage-approvel-sp
                              "
                            >
                              <div class="thisterm_wrap">
                                <div class="thisterm_head">
                                  <h3 class="thisterm_ttl challenge">
                                    チャレンジシート
                                  </h3>
                                </div>
                                <ol class="challge-ul challge-ul-sp">
                                  <template
                                    v-for="sdgsChallge in sdgsChallgeApproved"
                                  >
                                    <li
                                      :key="sdgsChallge.id"
                                      v-if="sdgsChallge.type == 'challenge'"
                                    >
                                      <div v-html="sdgsChallge.content"></div>
                                    </li>
                                  </template>
                                </ol>
                              </div>
                            </div>
                            <hr />
                            <div
                              class="
                                sdgs-challage-approvel sdgs-challage-approvel-sp
                              "
                            >
                              <div class="thisterm_wrap">
                                <div class="thisterm_head">
                                  <h3 class="thisterm_ttl sdgs">
                                    SDGs個人目標
                                  </h3>
                                </div>
                                <ol class="challge-ul challge-ul-sp">
                                  <template
                                    v-for="sdgsChallge in sdgsChallgeApproved"
                                  >
                                    <li
                                      :key="sdgsChallge.id"
                                      v-if="sdgsChallge.type == 'sdgs'"
                                    >
                                      <div v-html="sdgsChallge.content"></div>
                                    </li>
                                  </template>
                                </ol>
                              </div>
                            </div>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-else-if="environmentService_show == false"
                class="p_t50 p_b50"
              >
                <div class="half-circle-spinner">
                  <div class="circle circle-1"></div>
                  <div class="circle circle-2"></div>
                </div>
              </div>

              <div v-if="environmentServiceUnder_show">
                <div
                  class="staff_wrap"
                  v-for="(
                    under_items, under_index
                  ) in environmentServiceUnder_array"
                  :key="under_index"
                >
                  <h4 class="ttl_05">{{ under_items.name }}</h4>
                  <div class="staff_in_wrap" v-if="under_items.users != ''">
                    <div class="staff_list small">
                      <div
                        v-for="(items, line_index) in under_items.users"
                        :key="line_index"
                      >
                        <div class="staff_line">
                          <div
                            class="staff_item"
                            :data-wrapper="
                              line_index +
                              'environmentServiceUnder' +
                              under_index
                            "
                            :data-modal="'modal' + item_index"
                            v-for="(item, item_index) in items"
                            :key="item_index"
                          >
                            <div
                              class="user_wrap"
                              @click="getChallageSdgsByUserId(item.id)"
                            >
                              <div
                                class="img_wrap"
                                :style="{
                                  backgroundImage: 'url(' + item.imgpath + ')',
                                }"
                                v-if="item.imgpath"
                              ></div>
                              <div
                                class="img_wrap"
                                :style="{
                                  backgroundImage:
                                    'url(' +
                                    require('@/assets/front_component/images/test/test04.png') +
                                    ')',
                                }"
                                v-else
                              ></div>
                              <div class="user_name">
                                <p class="name">
                                  {{ item.last_name }} {{ item.first_name }}
                                </p>
                                <br />
                                <span
                                  class="director"
                                  v-if="item.group && item.group.group_role"
                                  >{{ item.group.group_role }}</span
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          :id="
                            'function_wrapper_' +
                            line_index +
                            'environmentServiceUnder' +
                            under_index
                          "
                          class="
                            functions_detail_wrapper
                            approvel-sdgs-challage-wrapper
                          "
                        >
                          <div
                            class="functions_detail"
                            :class="'modal' + item_index"
                            v-for="(item, item_index) in items"
                            :key="item_index"
                          >
                            <div class="left_area">
                              <div
                                class="img_wrap"
                                :style="{
                                  backgroundImage: 'url(' + item.imgpath + ')',
                                }"
                                v-if="item.imgpath"
                              ></div>
                              <div
                                class="img_wrap"
                                :style="{
                                  backgroundImage:
                                    'url(' +
                                    require('@/assets/front_component/images/test/test04.png') +
                                    ')',
                                }"
                                v-else
                              ></div>
                            </div>
                            <div class="right_area">
                              <div class="txt_wrap">
                                <div class="group">
                                  環境サービス部 {{ under_items.name }}
                                </div>
                                <div class="name">
                                  {{ item.last_name }} {{ item.first_name }}
                                </div>
                                <p
                                  class="txt"
                                  v-html="htmlText(item.introduction_com)"
                                ></p>
                              </div>
                            </div>
                            <div
                              v-if="sdgsChallengeApprovedLoader == true"
                              class="p_t50 p_b50"
                            >
                              <div class="half-circle-spinner">
                                <div class="circle circle-1"></div>
                                <div class="circle circle-2"></div>
                              </div>
                            </div>
                            <template v-if="sdgsChallgeApproved.length > 0">
                              <hr />
                              <div
                                class="
                                  sdgs-challage-approvel
                                  sdgs-challage-approvel-sp
                                "
                              >
                                <div class="thisterm_wrap">
                                  <div class="thisterm_head">
                                    <h3 class="thisterm_ttl challenge">
                                      チャレンジシート
                                    </h3>
                                  </div>
                                  <ol class="challge-ul challge-ul-sp">
                                    <template
                                      v-for="sdgsChallge in sdgsChallgeApproved"
                                    >
                                      <li
                                        :key="sdgsChallge.id"
                                        v-if="sdgsChallge.type == 'challenge'"
                                      >
                                        <div v-html="sdgsChallge.content"></div>
                                      </li>
                                    </template>
                                  </ol>
                                </div>
                              </div>
                              <hr />
                              <div
                                class="
                                  sdgs-challage-approvel
                                  sdgs-challage-approvel-sp
                                "
                              >
                                <div class="thisterm_wrap">
                                  <div class="thisterm_head">
                                    <h3 class="thisterm_ttl sdgs">
                                      SDGs個人目標
                                    </h3>
                                  </div>
                                  <ol class="challge-ul challge-ul-sp">
                                    <template
                                      v-for="sdgsChallge in sdgsChallgeApproved"
                                    >
                                      <li
                                        :key="sdgsChallge.id"
                                        v-if="sdgsChallge.type == 'sdgs'"
                                      >
                                        <div v-html="sdgsChallge.content"></div>
                                      </li>
                                    </template>
                                  </ol>
                                </div>
                              </div>
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div v-else class="p_t50 p_b50">
                    <div class="half-circle-spinner">
                      <div class="circle circle-1"></div>
                      <div class="circle circle-2"></div>
                    </div>
                  </div> -->
                </div>
              </div>
              <div
                v-else-if="environmentServiceUnder_array.length != 0"
                class="p_t100 p_b100"
              >
                <div class="half-circle-spinner">
                  <div class="circle circle-1"></div>
                  <div class="circle circle-2"></div>
                </div>
              </div>
            </div>
            <div v-else class="link_list kankyo">
              <h3 class="ttl_04">環境サービス部</h3>
              <div v-if="environmentService_array.length != 0">
                <div class="staff_wrap">
                  <div class="staff_list big">
                    <div
                      v-for="(items, line_index) in environmentService_array"
                      :key="line_index"
                    >
                      <div class="staff_line">
                        <div
                          class="staff_item"
                          :data-wrapper="line_index + 'environmentService'"
                          :data-modal="'modal' + item_index"
                          v-for="(item, item_index) in items"
                          :key="item_index"
                        >
                          <div
                            class="user_wrap"
                            @click="getChallageSdgsByUserId(item.id)"
                          >
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage: 'url(' + item.imgpath + ')',
                              }"
                              v-if="item.imgpath"
                            ></div>
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage:
                                  'url(' +
                                  require('@/assets/front_component/images/test/test04.png') +
                                  ')',
                              }"
                              v-else
                            ></div>
                            <div class="user_name">
                              <p class="name">
                                {{ item.last_name }} {{ item.first_name }}
                              </p>
                              <br />
                              <span
                                class="director"
                                v-if="item.group && item.group.group_role"
                                >{{ item.group.group_role }}</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        :id="
                          'function_wrapper_' +
                          line_index +
                          'environmentService'
                        "
                        class="
                          functions_detail_wrapper
                          approvel-sdgs-challage-wrapper
                        "
                      >
                        <div
                          class="functions_detail"
                          :class="'modal' + item_index"
                          v-for="(item, item_index) in items"
                          :key="item_index"
                        >
                          <div class="left_area">
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage: 'url(' + item.imgpath + ')',
                              }"
                              v-if="item.imgpath"
                            ></div>
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage:
                                  'url(' +
                                  require('@/assets/front_component/images/test/test04.png') +
                                  ')',
                              }"
                              v-else
                            ></div>
                          </div>
                          <div class="right_area">
                            <div class="txt_wrap">
                              <div class="group">環境サービス部</div>
                              <div class="name">
                                {{ item.last_name }} {{ item.first_name }}
                              </div>
                              <p
                                class="txt"
                                v-html="htmlText(item.introduction_com)"
                              ></p>
                            </div>
                          </div>
                          <div
                            v-if="sdgsChallengeApprovedLoader == true"
                            class="p_t50 p_b50"
                          >
                            <div class="half-circle-spinner">
                              <div class="circle circle-1"></div>
                              <div class="circle circle-2"></div>
                            </div>
                          </div>
                          <template v-if="sdgsChallgeApproved.length > 0">
                            <hr />
                            <div
                              class="
                                sdgs-challage-approvel sdgs-challage-approvel-sp
                              "
                            >
                              <div class="thisterm_wrap">
                                <div class="thisterm_head">
                                  <h3 class="thisterm_ttl challenge">
                                    チャレンジシート
                                  </h3>
                                </div>
                                <ol class="challge-ul challge-ul-sp">
                                  <template
                                    v-for="sdgsChallge in sdgsChallgeApproved"
                                  >
                                    <li
                                      :key="sdgsChallge.id"
                                      v-if="sdgsChallge.type == 'challenge'"
                                    >
                                      <div v-html="sdgsChallge.content"></div>
                                    </li>
                                  </template>
                                </ol>
                              </div>
                            </div>
                            <hr />
                            <div
                              class="
                                sdgs-challage-approvel sdgs-challage-approvel-sp
                              "
                            >
                              <div class="thisterm_wrap">
                                <div class="thisterm_head">
                                  <h3 class="thisterm_ttl sdgs">
                                    SDGs個人目標
                                  </h3>
                                </div>
                                <ol class="challge-ul challge-ul-sp">
                                  <template
                                    v-for="sdgsChallge in sdgsChallgeApproved"
                                  >
                                    <li
                                      :key="sdgsChallge.id"
                                      v-if="sdgsChallge.type == 'sdgs'"
                                    >
                                      <div v-html="sdgsChallge.content"></div>
                                    </li>
                                  </template>
                                </ol>
                              </div>
                            </div>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-else-if="environmentService_show == false"
                class="p_t50 p_b50"
              >
                <div class="half-circle-spinner">
                  <div class="circle circle-1"></div>
                  <div class="circle circle-2"></div>
                </div>
              </div>

              <div v-if="environmentServiceUnder_show">
                <div
                  class="staff_wrap"
                  v-for="(
                    under_items, under_index
                  ) in environmentServiceUnder_array"
                  :key="under_index"
                >
                  <h4 class="ttl_05">{{ under_items.name }}</h4>
                  <div class="staff_in_wrap" v-if="under_items.users != ''">
                    <div class="staff_list small">
                      <div
                        v-for="(items, line_index) in under_items.users"
                        :key="line_index"
                      >
                        <div class="staff_line">
                          <div
                            class="staff_item"
                            :data-wrapper="
                              line_index +
                              'environmentServiceUnder' +
                              under_index
                            "
                            :data-modal="'modal' + item_index"
                            v-for="(item, item_index) in items"
                            :key="item_index"
                          >
                            <div
                              class="user_wrap"
                              @click="getChallageSdgsByUserId(item.id)"
                            >
                              <div
                                class="img_wrap"
                                :style="{
                                  backgroundImage: 'url(' + item.imgpath + ')',
                                }"
                                v-if="item.imgpath"
                              ></div>
                              <div
                                class="img_wrap"
                                :style="{
                                  backgroundImage:
                                    'url(' +
                                    require('@/assets/front_component/images/test/test04.png') +
                                    ')',
                                }"
                                v-else
                              ></div>
                              <div class="user_name">
                                <p class="name">
                                  {{ item.last_name }} {{ item.first_name }}
                                </p>
                                <br />
                                <span
                                  class="director"
                                  v-if="item.group && item.group.group_role"
                                  >{{ item.group.group_role }}</span
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          :id="
                            'function_wrapper_' +
                            line_index +
                            'environmentServiceUnder' +
                            under_index
                          "
                          class="
                            functions_detail_wrapper
                            approvel-sdgs-challage-wrapper
                          "
                        >
                          <div
                            class="functions_detail"
                            :class="'modal' + item_index"
                            v-for="(item, item_index) in items"
                            :key="item_index"
                          >
                            <div class="left_area">
                              <div
                                class="img_wrap"
                                :style="{
                                  backgroundImage: 'url(' + item.imgpath + ')',
                                }"
                                v-if="item.imgpath"
                              ></div>
                              <div
                                class="img_wrap"
                                :style="{
                                  backgroundImage:
                                    'url(' +
                                    require('@/assets/front_component/images/test/test04.png') +
                                    ')',
                                }"
                                v-else
                              ></div>
                            </div>
                            <div class="right_area">
                              <div class="txt_wrap">
                                <div class="group">
                                  環境サービス部 {{ under_items.name }}
                                </div>
                                <div class="name">
                                  {{ item.last_name }} {{ item.first_name }}
                                </div>
                                <p
                                  class="txt"
                                  v-html="htmlText(item.introduction_com)"
                                ></p>
                              </div>
                            </div>
                            <div
                              v-if="sdgsChallengeApprovedLoader == true"
                              class="p_t50 p_b50"
                            >
                              <div class="half-circle-spinner">
                                <div class="circle circle-1"></div>
                                <div class="circle circle-2"></div>
                              </div>
                            </div>
                            <template v-if="sdgsChallgeApproved.length > 0">
                              <hr />
                              <div
                                class="
                                  sdgs-challage-approvel
                                  sdgs-challage-approvel-sp
                                "
                              >
                                <div class="thisterm_wrap">
                                  <div class="thisterm_head">
                                    <h3 class="thisterm_ttl challenge">
                                      チャレンジシート
                                    </h3>
                                  </div>
                                  <ol class="challge-ul challge-ul-sp">
                                    <template
                                      v-for="sdgsChallge in sdgsChallgeApproved"
                                    >
                                      <li
                                        :key="sdgsChallge.id"
                                        v-if="sdgsChallge.type == 'challenge'"
                                      >
                                        <div v-html="sdgsChallge.content"></div>
                                      </li>
                                    </template>
                                  </ol>
                                </div>
                              </div>
                              <hr />
                              <div
                                class="
                                  sdgs-challage-approvel
                                  sdgs-challage-approvel-sp
                                "
                              >
                                <div class="thisterm_wrap">
                                  <div class="thisterm_head">
                                    <h3 class="thisterm_ttl sdgs">
                                      SDGs個人目標
                                    </h3>
                                  </div>
                                  <ol class="challge-ul challge-ul-sp">
                                    <template
                                      v-for="sdgsChallge in sdgsChallgeApproved"
                                    >
                                      <li
                                        :key="sdgsChallge.id"
                                        v-if="sdgsChallge.type == 'sdgs'"
                                      >
                                        <div v-html="sdgsChallge.content"></div>
                                      </li>
                                    </template>
                                  </ol>
                                </div>
                              </div>
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div v-else class="p_t50 p_b50">
                    <div class="half-circle-spinner">
                      <div class="circle circle-1"></div>
                      <div class="circle circle-2"></div>
                    </div>
                  </div> -->
                </div>
              </div>
              <div
                v-else-if="environmentServiceUnder_array.length != 0"
                class="p_t100 p_b100"
              >
                <div class="half-circle-spinner">
                  <div class="circle circle-1"></div>
                  <div class="circle circle-2"></div>
                </div>
              </div>
            </div>

            <div v-if="phone" class="link_list risaikuru">
              <h3 class="ttl_04">リサイクル部</h3>
              <div v-if="recycling_array.length != 0">
                <div class="staff_wrap">
                  <div class="staff_list big">
                    <div
                      v-for="(items, line_index) in recycling_array"
                      :key="line_index"
                    >
                      <div class="staff_line">
                        <div
                          class="staff_item"
                          :data-wrapper="line_index + 'recycling'"
                          :data-modal="'modal' + item_index"
                          v-for="(item, item_index) in items"
                          :key="item_index"
                        >
                          <div
                            class="user_wrap"
                            @click="getChallageSdgsByUserId(item.id)"
                          >
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage: 'url(' + item.imgpath + ')',
                              }"
                              v-if="item.imgpath"
                            ></div>
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage:
                                  'url(' +
                                  require('@/assets/front_component/images/test/test04.png') +
                                  ')',
                              }"
                              v-else
                            ></div>
                            <div class="user_name">
                              <p class="name">
                                {{ item.last_name }} {{ item.first_name }}
                              </p>
                              <br />
                              <span
                                class="director"
                                v-if="item.group && item.group.group_role"
                                >{{ item.group.group_role }}</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        :id="'function_wrapper_' + line_index + 'recycling'"
                        class="
                          functions_detail_wrapper
                          approvel-sdgs-challage-wrapper
                        "
                      >
                        <div
                          class="functions_detail"
                          :class="'modal' + item_index"
                          v-for="(item, item_index) in items"
                          :key="item_index"
                        >
                          <div class="left_area">
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage: 'url(' + item.imgpath + ')',
                              }"
                              v-if="item.imgpath"
                            ></div>
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage:
                                  'url(' +
                                  require('@/assets/front_component/images/test/test04.png') +
                                  ')',
                              }"
                              v-else
                            ></div>
                          </div>
                          <div class="right_area">
                            <div class="txt_wrap">
                              <div class="group">リサイクル部</div>
                              <div class="name">
                                {{ item.last_name }} {{ item.first_name }}
                              </div>
                              <p
                                class="txt"
                                v-html="htmlText(item.introduction_com)"
                              ></p>
                            </div>
                          </div>
                          <div
                            v-if="sdgsChallengeApprovedLoader == true"
                            class="p_t50 p_b50"
                          >
                            <div class="half-circle-spinner">
                              <div class="circle circle-1"></div>
                              <div class="circle circle-2"></div>
                            </div>
                          </div>
                          <template v-if="sdgsChallgeApproved.length > 0">
                            <hr />
                            <div
                              class="
                                sdgs-challage-approvel sdgs-challage-approvel-sp
                              "
                            >
                              <div class="thisterm_wrap">
                                <div class="thisterm_head">
                                  <h3 class="thisterm_ttl challenge">
                                    チャレンジシート
                                  </h3>
                                </div>
                                <ol class="challge-ul challge-ul-sp">
                                  <template
                                    v-for="sdgsChallge in sdgsChallgeApproved"
                                  >
                                    <li
                                      :key="sdgsChallge.id"
                                      v-if="sdgsChallge.type == 'challenge'"
                                    >
                                      <div v-html="sdgsChallge.content"></div>
                                    </li>
                                  </template>
                                </ol>
                              </div>
                            </div>
                            <hr />
                            <div
                              class="
                                sdgs-challage-approvel sdgs-challage-approvel-sp
                              "
                            >
                              <div class="thisterm_wrap">
                                <div class="thisterm_head">
                                  <h3 class="thisterm_ttl sdgs">
                                    SDGs個人目標
                                  </h3>
                                </div>
                                <ol class="challge-ul challge-ul-sp">
                                  <template
                                    v-for="sdgsChallge in sdgsChallgeApproved"
                                  >
                                    <li
                                      :key="sdgsChallge.id"
                                      v-if="sdgsChallge.type == 'sdgs'"
                                    >
                                      <div v-html="sdgsChallge.content"></div>
                                    </li>
                                  </template>
                                </ol>
                              </div>
                            </div>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else-if="recycling_show == false" class="p_t50 p_b50">
                <div class="half-circle-spinner">
                  <div class="circle circle-1"></div>
                  <div class="circle circle-2"></div>
                </div>
              </div>

              <div v-if="recyclingUnder_show">
                <div
                  class="staff_wrap"
                  v-for="(under_items, under_index) in recyclingUnder_array"
                  :key="under_index"
                >
                  <h4 class="ttl_05">{{ under_items.name }}</h4>
                  <div class="staff_in_wrap" v-if="under_items.users != ''">
                    <div class="staff_list small">
                      <div
                        v-for="(items, line_index) in under_items.users"
                        :key="line_index"
                      >
                        <div class="staff_line">
                          <div
                            class="staff_item"
                            :data-wrapper="
                              line_index + 'recyclingUnder' + under_index
                            "
                            :data-modal="'modal' + item_index"
                            v-for="(item, item_index) in items"
                            :key="item_index"
                          >
                            <div
                              class="user_wrap"
                              @click="getChallageSdgsByUserId(item.id)"
                            >
                              <div
                                class="img_wrap"
                                :style="{
                                  backgroundImage: 'url(' + item.imgpath + ')',
                                }"
                                v-if="item.imgpath"
                              ></div>
                              <div
                                class="img_wrap"
                                :style="{
                                  backgroundImage:
                                    'url(' +
                                    require('@/assets/front_component/images/test/test04.png') +
                                    ')',
                                }"
                                v-else
                              ></div>
                              <div class="user_name">
                                <p class="name">
                                  {{ item.last_name }} {{ item.first_name }}
                                </p>
                                <br />
                                <span
                                  class="director"
                                  v-if="item.group && item.group.group_role"
                                  >{{ item.group.group_role }}</span
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          :id="
                            'function_wrapper_' +
                            line_index +
                            'recyclingUnder' +
                            under_index
                          "
                          class="
                            functions_detail_wrapper
                            approvel-sdgs-challage-wrapper
                          "
                        >
                          <div
                            class="functions_detail"
                            :class="'modal' + item_index"
                            v-for="(item, item_index) in items"
                            :key="item_index"
                          >
                            <div class="left_area">
                              <div
                                class="img_wrap"
                                :style="{
                                  backgroundImage: 'url(' + item.imgpath + ')',
                                }"
                                v-if="item.imgpath"
                              ></div>
                              <div
                                class="img_wrap"
                                :style="{
                                  backgroundImage:
                                    'url(' +
                                    require('@/assets/front_component/images/test/test04.png') +
                                    ')',
                                }"
                                v-else
                              ></div>
                            </div>
                            <div class="right_area">
                              <div class="txt_wrap">
                                <div class="group">
                                  リサイクル部 {{ under_items.name }}
                                </div>
                                <div class="name">
                                  {{ item.last_name }} {{ item.first_name }}
                                </div>
                                <p
                                  class="txt"
                                  v-html="htmlText(item.introduction_com)"
                                ></p>
                              </div>
                            </div>
                            <div
                              v-if="sdgsChallengeApprovedLoader == true"
                              class="p_t50 p_b50"
                            >
                              <div class="half-circle-spinner">
                                <div class="circle circle-1"></div>
                                <div class="circle circle-2"></div>
                              </div>
                            </div>
                            <template v-if="sdgsChallgeApproved.length > 0">
                              <hr />
                              <div
                                class="
                                  sdgs-challage-approvel
                                  sdgs-challage-approvel-sp
                                "
                              >
                                <div class="thisterm_wrap">
                                  <div class="thisterm_head">
                                    <h3 class="thisterm_ttl challenge">
                                      チャレンジシート
                                    </h3>
                                  </div>
                                  <ol class="challge-ul challge-ul-sp">
                                    <template
                                      v-for="sdgsChallge in sdgsChallgeApproved"
                                    >
                                      <li
                                        :key="sdgsChallge.id"
                                        v-if="sdgsChallge.type == 'challenge'"
                                      >
                                        <div v-html="sdgsChallge.content"></div>
                                      </li>
                                    </template>
                                  </ol>
                                </div>
                              </div>
                              <hr />
                              <div
                                class="
                                  sdgs-challage-approvel
                                  sdgs-challage-approvel-sp
                                "
                              >
                                <div class="thisterm_wrap">
                                  <div class="thisterm_head">
                                    <h3 class="thisterm_ttl sdgs">
                                      SDGs個人目標
                                    </h3>
                                  </div>
                                  <ol class="challge-ul challge-ul-sp">
                                    <template
                                      v-for="sdgsChallge in sdgsChallgeApproved"
                                    >
                                      <li
                                        :key="sdgsChallge.id"
                                        v-if="sdgsChallge.type == 'sdgs'"
                                      >
                                        <div v-html="sdgsChallge.content"></div>
                                      </li>
                                    </template>
                                  </ol>
                                </div>
                              </div>
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div v-else class="p_t50 p_b50">
                    <div class="half-circle-spinner">
                      <div class="circle circle-1"></div>
                      <div class="circle circle-2"></div>
                    </div>
                  </div> -->
                </div>
              </div>
              <div
                v-else-if="recyclingUnder_array.length != 0"
                class="p_t100 p_b100"
              >
                <div class="half-circle-spinner">
                  <div class="circle circle-1"></div>
                  <div class="circle circle-2"></div>
                </div>
              </div>
            </div>
            <div v-else class="link_list risaikuru">
              <h3 class="ttl_04">リサイクル部</h3>
              <div v-if="recycling_array.length != 0">
                <div class="staff_wrap">
                  <div class="staff_list big">
                    <div
                      v-for="(items, line_index) in recycling_array"
                      :key="line_index"
                    >
                      <div class="staff_line">
                        <div
                          class="staff_item"
                          :data-wrapper="line_index + 'recycling'"
                          :data-modal="'modal' + item_index"
                          v-for="(item, item_index) in items"
                          :key="item_index"
                        >
                          <div
                            class="user_wrap"
                            @click="getChallageSdgsByUserId(item.id)"
                          >
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage: 'url(' + item.imgpath + ')',
                              }"
                              v-if="item.imgpath"
                            ></div>
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage:
                                  'url(' +
                                  require('@/assets/front_component/images/test/test04.png') +
                                  ')',
                              }"
                              v-else
                            ></div>
                            <div class="user_name">
                              <p class="name">
                                {{ item.last_name }} {{ item.first_name }}
                              </p>
                              <br />
                              <span
                                class="director"
                                v-if="item.group && item.group.group_role"
                                >{{ item.group.group_role }}</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        :id="'function_wrapper_' + line_index + 'recycling'"
                        class="
                          functions_detail_wrapper
                          approvel-sdgs-challage-wrapper
                        "
                      >
                        <div
                          class="functions_detail"
                          :class="'modal' + item_index"
                          v-for="(item, item_index) in items"
                          :key="item_index"
                        >
                          <div class="left_area">
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage: 'url(' + item.imgpath + ')',
                              }"
                              v-if="item.imgpath"
                            ></div>
                            <div
                              class="img_wrap"
                              :style="{
                                backgroundImage:
                                  'url(' +
                                  require('@/assets/front_component/images/test/test04.png') +
                                  ')',
                              }"
                              v-else
                            ></div>
                          </div>
                          <div class="right_area">
                            <div class="txt_wrap">
                              <div class="group">リサイクル部</div>
                              <div class="name">
                                {{ item.last_name }} {{ item.first_name }}
                              </div>
                              <p
                                class="txt"
                                v-html="htmlText(item.introduction_com)"
                              ></p>
                            </div>
                          </div>
                          <div
                            v-if="sdgsChallengeApprovedLoader == true"
                            class="p_t50 p_b50"
                          >
                            <div class="half-circle-spinner">
                              <div class="circle circle-1"></div>
                              <div class="circle circle-2"></div>
                            </div>
                          </div>
                          <template v-if="sdgsChallgeApproved.length > 0">
                            <hr />
                            <div
                              class="
                                sdgs-challage-approvel sdgs-challage-approvel-sp
                              "
                            >
                              <div class="thisterm_wrap">
                                <div class="thisterm_head">
                                  <h3 class="thisterm_ttl challenge">
                                    チャレンジシート
                                  </h3>
                                </div>
                                <ol class="challge-ul challge-ul-sp">
                                  <template
                                    v-for="sdgsChallge in sdgsChallgeApproved"
                                  >
                                    <li
                                      :key="sdgsChallge.id"
                                      v-if="sdgsChallge.type == 'challenge'"
                                    >
                                      <div v-html="sdgsChallge.content"></div>
                                    </li>
                                  </template>
                                </ol>
                              </div>
                            </div>
                            <hr />
                            <div
                              class="
                                sdgs-challage-approvel sdgs-challage-approvel-sp
                              "
                            >
                              <div class="thisterm_wrap">
                                <div class="thisterm_head">
                                  <h3 class="thisterm_ttl sdgs">
                                    SDGs個人目標
                                  </h3>
                                </div>
                                <ol class="challge-ul challge-ul-sp">
                                  <template
                                    v-for="sdgsChallge in sdgsChallgeApproved"
                                  >
                                    <li
                                      :key="sdgsChallge.id"
                                      v-if="sdgsChallge.type == 'sdgs'"
                                    >
                                      <div v-html="sdgsChallge.content"></div>
                                    </li>
                                  </template>
                                </ol>
                              </div>
                            </div>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else-if="recycling_show == false" class="p_t50 p_b50">
                <div class="half-circle-spinner">
                  <div class="circle circle-1"></div>
                  <div class="circle circle-2"></div>
                </div>
              </div>

              <div v-if="recyclingUnder_show">
                <div
                  class="staff_wrap"
                  v-for="(under_items, under_index) in recyclingUnder_array"
                  :key="under_index"
                >
                  <h4 class="ttl_05">{{ under_items.name }}</h4>
                  <div class="staff_in_wrap" v-if="under_items.users != ''">
                    <div class="staff_list small">
                      <div
                        v-for="(items, line_index) in under_items.users"
                        :key="line_index"
                      >
                        <div class="staff_line">
                          <div
                            class="staff_item"
                            :data-wrapper="
                              line_index + 'recyclingUnder' + under_index
                            "
                            :data-modal="'modal' + item_index"
                            v-for="(item, item_index) in items"
                            :key="item_index"
                          >
                            <div
                              class="user_wrap"
                              @click="getChallageSdgsByUserId(item.id)"
                            >
                              <div
                                class="img_wrap"
                                :style="{
                                  backgroundImage: 'url(' + item.imgpath + ')',
                                }"
                                v-if="item.imgpath"
                              ></div>
                              <div
                                class="img_wrap"
                                :style="{
                                  backgroundImage:
                                    'url(' +
                                    require('@/assets/front_component/images/test/test04.png') +
                                    ')',
                                }"
                                v-else
                              ></div>
                              <div class="user_name">
                                <p class="name">
                                  {{ item.last_name }} {{ item.first_name }}
                                </p>
                                <br />
                                <span
                                  class="director"
                                  v-if="item.group && item.group.group_role"
                                  >{{ item.group.group_role }}</span
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          :id="
                            'function_wrapper_' +
                            line_index +
                            'recyclingUnder' +
                            under_index
                          "
                          class="
                            functions_detail_wrapper
                            approvel-sdgs-challage-wrapper
                          "
                        >
                          <div
                            class="functions_detail"
                            :class="'modal' + item_index"
                            v-for="(item, item_index) in items"
                            :key="item_index"
                          >
                            <div class="left_area">
                              <div
                                class="img_wrap"
                                :style="{
                                  backgroundImage: 'url(' + item.imgpath + ')',
                                }"
                                v-if="item.imgpath"
                              ></div>
                              <div
                                class="img_wrap"
                                :style="{
                                  backgroundImage:
                                    'url(' +
                                    require('@/assets/front_component/images/test/test04.png') +
                                    ')',
                                }"
                                v-else
                              ></div>
                            </div>
                            <div class="right_area">
                              <div class="txt_wrap">
                                <div class="group">
                                  リサイクル部 {{ under_items.name }}
                                </div>
                                <div class="name">
                                  {{ item.last_name }} {{ item.first_name }}
                                </div>
                                <p
                                  class="txt"
                                  v-html="htmlText(item.introduction_com)"
                                ></p>
                              </div>
                            </div>
                            <div
                              v-if="sdgsChallengeApprovedLoader == true"
                              class="p_t50 p_b50"
                            >
                              <div class="half-circle-spinner">
                                <div class="circle circle-1"></div>
                                <div class="circle circle-2"></div>
                              </div>
                            </div>
                            <template v-if="sdgsChallgeApproved.length > 0">
                              <hr />
                              <div
                                class="
                                  sdgs-challage-approvel
                                  sdgs-challage-approvel-sp
                                "
                              >
                                <div class="thisterm_wrap">
                                  <div class="thisterm_head">
                                    <h3 class="thisterm_ttl challenge">
                                      チャレンジシート
                                    </h3>
                                  </div>
                                  <ol class="challge-ul challge-ul-sp">
                                    <template
                                      v-for="sdgsChallge in sdgsChallgeApproved"
                                    >
                                      <li
                                        :key="sdgsChallge.id"
                                        v-if="sdgsChallge.type == 'challenge'"
                                      >
                                        <div v-html="sdgsChallge.content"></div>
                                      </li>
                                    </template>
                                  </ol>
                                </div>
                              </div>
                              <hr />
                              <div
                                class="
                                  sdgs-challage-approvel
                                  sdgs-challage-approvel-sp
                                "
                              >
                                <div class="thisterm_wrap">
                                  <div class="thisterm_head">
                                    <h3 class="thisterm_ttl sdgs">
                                      SDGs個人目標
                                    </h3>
                                  </div>
                                  <ol class="challge-ul challge-ul-sp">
                                    <template
                                      v-for="sdgsChallge in sdgsChallgeApproved"
                                    >
                                      <li
                                        :key="sdgsChallge.id"
                                        v-if="sdgsChallge.type == 'sdgs'"
                                      >
                                        <div v-html="sdgsChallge.content"></div>
                                      </li>
                                    </template>
                                  </ol>
                                </div>
                              </div>
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div v-else class="p_t50 p_b50">
                    <div class="half-circle-spinner">
                      <div class="circle circle-1"></div>
                      <div class="circle circle-2"></div>
                    </div>
                  </div> -->
                </div>
              </div>
              <div
                v-else-if="recyclingUnder_array.length != 0"
                class="p_t100 p_b100"
              >
                <div class="half-circle-spinner">
                  <div class="circle circle-1"></div>
                  <div class="circle circle-2"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="kusa_outline">
        <div class="kusa_area">
          <div class="deco_wrap01">
            <img
              src="@/assets/front_component/images/assets/kusa01.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap02">
            <img
              src="@/assets/front_component/images/assets/kusa02.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap03">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap04">
            <img
              src="@/assets/front_component/images/assets/kusa03.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap05">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap06">
            <img
              src="@/assets/front_component/images/assets/kusa03.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap07">
            <img
              src="@/assets/front_component/images/assets/kusa05.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap08">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap09">
            <img
              src="@/assets/front_component/images/assets/kusa01.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap10">
            <img
              src="@/assets/front_component/images/assets/kusa07.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap11">
            <img
              src="@/assets/front_component/images/assets/kusa06.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap12">
            <img
              src="@/assets/front_component/images/assets/kusa01.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap13">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap14">
            <img
              src="@/assets/front_component/images/assets/kusa03.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap15">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap16">
            <img
              src="@/assets/front_component/images/assets/kusa03.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap17">
            <img
              src="@/assets/front_component/images/assets/kusa01.png"
              alt="草"
            />
          </div>
        </div>
      </div>
      <div class="deco_outline">
        <div class="deco_area">
          <div class="deco_wrap01">
            <img
              src="@/assets/front_component/images/assets/kikyu01.png"
              alt="気球"
            />
          </div>
          <div class="deco_wrap02">
            <img
              src="@/assets/front_component/images/assets/hana01.png"
              alt="花"
              class="hana01"
            />
            <img
              src="@/assets/front_component/images/assets/hana02.png"
              alt="花"
              class="hana02"
            />
            <img
              src="@/assets/front_component/images/assets/hana03.png"
              alt="花"
              class="hana03"
            />
          </div>
          <div class="deco_wrap03">
            <img
              src="@/assets/front_component/images/assets/hana01.png"
              alt="花"
              class="hana01"
            />
            <img
              src="@/assets/front_component/images/assets/hana02.png"
              alt="花"
              class="hana02"
            />
            <img
              src="@/assets/front_component/images/assets/hana03.png"
              alt="花"
              class="hana03"
            />
          </div>
          <div class="deco_wrap04">
            <img
              src="@/assets/front_component/images/assets/heri01.png"
              alt="ヘリコプター"
            />
          </div>
          <div class="deco_wrap05">
            <img
              src="@/assets/front_component/images/assets/kikyu02.png"
              alt="気球"
            />
          </div>
          <div class="deco_wrap06">
            <img
              src="@/assets/front_component/images/assets/hana01.png"
              alt="花"
              class="hana01"
            />
            <img
              src="@/assets/front_component/images/assets/hana02.png"
              alt="花"
              class="hana02"
            />
            <img
              src="@/assets/front_component/images/assets/hana03.png"
              alt="花"
              class="hana03"
            />
          </div>
          <div class="deco_wrap07">
            <img
              src="@/assets/front_component/images/assets/hana01.png"
              alt="花"
              class="hana01"
            />
            <img
              src="@/assets/front_component/images/assets/hana02.png"
              alt="花"
              class="hana02"
            />
            <img
              src="@/assets/front_component/images/assets/hana03.png"
              alt="花"
              class="hana03"
            />
          </div>
          <div class="deco_wrap08">
            <img
              src="@/assets/front_component/images/assets/kikyu03.png"
              alt="気球"
            />
          </div>
        </div>
      </div>
    </div>
  </main>
  <div class="menu_wrap">
    <div class="menu_icon">
      <img
        src="@/assets/front_component/images/assets/menu01.png"
        alt="メニュー"
      />
    </div>
    <div class="menu_list_wrap">
      <div class="menu_ttl">作成する</div>
      <ul class="menu_list">
        <li>
          <router-link
            :to="{
              name: 'Control messagecreate',
              params: { type: 'mysdgs' },
            }"
            >SDGsレポート</router-link
          >
        </li>
        <!-- <li>
          <router-link
            :to="{ name: 'Control messagecreate', params: { type: 'news' } }"
            >お知らせ</router-link
          >
        </li> -->
        <li>
          <router-link
            :to="{ name: 'Control messagecreate', params: { type: 'thanks' } }"
            >サンクス&ホメロンカード</router-link
          >
        </li>
        <li>
          <router-link
            :to="{ name: 'Control messagecreate', params: { type: 'idea' } }"
            >創発カード</router-link
          >
        </li>
        <li>
          <router-link
            :to="{ name: 'Control messagecreate', params: { type: 'daily' } }"
            >気づき日報</router-link
          >
        </li>
        <li>
          <router-link
            :to="{
              name: 'Control messagecreate',
              params: { type: 'photolog' },
            }"
            >フォトログ</router-link
          >
        </li>
        <li>
          <router-link
            :to="{ name: 'Control messagecreate', params: { type: 'column' } }"
            >コラム</router-link
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Api from "@/apis/Api";
import message from "@/apis/Message";
import dayjs from "dayjs";
import MessageType from "@/const/MessageType.json";
import User from "@/apis/User";
import Groups from "@/apis/Groups";
import $ from "jquery";
import "@/assets/front_component/js/functions.js";
import CONST from "@/const/staticProductionData.js";
import striptags from "striptags";
export default {
  name: "message",
  data() {
    return {
      introduction: [],
      users: [],
      ausers: [],
      images: [],
      loader: false,

      ceo_array: [],
      chairman_array: [],
      ceo_show: false,
      chairman_show: false,

      officer_id: "",
      officer_array: [],
      new_officer_array: [],
      officerUnder_array: [],
      officer_show: false,
      officerUnder_show: false,

      internalControl_id: "",
      internalControl_array: [],
      internalControlUnder_array: [],
      internalControl_show: false,
      internalControlUnder_show: false,

      cs_id: "",
      cs_array: [],
      csUnder_array: [],
      cs_show: false,
      csUnder_show: false,

      control_id: "",
      control_array: [],
      controlUnder_array: [],
      control_show: false,
      controlUnder_show: false,

      areaService_id: "",
      areaService_array: [],
      areaServiceUnder_array: [],
      areaService_show: false,
      areaServiceUnder_show: false,

      environmentService_id: "",
      environmentService_array: [],
      environmentServiceUnder_array: [],
      environmentService_show: false,
      environmentServiceUnder_show: false,

      recycling_id: "",
      recycling_array: [],
      recyclingUnder_array: [],
      recycling_show: false,
      recyclingUnder_show: false,

      phone: "",
      csGroupStaffs: [],
      staticProductionData: [],
      staticProductionUser: CONST.staticUser,
      sdgsChallgeApproved: [],
      sdgsChallengeApprovedLoader: false,

      staticId: {
        csGroup: { 
          id: null,
          targetId: null
        },
        areaGroup: {
          id: null,
          targetId: null
        }
      }
    };
  },
  components: {
  },
  watch: {
    csGroupStaffs(users) {
      this.csGroupStaffs = users;
    },
    officer_array(users) {
      let userPush = [];
      users = users.forEach((user) => {
        return user.filter((data) => {
          if (data.user_code === this.staticProductionUser.user_code) {
            this.staticProductionData = [[data]];
          } else {
            userPush.push(data);
          }
        });
      });
      this.new_officer_array = this.grouped(userPush);
    },
  },
  beforeCreate() {
    $(function () {
      // body に open クラスをつけたりはずしたりする( open クラスは空)
      $(".g_header_list").removeClass("open");
      $("#button").removeClass("active");
      $(document.body).removeClass("open");
      var result = $("body").attr("style");
      var int_data = result.replace(/[^0-9]/g, "");
      $("html,body").css({
        overflow: "auto",
        height: "auto",
      });
      //bodyfixedを解除する
      $("body").css({
        position: "",
        width: "",
        top: "",
      });
      $(window).scrollTop(int_data);
      $(".g_header_list  > li.has_child").removeClass("open");
      $(".g_header_list  > li.has_child").find(".menu_outwrap").slideUp();
    });
    $(function () {
      $("#js-loader").show();
      $(window).scrollTop(0);
    });
  },
  created() {
    this.phoneMatch();
    this.introductionList();
    this.groupList();
    this.getStaticId();  
  },
  methods: {
    phoneMatch: function () {
      var ua = navigator.userAgent;
      if (
        ua.indexOf("iPhone") > 0 ||
        (ua.indexOf("Android") > 0 && ua.indexOf("Mobile") > 0)
      ) {
        this.phone = true;
      } else {
        this.phone = false;
      }
    },
    groupList() {
      Groups.getAllList()
        .then((response) => {
          if (response != null) {
            response.data.data.forEach((ob) => {
              //直下
              if (ob.name == "経営企画室" && this.officer_id == "") {
                this.officer_id = ob.id;
                this.officerList(this.officer_id);
              } else if (
                ob.name == "内部統括部" &&
                this.internalControl_id == ""
              ) {
                this.internalControl_id = ob.id;
              } else if (ob.name == "CS営業部" && this.cs_id == "") {
                this.cs_id = ob.id;
              } else if (ob.name == "コントロール部" && this.control_id == "") {
                this.control_id = ob.id;
              } else if (
                ob.name == "エリアサービス部" &&
                this.areaService_id == ""
              ) {
                this.areaService_id = ob.id;
              } else if (
                ob.name == "環境サービス部" &&
                this.environmentService_id == ""
              ) {
                this.environmentService_id = ob.id;
              } else if (ob.name == "リサイクル部" && this.recycling_id == "") {
                this.recycling_id = ob.id;
              }

              //属する課
              if (ob.id == this.officer_id) {
                this.officerUnder_array = ob.groups;
              } else if (ob.id == this.internalControl_id) {
                this.internalControlUnder_array = ob.groups;
              } else if (ob.id == this.cs_id) {
                this.csUnder_array = ob.groups;
              } else if (ob.id == this.control_id) {
                this.controlUnder_array = ob.groups;
              } else if (ob.id == this.areaService_id) {
                this.areaServiceUnder_array = ob.groups;
              } else if (ob.id == this.environmentService_id) {
                this.environmentServiceUnder_array = ob.groups;
              } else if (ob.id == this.recycling_id) {
                this.recyclingUnder_array = ob.groups;
              }
            });
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    introductionList() {
      //紹介文の挿入
      message
        .list("user_introduction")
        .then(
          (response) => {
            if (response != null) {
              this.introduction = response.data.data;
            }
          },
          (error) => {
            console.log(error);

            this.$router.push({ name: "Front Error" });
          }
        )
        .catch((error) => {
          console.log(error);
          this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => {
          this.loader = false;
        });
    },
    officerList(id) {
      if (this.officer_show == false) {
        this.loader = true;

        User.get_group_byId(id)
          .then((response) => {
            if (response != null) {
              var length = response.data.data.length;
              var array = [];
              var ceoArray = [];
              var ChairmanArray = [];

              if (length == 0) {
                this.officer_show = true;
              }

              response.data.data.forEach((ob, i) => {
                User.show(ob.id)
                  .then(
                    (response) => {
                      if (response != null) {
                        var userData = response.data.data;
                        this.introduction.forEach((intro_ob) => {
                          if (ob.id == intro_ob.from_id) {
                            userData.introduction_com = intro_ob.content;
                          }
                        });

                        // if (
                        //   userData.id === "95024531-21cf-4866-a57f-8abd145b7b3b"
                        // ) {
                        //   this.staticProductionData = [[userData]];
                        //   this.userProfirleGetByUerId();
                        // }
                        //プロフィール画像の挿入
                        User.showfile(ob.id, "key", "users")
                          .then(
                            (response) => {
                              if (response != "") {
                                this.images = response.data.data;
                                if (this.images.length > 0) {
                                  let path = this.images[0].storage_file_name;
                                  let fileApiPass = "/api/v1/file/" + path;

                                  Api()
                                    .get(fileApiPass, {
                                      responseType: "arraybuffer",
                                    })
                                    .then(
                                      (response) => {
                                        let blob = new Blob([response.data], {
                                          type: response.headers[
                                            "content-type"
                                          ],
                                        });
                                        let url =
                                          window.URL || window.webkitURL;
                                        let src = url.createObjectURL(blob);

                                        userData.imgpath = src;
                                        //配列にプッシュ
                                        if (
                                          userData.group.group_role ==
                                          "代表取締役社長"
                                        ) {
                                          ceoArray.push(userData);
                                        }else if (
                                          userData.group.group_role ==
                                          "会長"
                                        ) {
                                          ChairmanArray.push(userData);
                                        } else {
                                          array.push(userData);
                                        }

                                        array = array.filter((item) => {
                                          if (item.group) {
                                            if (item.group.group_rank == null) {
                                              item.group.group_rank = 0;
                                            }
                                          }
                                          return item;
                                        });

                                        ceoArray = ceoArray.filter((item) => {
                                          if (item.group) {
                                            if (item.group.group_rank == null) {
                                              item.group.group_rank = 0;
                                            }
                                          }
                                          return item;
                                        });

                                         ChairmanArray = ChairmanArray.filter((item) => {
                                          if (item.group) {
                                            if (item.group.group_rank == null) {
                                              item.group.group_rank = 0;
                                            }
                                          }
                                          return item;
                                        });

                                        //最後のforEachで階層化
                                        if (
                                          array.length + ceoArray.length + ChairmanArray.length ==
                                          length
                                        ) {
                                          let ceoResult = ceoArray.sort(
                                            function (a, b) {
                                              return a.last_name_kana >
                                                b.last_name_kana
                                                ? 1
                                                : -1;
                                            }
                                          );

                                          // ceoResult = ceoArray.sort(function (a, b) {
                                          //   if (a.group.group_rank < b.group.group_rank)
                                          //     return 1;
                                          //   if (a.group.group_rank > b.group.group_rank)
                                          //     return -1;
                                          //   return 0;
                                          // });

                                          ceoResult = ceoArray.sort(function (
                                            a,
                                            b
                                          ) {
                                            return (
                                              b.group.group_rank -
                                              a.group.group_rank
                                            );
                                          });

                                          array.sort(function (a, b) {
                                            return (
                                              b.group.group_rank -
                                              a.group.group_rank
                                            );
                                          });

                                          this.officer_array =
                                            this.grouped(array);
                                          this.officer_show = true;
                                          this.ceo_array =this.grouped(ceoArray);
                                          this.chairman_array =this.grouped(ChairmanArray);
                                          this.ceo_show = true;
                                          this.chairman_show = true;
                                        }
                                      },
                                      (error) => {
                                        console.log(error);
                                      }
                                    );
                                } else {
                                  userData.imgpath = null;
                                  //配列にプッシュ
                                  if (
                                    userData.group.group_role ==
                                    "代表取締役社長"
                                  ) {
                                    ceoArray.push(userData);
                                  }else if (
                                          userData.group.group_role ==
                                          "会長"
                                        ) {
                                          ChairmanArray.push(userData);
                                        }  else {
                                    array.push(userData);
                                  }

                                  array = array.filter((item) => {
                                    if (item.group) {
                                      if (item.group.group_rank == null) {
                                        item.group.group_rank = 0;
                                      }
                                    }
                                    return item;
                                  });

                                  ceoArray = ceoArray.filter((item) => {
                                    if (item.group) {
                                      if (item.group.group_rank == null) {
                                        item.group.group_rank = 0;
                                      }
                                    }
                                    return item;
                                  });

                                  //最後のforEachで階層化
                                  // if (array.length + ceoArray.length == length) {
                                  let ceoResult = ceoArray.sort(function (
                                    a,
                                    b
                                  ) {
                                    return a.last_name_kana > b.last_name_kana
                                      ? 1
                                      : -1;
                                  });

                                  ceoResult = ceoArray.sort(function (a, b) {
                                    return (
                                      b.group.group_rank - a.group.group_rank
                                    );
                                  });
                                  let result = array.sort(function (a, b) {
                                    return a.last_name_kana > b.last_name_kana
                                      ? 1
                                      : -1;
                                  });

                                  array.sort(function (a, b) {
                                    return (
                                      b.group.group_rank - a.group.group_rank
                                    );
                                  });

                                  this.officer_array = this.grouped(array);

                                  this.officer_show = true;
                                  this.ceo_array = this.grouped(ceoArray);
                                  this.chairman_array = this.grouped(ChairmanArray);

                                  this.chairman_show = true;
                                  this.ceo_show = true;

                                  // }
                                }
                              }
                            },
                            (error) => {
                              this.officer_show = true;
                              this.ceo_show = true;
                              this.chairman_show = true;
                              console.log(error);
                            }
                          )
                          .catch(() => {})
                          .finally(() => {});
                      }
                    },
                    (error) => {
                      this.officer_show = true;
                      console.log(error);
                    }
                  )
                  .catch((error) => {
                    console.log(error);
                  })
                  .finally(() => {
                    this.loader = false;
                  });
              });
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.loader = false;
          });

        if (this.officerUnder_array == "") {
          this.officerUnder_show = true;
        }

        var underlength = this.officerUnder_array.length - 1;
        this.officerUnder_array.forEach((under_ob, under_i) => {
          User.get_group_byId(under_ob.id)
            .then((response) => {
              if (response != null) {
                var length = response.data.length;
                var array = [];

                if (length == 0) {
                  this.officerUnder_array[under_i].users = [];
                }

                if (underlength == under_i) {
                  if (length == 0) {
                    this.officerUnder_show = true;
                  }
                }

                response.data.data.group_users.forEach((ob, i) => {
                  User.show(ob.id)
                    .then(
                      (response) => {
                        if (response != null) {
                          var userData = response.data.data;
                          this.introduction.forEach((intro_ob) => {
                            if (ob.id == intro_ob.from_id) {
                              userData.introduction_com = intro_ob.content;
                            }
                          });

                          //プロフィール画像の挿入
                          User.showfile(ob.id, "key", "users")
                            .then(
                              (response) => {
                                if (response != "") {
                                  this.images = response.data.data;
                                  if (this.images.length > 0) {
                                    let path = this.images[0].storage_file_name;
                                    let fileApiPass = "/api/v1/file/" + path;

                                    Api()
                                      .get(fileApiPass, {
                                        responseType: "arraybuffer",
                                      })
                                      .then(
                                        (response) => {
                                          let blob = new Blob([response.data], {
                                            type: response.headers[
                                              "content-type"
                                            ],
                                          });
                                          let url =
                                            window.URL || window.webkitURL;
                                          let src = url.createObjectURL(blob);

                                          userData.imgpath = src;
                                          //配列にプッシュ
                                          array.push(userData);

                                          array = array.filter((item) => {
                                            if (item.group) {
                                              if (
                                                item.group.group_rank == null
                                              ) {
                                                item.group.group_rank = 0;
                                              }
                                            }
                                            return item;
                                          });

                                          //最後のforEachで階層化
                                          // if (array.length == length) {
                                          let result = array.sort(function (
                                            a,
                                            b
                                          ) {
                                            return a.last_name_kana >
                                              b.last_name_kana
                                              ? 1
                                              : -1;
                                          });

                                          array.sort(function (a, b) {
                                            return (
                                              b.group.group_rank -
                                              a.group.group_rank
                                            );
                                          });

                                          this.officerUnder_array[
                                            under_i
                                          ].users = this.groupedUnder(array);

                                          this.officerUnder_show = true;
                                          if (underlength == under_i) {
                                            this.officerUnder_show = true;
                                          }
                                          // }
                                        },
                                        (error) => {
                                          console.log(error);
                                        }
                                      );
                                  } else {
                                    userData.imgpath = null;
                                    //配列にプッシュ
                                    array.push(userData);

                                    array = array.filter((item) => {
                                      if (item.group) {
                                        if (item.group.group_rank == null) {
                                          item.group.group_rank = 0;
                                        }
                                      }
                                      return item;
                                    });

                                    //最後のforEachで階層化
                                    // if (array.length == length) {
                                    let result = array.sort(function (a, b) {
                                      return a.last_name_kana > b.last_name_kana
                                        ? 1
                                        : -1;
                                    });

                                    array.sort(function (a, b) {
                                      return (
                                        b.group.group_rank - a.group.group_rank
                                      );
                                    });

                                    this.officerUnder_array[under_i].users =
                                      this.groupedUnder(array);

                                    this.officerUnder_show = true;
                                    if (underlength == under_i) {
                                      this.officerUnder_show = true;
                                    }
                                    // }
                                  }
                                }
                              },
                              (error) => {
                                userData.imgpath = null;
                                //配列にプッシュ
                                array.push(userData);

                                array = array.filter((item) => {
                                  if (item.group) {
                                    if (item.group.group_rank == null) {
                                      item.group.group_rank = 0;
                                    }
                                  }
                                  return item;
                                });

                                //最後のforEachで階層化
                                // if (array.length == length) {
                                let result = array.sort(function (a, b) {
                                  return a.last_name_kana > b.last_name_kana
                                    ? 1
                                    : -1;
                                });

                                array.sort(function (a, b) {
                                  return (
                                    b.group.group_rank - a.group.group_rank
                                  );
                                });

                                this.officerUnder_array[under_i].users =
                                  this.groupedUnder(array);

                                if (underlength == under_i) {
                                  this.officerUnder_show = true;
                                }
                                // }
                                console.log(error);
                              }
                            )
                            .catch(() => {})
                            .finally(() => {});
                        }
                      },
                      (error) => {
                        this.officerUnder_show = true;
                        console.log(error);
                      }
                    )
                    .catch((error) => {
                      console.log(error);
                    })
                    .finally(() => {
                      this.loader = false;
                    });
                });
              }
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              this.loader = false;
            });
        });
      }
    },
    internalList(id) {
      if (this.internalControl_show == false) {
        this.loader = true;

        User.showGroupUsers(id)
          .then((response) => {
            if (response != null) {
              var length = response.data.data.group_users.length;
              var array = [];

              if (length == 0) {
                this.internalControl_show = true;
              }

              response.data.data.group_users.forEach((ob, i) => {
                var userData = ob.user_data;
                        userData.introduction_com = "";
                        this.introduction.forEach((intro_ob) => {
                          if (ob.user_id == intro_ob.from_id) {
                            userData.introduction_com = intro_ob.content;
                          }
                        });

                        //プロフィール画像の挿入
                        User.showfile(ob.user_id, "key", "users")
                          .then(
                            (response) => {
                              if (response != "") {
                                this.images = response.data.data;
                                if (this.images.length > 0) {
                                  let path = this.images[0].storage_file_name;
                                  let fileApiPass = "/api/v1/file/" + path;
                                  Api()
                                    .get(fileApiPass, {
                                      responseType: "arraybuffer",
                                    })
                                    .then(
                                      (response) => {
                                        let blob = new Blob([response.data], {
                                          type: response.headers[
                                            "content-type"
                                          ],
                                        });
                                        let url =
                                          window.URL || window.webkitURL;
                                        let src = url.createObjectURL(blob);
                                        userData.imgpath = src;
                                        //配列にプッシュ
                                        array.push(userData);

                                        array = array.filter((item) => {
                                          if (item.group) {
                                            if (item.group.group_rank == null) {
                                              item.group.group_rank = 0;
                                            }
                                          }
                                          return item;
                                        });
                                        let result = array.sort(function (
                                          a,
                                          b
                                        ) {
                                          return a.last_name_kana >
                                            b.last_name_kana
                                            ? 1
                                            : -1;
                                        });

                                        array.sort(function (a, b) {
                                          if (a.order === 0 && b.order !== 0) {
                                            return 1;
                                          } else if (a.order !== 0 && b.order === 0) {
                                            return -1;
                                          } else {
                                            return a.order - b.order;
                                          }
                                        });

                                        result = array.sort(function (a, b) {
                                          return (
                                            b.group.group_rank -
                                            a.group.group_rank
                                          );
                                        });                       

                                        this.internalControl_array =
                                          this.grouped(array);
                                        this.internalControl_show = true;
                                      },
                                    );
                                } else {
                                  userData.imgpath = null;
                                  //配列にプッシュ
                                  array.push(userData);

                                  array = array.filter((item) => {
                                    if (item.group) {
                                      if (item.group.group_rank == null) {
                                        item.group.group_rank = 0;
                                      }
                                    }
                                    return item;
                                  });
                                  let result = array.sort(function (a, b) {
                                    return a.last_name_kana > b.last_name_kana
                                      ? 1
                                      : -1;
                                  });

                                  array.sort(function (a, b) {
                                    if (a.order === 0 && b.order !== 0) {
                                      return 1;
                                    } else if (a.order !== 0 && b.order === 0) {
                                      return -1;
                                    } else {
                                      return a.order - b.order;
                                    }
                                  });

                                  result = array.sort(function (a, b) {
                                    return (
                                      b.group.group_rank - a.group.group_rank
                                    );
                                  });

                                  this.internalControl_array =
                                    this.grouped(array);
                                  this.internalControl_show = true;
                                }
                              }
                            },
                          )
                          .catch(() => {})
                          .finally(() => {});
              });
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.loader = false;
          });

        if (this.internalControlUnder_array == "") {
          this.internalControlUnder_show = true;
        }

        var underlength = this.internalControlUnder_array.length - 1;
        this.internalControlUnder_array.forEach((under_ob, under_i) => {
          User.showGroupUsers(under_ob.id)
            .then((response) => {
              if (response != null) {
                var length = response.data.data.group_users.length;
                var array = [];

                if (length == 0) {
                  this.internalControlUnder_array[under_i].users = [];
                }

                if (underlength == under_i) {
                  if (length == 0) {
                    this.internalControlUnder_show = true;
                  }
                }

                response.data.data.group_users.forEach((ob, i) => {
                  var userData = ob.user_data;
                          userData.introduction_com = "";
                          this.introduction.forEach((intro_ob) => {
                            if (ob.user_id == intro_ob.from_id) {
                              userData.introduction_com = intro_ob.content;
                            }
                          });

                          //プロフィール画像の挿入
                          User.showfile(ob.user_id, "key", "users")
                            .then(
                              (response) => {
                                if (response != "") {
                                  this.images = response.data.data;
                                  if (this.images.length > 0) {
                                    let path = this.images[0].storage_file_name;
                                    let fileApiPass = "/api/v1/file/" + path;

                                    Api()
                                      .get(fileApiPass, {
                                        responseType: "arraybuffer",
                                      })
                                      .then(
                                        (response) => {
                                          let blob = new Blob([response.data], {
                                            type: response.headers[
                                              "content-type"
                                            ],
                                          });
                                          let url =
                                            window.URL || window.webkitURL;
                                          let src = url.createObjectURL(blob);

                                          userData.imgpath = src;
                                          //配列にプッシュ
                                          array.push(userData);

                                          array = array.filter((item) => {
                                            if (item.group) {
                                              if (
                                                item.group.group_rank == null
                                              ) {
                                                item.group.group_rank = 0;
                                              }
                                            }
                                            return item;
                                          });

                                          let result = array.sort(function (
                                            a,
                                            b
                                          ) {
                                            return a.last_name_kana >
                                              b.last_name_kana
                                              ? 1
                                              : -1;
                                          });

                                          array.sort(function (a, b) {
                                            if (a.order === 0 && b.order !== 0) {
                                              return 1;
                                            } else if (a.order !== 0 && b.order === 0) {
                                              return -1;
                                            } else {
                                              return a.order - b.order;
                                            }
                                          });

                                          result = array.sort(function (a, b) {
                                            return (
                                              b.group.group_rank -
                                              a.group.group_rank
                                            );
                                          });

                                          this.internalControlUnder_array[
                                            under_i
                                          ].users = this.groupedUnder(array);

                                          this.internalControlUnder_show = true;
                                          if (underlength == under_i) {
                                            this.internalControlUnder_show = true;
                                          }
                                        }
                                      );
                                  } else {
                                    userData.imgpath = null;
                                    //配列にプッシュ
                                    array.push(userData);
                                    array = array.filter((item) => {
                                      if (item.group) {
                                        if (item.group.group_rank == null) {
                                          item.group.group_rank = 0;
                                        }
                                      }
                                      return item;
                                    });
                                    let result = array.sort(function (a, b) {
                                      return a.last_name_kana > b.last_name_kana
                                        ? 1
                                        : -1;
                                    });

                                    array.sort(function (a, b) {
                                      if (a.order === 0 && b.order !== 0) {
                                        return 1;
                                      } else if (a.order !== 0 && b.order === 0) {
                                        return -1;
                                      } else {
                                        return a.order - b.order;
                                      }
                                    });

                                    result = array.sort(function (a, b) {
                                      return (
                                        b.group.group_rank - a.group.group_rank
                                      );
                                    });

                                    this.internalControlUnder_array[
                                      under_i
                                    ].users = this.groupedUnder(array);

                                    this.internalControlUnder_show = true;
                                    if (underlength == under_i) {
                                      this.internalControlUnder_show = true;
                                    }
                                  }
                                }
                              }
                            )
                            .catch(() => {})
                            .finally(() => {});
                });
              }
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              this.loader = false;
            });
        });
      }
    },
    csList(id) {
      if (this.cs_show == false) {
        this.loader = true;

        User.showGroupUsers(id)
          .then((response) => {
            if (response != null) {
              var length = response.data.data.group_users.length;
              var array = [];

              if (length == 0) {
                this.cs_show = true;
              }

              response.data.data.group_users.forEach((ob, i) => {
                    var userData = ob.user_data;
                    userData.introduction_com = "";

                    this.introduction.forEach((intro_ob) => {
                      if (ob.user_id == intro_ob.from_id) {
                        userData.introduction_com = intro_ob.content;
                      }
                    });

                    //プロフィール画像の挿入
                    User.showfile(ob.user_id, "key", "users")
                      .then(
                        (response) => {
                          if (response != "") {
                            this.images = response.data.data;
                            if (this.images.length > 0) {
                              let path = this.images[0].storage_file_name;
                              let fileApiPass = "/api/v1/file/" + path;

                              Api()
                                .get(fileApiPass, {
                                  responseType: "arraybuffer",
                                })
                                .then(
                                  (response) => {
                                    let blob = new Blob([response.data], {
                                      type: response.headers["content-type"],
                                    });
                                    let url = window.URL || window.webkitURL;
                                    let src = url.createObjectURL(blob);

                                    userData.imgpath = src;
                                    //配列にプッシュ
                                    array.push(userData);

                                    array = array.filter((item) => {
                                      if (item.group) {
                                        if (item.group.group_rank == null) {
                                          item.group.group_rank = 0;
                                        }
                                      }
                                      return item;
                                    });

                                    let result = array.sort(function (a, b) {
                                        return a.last_name_kana >
                                          b.last_name_kana
                                          ? 1
                                          : -1;
                                      });

                                      array.sort(function (a, b) {
                                        if (a.order === 0 && b.order !== 0) {
                                          return 1;
                                        } else if (a.order !== 0 && b.order === 0) {
                                          return -1;
                                        } else {
                                          return a.order - b.order;
                                        }
                                      });

                                      array.sort(function (a, b) {
                                        return (
                                          b.group.group_rank -
                                          a.group.group_rank
                                        );
                                      });

                                    this.cs_array = this.grouped(array);

                                    this.cs_show = true;
                                  },
                                  (error) => {
                                    console.log(error);
                                  }
                                );
                            } else {
                              userData.imgpath = null;
                              //配列にプッシュ
                              array.push(userData);

                              array = array.filter((item) => {
                                if (item.group) {
                                  if (item.group.group_rank == null) {
                                    item.group.group_rank = 0;
                                  }
                                }
                                return item;
                              });

                              let result = array.sort(function (a, b) {
                                  return a.last_name_kana > b.last_name_kana
                                    ? 1
                                    : -1;
                                });

                                array.sort(function (a, b) {
                                  if (a.order === 0 && b.order !== 0) {
                                    return 1;
                                  } else if (a.order !== 0 && b.order === 0) {
                                    return -1;
                                  } else {
                                    return a.order - b.order;
                                  }
                                });

                                array.sort(function (a, b) {
                                  return (
                                    b.group.group_rank - a.group.group_rank
                                    );
                                  });

                              this.cs_array = this.grouped(array);

                              this.cs_show = true;
                            }
                          }
                        },
                      )
                      .catch(() => {})
                      .finally(() => {});

              });
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.loader = false;
          });

        if (this.csUnder_array == "") {
          this.csUnder_show = true;
        }

        var underlength = this.csUnder_array.length - 1;

        this.csUnder_array.forEach((under_ob, under_i) => {
          User.showGroupUsers(under_ob.id)
            .then((response) => {
              if (response != null) {
                var length = response.data.data.group_users.length;
                var array = [];
                if (length == 0) {
                  under_ob.users = [];
                }

                if (underlength == under_i) {
                  if (length == 0) {
                    this.csUnder_show = true;
                  }

                }

                response.data.data.group_users.forEach((ob, i) => {
                        var userData = ob.user_data;

                        userData.introduction_com = "";

                        this.introduction.forEach((intro_ob) => {
                          if (ob.user_id == intro_ob.from_id) {
                            userData.introduction_com = intro_ob.content;
                          }
                        });

                        //プロフィール画像の挿入
                        User.showfile(ob.user_id, "key", "users")
                          .then(
                            (response) => {
                              if (response != "") {
                                this.images = response.data.data;

                                if (this.images.length > 0) {
                                  let path = this.images[0].storage_file_name;

                                  let fileApiPass = "/api/v1/file/" + path;

                                  Api()
                                    .get(fileApiPass, {
                                      responseType: "arraybuffer",
                                    })
                                    .then(
                                      (response) => {
                                        let blob = new Blob([response.data], {
                                          type: response.headers[
                                            "content-type"
                                          ],
                                        });

                                        let url =
                                          window.URL || window.webkitURL;
                                        let src = url.createObjectURL(blob);

                                        userData.imgpath = src;
                                        //配列にプッシュ
                                        array.push(userData);

                                        array = array.filter((item) => {
                                          if (item.group) {
                                            if (item.group.group_rank == null) {
                                              item.group.group_rank = 0;
                                            }
                                          }
                                          return item;
                                        });

                                        let result = array.sort(function (
                                          a,
                                          b
                                        ) {
                                          return a.last_name_kana >
                                            b.last_name_kana
                                            ? 1
                                            : -1;
                                        });

                                        array.sort(function (a, b) {
                                          if (a.order === 0 && b.order !== 0) {
                                            return 1;
                                          } else if (a.order !== 0 && b.order === 0) {
                                            return -1;
                                          } else {
                                            return a.order - b.order;
                                          }
                                        });

                                        array.sort(function (a, b) {
                                          return (
                                            b.group.group_rank -
                                            a.group.group_rank
                                          );
                                        });

                                        under_ob.users =
                                          this.groupedUnder(array);

                                        this.csUnder_show = true;
                                        if (underlength == under_i) {
                                          this.csUnder_show = true;
                                        }
                                      },
                                      (error) => {
                                        console.log(error);
                                      }
                                    );
                                } else {
                                  userData.imgpath = null;
                                  //配列にプッシュ
                                  array.push(userData);

                                  array = array.filter((item) => {
                                    if (item.group) {
                                      if (item.group.group_rank == null) {
                                        item.group.group_rank = 0;
                                      }
                                    }
                                    return item;
                                  });

                                  let result = array.sort(function (a, b) {
                                    return a.last_name_kana > b.last_name_kana
                                      ? 1
                                      : -1;
                                  });

                                  array.sort(function (a, b) {
                                    if (a.order === 0 && b.order !== 0) {
                                      return 1;
                                    } else if (a.order !== 0 && b.order === 0) {
                                      return -1;
                                    } else {
                                      return a.order - b.order;
                                    }
                                  });

                                  array.sort(function (a, b) {
                                    return (
                                      b.group.group_rank - a.group.group_rank
                                    );
                                  });

                                  under_ob.users = this.groupedUnder(array);

                                  this.csUnder_show = true;
                                  if (underlength == under_i) {
                                    this.csUnder_show = true;
                                  }
                                }
                              }
                            },
                          )
                          .catch(() => {})
                          .finally(() => {});
                });
              }
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              this.loader = false;
            });
        });
      }
    },
    controlList(id) {
      if (this.control_show == false) {
        this.loader = true;

        User.showGroupUsers(id)
          .then((response) => {
            if (response != null) {
              var length = response.data.data.group_users.length;
              var array = [];

              if (length == 0) {
                this.control_show = true;
              }

              response.data.data.group_users.forEach((ob, i) => {
                var userData = ob.user_data;
                      userData.introduction_com = "";
                      this.introduction.forEach((intro_ob) => {
                        if (ob.user_id == intro_ob.from_id) {
                          userData.introduction_com = intro_ob.content;
                        }
                      });

                      //プロフィール画像の挿入
                      User.showfile(ob.user_id, "key", "users")
                        .then(
                          (response) => {
                            if (response != "") {
                              this.images = response.data.data;
                              if (this.images.length > 0) {
                                let path = this.images[0].storage_file_name;
                                let fileApiPass = "/api/v1/file/" + path;

                                Api()
                                  .get(fileApiPass, {
                                    responseType: "arraybuffer",
                                  })
                                  .then(
                                    (response) => {
                                      let blob = new Blob([response.data], {
                                        type: response.headers["content-type"],
                                      });
                                      let url = window.URL || window.webkitURL;
                                      let src = url.createObjectURL(blob);

                                      userData.imgpath = src;
                                      //配列にプッシュ
                                      array.push(userData);

                                      array = array.filter((item) => {
                                        if (item.group) {
                                          if (item.group.group_rank == null) {
                                            item.group.group_rank = 0;
                                          }
                                        }
                                        return item;
                                      });
                                      let result = array.sort(function (a, b) {
                                        return a.last_name_kana >
                                          b.last_name_kana
                                          ? 1
                                          : -1;
                                      });

                                      array.sort(function (a, b) {
                                        if (a.order === 0 && b.order !== 0) {
                                          return 1;
                                        } else if (a.order !== 0 && b.order === 0) {
                                          return -1;
                                        } else {
                                          return a.order - b.order;
                                        }
                                      });

                                      array.sort(function (a, b) {
                                        return (
                                          b.group.group_rank -
                                          a.group.group_rank
                                        );
                                      });

                                      this.control_array = this.grouped(array);

                                      this.control_show = true;
                                    }
                                  );
                              } else {
                                userData.imgpath = null;
                                //配列にプッシュ
                                array.push(userData);

                                array = array.filter((item) => {
                                  if (item.group) {
                                    if (item.group.group_rank == null) {
                                      item.group.group_rank = 0;
                                    }
                                  }
                                  return item;
                                });
                                let result = array.sort(function (a, b) {
                                  return a.last_name_kana > b.last_name_kana
                                    ? 1
                                    : -1;
                                });

                                array.sort(function (a, b) {
                                  if (a.order === 0 && b.order !== 0) {
                                    return 1;
                                  } else if (a.order !== 0 && b.order === 0) {
                                    return -1;
                                  } else {
                                    return a.order - b.order;
                                  }
                                });

                                array.sort(function (a, b) {
                                  return (
                                    b.group.group_rank - a.group.group_rank
                                  );
                                });

                                this.control_array = this.grouped(array);

                                this.control_show = true;
                              }
                            }
                          }
                        )
                        .catch(() => {})
                        .finally(() => {});
              });
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.loader = false;
          });

        if (this.controlUnder_array != "") {
          this.controlUnder_show = true;
        }

        var underlength = this.controlUnder_array.length - 1;
        this.controlUnder_array.forEach((under_ob, under_i) => {
          User.showGroupUsers(under_ob.id)
            .then((response) => {
              if (response != null) {
                var length = response.data.data.group_users.length;
                var array = [];

                if (length == 0) {
                  this.controlUnder_array[under_i].users = [];
                }

                if (underlength == under_i) {
                  if (length == 0) {
                    this.controlUnder_show = true;
                  }
                }

                response.data.data.group_users.forEach((ob, i) => {
                  var userData = ob.user_data;
                        userData.introduction_com = "";
                        this.introduction.forEach((intro_ob) => {
                          if (ob.user_id == intro_ob.from_id) {
                            userData.introduction_com = intro_ob.content;
                          }
                        });

                        //プロフィール画像の挿入
                        User.showfile(ob.user_id, "key", "users")
                          .then(
                            (response) => {
                              if (response != "") {
                                this.images = response.data.data;
                                if (this.images.length > 0) {
                                  let path = this.images[0].storage_file_name;
                                  let fileApiPass = "/api/v1/file/" + path;

                                  Api()
                                    .get(fileApiPass, {
                                      responseType: "arraybuffer",
                                    })
                                    .then(
                                      (response) => {
                                        let blob = new Blob([response.data], {
                                          type: response.headers[
                                            "content-type"
                                          ],
                                        });
                                        let url =
                                          window.URL || window.webkitURL;
                                        let src = url.createObjectURL(blob);

                                        userData.imgpath = src;
                                        //配列にプッシュ
                                        array.push(userData);

                                        array = array.filter((item) => {
                                          if (item.group) {
                                            if (item.group.group_rank == null) {
                                              item.group.group_rank = 0;
                                            }
                                          }
                                          return item;
                                        });
                                        let result = array.sort(function (
                                          a,
                                          b
                                        ) {
                                          return a.last_name_kana >
                                            b.last_name_kana
                                            ? 1
                                            : -1;
                                        });

                                        array.sort(function (a, b) {
                                          if (a.order === 0 && b.order !== 0) {
                                            return 1;
                                          } else if (a.order !== 0 && b.order === 0) {
                                            return -1;
                                          } else {
                                            return a.order - b.order;
                                          }
                                        });

                                        array.sort(function (a, b) {
                                          return (
                                            b.group.group_rank -
                                            a.group.group_rank
                                          );
                                        });

                                        this.controlUnder_array[under_i].users =
                                          this.groupedUnder(array);

                                        this.controlUnder_show = true;
                                        if (underlength == under_i) {
                                          this.controlUnder_show = true;
                                        }
                                      }
                                    );
                                } else {
                                  userData.imgpath = null;
                                  //配列にプッシュ
                                  array.push(userData);

                                  array = array.filter((item) => {
                                    if (item.group) {
                                      if (item.group.group_rank == null) {
                                        item.group.group_rank = 0;
                                      }
                                    }
                                    return item;
                                  });
                                  let result = array.sort(function (a, b) {
                                    return a.last_name_kana > b.last_name_kana
                                      ? 1
                                      : -1;
                                  });

                                  array.sort(function (a, b) {
                                    if (a.order === 0 && b.order !== 0) {
                                      return 1;
                                    } else if (a.order !== 0 && b.order === 0) {
                                      return -1;
                                    } else {
                                      return a.order - b.order;
                                    }
                                  });

                                  array.sort(function (a, b) {
                                    return (
                                      b.group.group_rank - a.group.group_rank
                                    );
                                  });
                                  this.controlUnder_array[under_i].users =
                                    this.groupedUnder(array);

                                  this.controlUnder_show = true;
                                  if (underlength == under_i) {
                                    this.controlUnder_show = true;
                                  }
                                }
                              }
                            }
                          )
                          .catch(() => {})
                          .finally(() => {});
                });
              }
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              this.loader = false;
            });
        });
      }
    },
    areaList(id) {
      if (this.areaService_show == false) {
        this.loader = true;

        User.showGroupUsers(id)
          .then((response) => {
            if (response != null) {
              var length = response.data.data.group_users.length;
              var array = [];

              if (length == 0) {
                this.areaService_show = true;
              }

              response.data.data.group_users.forEach((ob, i) => {
                var userData = ob.user_data;
                      userData.introduction_com = "";
                      this.introduction.forEach((intro_ob) => {
                        if (ob.user_id == intro_ob.from_id) {
                          userData.introduction_com = intro_ob.content;
                        }
                      });

                      //プロフィール画像の挿入
                      User.showfile(ob.user_id, "key", "users")
                        .then(
                          (response) => {
                            if (response != "") {
                              this.images = response.data.data;
                              if (this.images.length > 0) {
                                let path = this.images[0].storage_file_name;
                                let fileApiPass = "/api/v1/file/" + path;

                                Api()
                                  .get(fileApiPass, {
                                    responseType: "arraybuffer",
                                  })
                                  .then(
                                    (response) => {
                                      let blob = new Blob([response.data], {
                                        type: response.headers["content-type"],
                                      });
                                      let url = window.URL || window.webkitURL;
                                      let src = url.createObjectURL(blob);

                                      userData.imgpath = src;
                                      //配列にプッシュ
                                      array.push(userData);

                                      array = array.filter((item) => {
                                        if (item.group) {
                                          if (item.group.group_rank == null) {
                                            item.group.group_rank = 0;
                                          }
                                        }
                                        return item;
                                      });
                                      let result = array.sort(function (a, b) {
                                        return a.last_name_kana >
                                          b.last_name_kana
                                          ? 1
                                          : -1;
                                      });

                                      array.sort(function (a, b) {
                                        if (a.order === 0 && b.order !== 0) {
                                          return 1;
                                        } else if (a.order !== 0 && b.order === 0) {
                                          return -1;
                                        } else {
                                          return a.order - b.order;
                                        }
                                      });

                                      array.sort(function (a, b) {
                                        return (
                                          b.group.group_rank -
                                          a.group.group_rank
                                        );
                                      });

                                      this.areaService_array =
                                        this.grouped(array);
                                      this.areaService_show = true;
                                    }
                                  );
                              } else {
                                userData.imgpath = null;
                                //配列にプッシュ
                                array.push(userData);

                                array = array.filter((item) => {
                                  if (item.group) {
                                    if (item.group.group_rank == null) {
                                      item.group.group_rank = 0;
                                    }
                                  }
                                  return item;
                                });


                                let result = array.sort(function (a, b) {
                                  return a.last_name_kana > b.last_name_kana
                                    ? 1
                                    : -1;
                                });

                                array.sort(function (a, b) {
                                      if (a.order === 0 && b.order !== 0) {
                                        return 1;
                                      } else if (a.order !== 0 && b.order === 0) {
                                        return -1;
                                      } else {
                                        return a.order - b.order;
                                      }
                                    });

                                array.sort(function (a, b) {
                                  return (
                                    b.group.group_rank - a.group.group_rank
                                  );
                                });

                                this.areaService_array = this.grouped(array);

                                this.areaService_show = true;
                              }
                            }
                          }
                        )
                        .catch(() => {})
                        .finally(() => {});
              });
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.loader = false;
          });

        if (this.areaServiceUnder_array == "") {
          this.areaServiceUnder_show = true;
        }

        var underlength = this.areaServiceUnder_array.length - 1;
        this.areaServiceUnder_array.forEach((under_ob, under_i) => {
          User.showGroupUsers(under_ob.id)
            .then((response) => {
              if (response != null) {
                var length = response.data.data.group_users.length;
                var array = [];

                if (length == 0) {
                  this.areaServiceUnder_array[under_i].users = [];
                }

                if (underlength == under_i) {
                  if (length == 0) {
                    this.areaServiceUnder_show = true;
                  }
                }

                response.data.data.group_users.forEach((ob, i) => {
                  var userData = ob.user_data;
                        userData.introduction_com = "";
                        this.introduction.forEach((intro_ob) => {
                          if (ob.user_id == intro_ob.from_id) {
                            userData.introduction_com = intro_ob.content;
                          }
                        });

                        //プロフィール画像の挿入
                        User.showfile(ob.user_id, "key", "users")
                          .then(
                            (response) => {
                              if (response != "") {
                                this.images = response.data.data;
                                if (this.images.length > 0) {
                                  let path = this.images[0].storage_file_name;
                                  let fileApiPass = "/api/v1/file/" + path;

                                  Api()
                                    .get(fileApiPass, {
                                      responseType: "arraybuffer",
                                    })
                                    .then(
                                      (response) => {
                                        let blob = new Blob([response.data], {
                                          type: response.headers[
                                            "content-type"
                                          ],
                                        });
                                        let url =
                                          window.URL || window.webkitURL;
                                        let src = url.createObjectURL(blob);

                                        userData.imgpath = src;
                                        //配列にプッシュ
                                        array.push(userData);

                                        array = array.filter((item) => {
                                          if (item.group) {
                                            if (item.group.group_rank == null) {
                                              item.group.group_rank = 0;
                                            }
                                          }
                                          return item;
                                        });
                                        let result = array.sort(function (
                                          a,
                                          b
                                        ) {
                                          return a.last_name_kana >
                                            b.last_name_kana
                                            ? 1
                                            : -1;
                                        });

                                        array.sort(function (a, b) {
                                          if (a.order === 0 && b.order !== 0) {
                                            return 1;
                                          } else if (a.order !== 0 && b.order === 0) {
                                            return -1;
                                          } else {
                                            return a.order - b.order;
                                          }
                                        });

                                        array.sort(function (a, b) {
                                          return (
                                            b.group.group_rank -
                                            a.group.group_rank
                                          );
                                        });

                                        this.areaServiceUnder_array[
                                          under_i
                                        ].users = this.groupedUnder(array);

                                        this.areaServiceUnder_show = true;
                                        if (underlength == under_i) {
                                          this.areaServiceUnder_show = true;
                                        }
                                      }
                                    );
                                } else {
                                  userData.imgpath = null;
                                  //配列にプッシュ
                                  array.push(userData);

                                  array = array.filter((item) => {
                                    if (item.group) {
                                      if (item.group.group_rank == null) {
                                        item.group.group_rank = 0;
                                      }
                                    }
                                    return item;
                                  });
                                  let result = array.sort(function (a, b) {
                                    return a.last_name_kana > b.last_name_kana
                                      ? 1
                                      : -1;
                                  });

                                  array.sort(function (a, b) {
                                    if (a.order === 0 && b.order !== 0) {
                                      return 1;
                                    } else if (a.order !== 0 && b.order === 0) {
                                      return -1;
                                    } else {
                                      return a.order - b.order;
                                    }
                                  });

                                  array.sort(function (a, b) {
                                    return (
                                      b.group.group_rank - a.group.group_rank
                                    );
                                  });

                                  this.areaServiceUnder_array[under_i].users =
                                    this.groupedUnder(array);

                                  this.areaServiceUnder_show = true;
                                  if (underlength == under_i) {
                                    this.areaServiceUnder_show = true;
                                  }
                                }
                              }
                            }
                          )
                          .catch(() => {})
                          .finally(() => {});
                });
              }
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              this.loader = false;
            });
        });
      }
    },
    environmentList(id) {
      if (this.environmentService_show == false) {
        this.loader = true;

        User.showGroupUsers(id)
          .then((response) => {
            if (response != null) {
              var length = response.data.data.group_users.length;
              var array = [];

              if (length == 0) {
                this.environmentService_show = true;
              }

              response.data.data.group_users.forEach((ob, i) => {
                var userData = ob.user_data;
                      userData.introduction_com = "";
                      this.introduction.forEach((intro_ob) => {
                        if (ob.user_id == intro_ob.from_id) {
                          userData.introduction_com = intro_ob.content;
                        }
                      });

                      //プロフィール画像の挿入
                      User.showfile(ob.user_id, "key", "users")
                        .then(
                          (response) => {
                            if (response != "") {
                              this.images = response.data.data;
                              if (this.images.length > 0) {
                                let path = this.images[0].storage_file_name;
                                let fileApiPass = "/api/v1/file/" + path;

                                Api()
                                  .get(fileApiPass, {
                                    responseType: "arraybuffer",
                                  })
                                  .then(
                                    (response) => {
                                      let blob = new Blob([response.data], {
                                        type: response.headers["content-type"],
                                      });
                                      let url = window.URL || window.webkitURL;
                                      let src = url.createObjectURL(blob);

                                      userData.imgpath = src;
                                      //配列にプッシュ
                                      array.push(userData);

                                      array = array.filter((item) => {
                                        if (item.group) {
                                          if (item.group.group_rank == null) {
                                            item.group.group_rank = 0;
                                          }
                                        }
                                        return item;
                                      });
                                      let result = array.sort(function (a, b) {
                                        return a.last_name_kana >
                                          b.last_name_kana
                                          ? 1
                                          : -1;
                                      });

                                      array.sort(function (a, b) {
                                        if (a.order === 0 && b.order !== 0) {
                                          return 1;
                                        } else if (a.order !== 0 && b.order === 0) {
                                          return -1;
                                        } else {
                                          return a.order - b.order;
                                        }
                                      });

                                      array.sort(function (a, b) {
                                        return (
                                          b.group.group_rank -
                                          a.group.group_rank
                                        );
                                      });

                                      this.environmentService_array =
                                        this.grouped(array);

                                      this.environmentService_show = true;
                                    }
                                  );
                              } else {
                                userData.imgpath = null;
                                //配列にプッシュ
                                array.push(userData);

                                array = array.filter((item) => {
                                  if (item.group) {
                                    if (item.group.group_rank == null) {
                                      item.group.group_rank = 0;
                                    }
                                  }
                                  return item;
                                });
                                let result = array.sort(function (a, b) {
                                  return a.last_name_kana > b.last_name_kana
                                    ? 1
                                    : -1;
                                });

                                array.sort(function (a, b) {
                                  if (a.order === 0 && b.order !== 0) {
                                    return 1;
                                  } else if (a.order !== 0 && b.order === 0) {
                                    return -1;
                                  } else {
                                    return a.order - b.order;
                                  }
                                });

                                array.sort(function (a, b) {
                                  return (
                                    b.group.group_rank - a.group.group_rank
                                  );
                                });

                                this.environmentService_array =
                                  this.grouped(array);

                                this.environmentService_show = true;
                              }
                            }
                          }
                        )
                        .catch(() => {})
                        .finally(() => {});
              });
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.loader = false;
          });

        if (this.environmentServiceUnder_array != "") {
          this.environmentServiceUnder_show = true;
        }

        var underlength = this.environmentServiceUnder_array.length - 1;
        this.environmentServiceUnder_array.forEach((under_ob, under_i) => {
          User.showGroupUsers(under_ob.id)
            .then((response) => {
              if (response != null) {
                var length = response.data.data.group_users.length;
                var array = [];

                if (length == 0) {
                  this.environmentServiceUnder_array[under_i].users = [];
                }

                if (underlength == under_i) {
                  if (length == 0) {
                    this.environmentServiceUnder_show = true;
                  }
                }

                response.data.data.group_users.forEach((ob, i) => {
                  var userData = ob.user_data;
                        userData.introduction_com = "";
                        this.introduction.forEach((intro_ob) => {
                          if (ob.user_id == intro_ob.from_id) {
                            userData.introduction_com = intro_ob.content;
                          }
                        });

                        //プロフィール画像の挿入
                        User.showfile(ob.user_id, "key", "users")
                          .then(
                            (response) => {
                              if (response != "") {
                                this.images = response.data.data;
                                if (this.images.length > 0) {
                                  let path = this.images[0].storage_file_name;
                                  let fileApiPass = "/api/v1/file/" + path;

                                  Api()
                                    .get(fileApiPass, {
                                      responseType: "arraybuffer",
                                    })
                                    .then(
                                      (response) => {
                                        let blob = new Blob([response.data], {
                                          type: response.headers[
                                            "content-type"
                                          ],
                                        });
                                        let url =
                                          window.URL || window.webkitURL;
                                        let src = url.createObjectURL(blob);

                                        userData.imgpath = src;
                                        //配列にプッシュ
                                        array.push(userData);

                                        array = array.filter((item) => {
                                          if (item.group) {
                                            if (item.group.group_rank == null) {
                                              item.group.group_rank = 0;
                                            }
                                          }
                                          return item;
                                        });
                                        let result = array.sort(function (
                                          a,
                                          b
                                        ) {
                                          return a.last_name_kana >
                                            b.last_name_kana
                                            ? 1
                                            : -1;
                                        });

                                        array.sort(function (a, b) {
                                          if (a.order === 0 && b.order !== 0) {
                                            return 1;
                                          } else if (a.order !== 0 && b.order === 0) {
                                            return -1;
                                          } else {
                                            return a.order - b.order;
                                          }
                                        });

                                        array.sort(function (a, b) {
                                          return (
                                            b.group.group_rank -
                                            a.group.group_rank
                                          );
                                        });

                                        this.environmentServiceUnder_array[
                                          under_i
                                        ].users = this.groupedUnder(array);

                                        this.environmentServiceUnder_show = true;
                                        if (underlength == under_i) {
                                          this.environmentServiceUnder_show = true;
                                        }
                                      }
                                    );
                                } else {
                                  userData.imgpath = null;
                                  //配列にプッシュ
                                  array.push(userData);

                                  array = array.filter((item) => {
                                    if (item.group) {
                                      if (item.group.group_rank == null) {
                                        item.group.group_rank = 0;
                                      }
                                    }
                                    return item;
                                  });
                                  let result = array.sort(function (a, b) {
                                    return a.last_name_kana > b.last_name_kana
                                      ? 1
                                      : -1;
                                  });

                                  array.sort(function (a, b) {
                                      if (a.order === 0 && b.order !== 0) {
                                        return 1;
                                      } else if (a.order !== 0 && b.order === 0) {
                                        return -1;
                                      } else {
                                        return a.order - b.order;
                                      }
                                    });

                                  array.sort(function (a, b) {
                                    return (
                                      b.group.group_rank - a.group.group_rank
                                    );
                                  });

                                  this.environmentServiceUnder_array[
                                    under_i
                                  ].users = this.groupedUnder(array);

                                  this.environmentServiceUnder_show = true;
                                  if (underlength == under_i) {
                                    this.environmentServiceUnder_show = true;
                                  }
                                }
                              }
                            },
                          )
                          .catch(() => {})
                          .finally(() => {});
                });
              }
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              this.loader = false;
            });
        });
      }
    },
    recyclingList(id) {
      if (this.recycling_show == false) {
        this.loader = true;

        User.showGroupUsers(id)
          .then((response) => {
            if (response != null) {
              var length = response.data.data.group_users.length;
              var array = [];

              if (length == 0) {
                this.recycling_show = true;
              }
              response.data.data.group_users.forEach((ob, i) => {
                var userData = ob.user_data;
                        userData.introduction_com = "";
                        this.introduction.forEach((intro_ob) => {
                          if (ob.user_id == intro_ob.from_id) {
                            userData.introduction_com = intro_ob.content;
                          }
                        });

                        //プロフィール画像の挿入
                        User.showfile(ob.user_id, "key", "users")
                          .then(
                            (response) => {
                              if (response != "") {
                                this.images = response.data.data;
                                if (this.images.length > 0) {
                                  let path = this.images[0].storage_file_name;
                                  let fileApiPass = "/api/v1/file/" + path;
                                  Api()
                                    .get(fileApiPass, {
                                      responseType: "arraybuffer",
                                    })
                                    .then(
                                      (response) => {
                                        let blob = new Blob([response.data], {
                                          type: response.headers[
                                            "content-type"
                                          ],
                                        });
                                        let url =
                                          window.URL || window.webkitURL;
                                        let src = url.createObjectURL(blob);

                                        userData.imgpath = src;
                                        //配列にプッシュ
                                        array.push(userData);

                                        array = array.filter((item) => {
                                          if (item.group) {
                                            if (item.group.group_rank == null) {
                                              item.group.group_rank = 0;
                                            }
                                          }
                                          return item;
                                        });
                                         let result = array.sort(function (
                                          a,
                                          b
                                        ) {
                                          return a.last_name_kana >
                                            b.last_name_kana
                                            ? 1
                                            : -1;
                                        });

                                        array.sort(function (a, b) {
                                          if (a.order === 0 && b.order !== 0) {
                                            return 1;
                                          } else if (a.order !== 0 && b.order === 0) {
                                            return -1;
                                          } else {
                                            return a.order - b.order;
                                          }
                                        });

                                        array.sort(function (a, b) {
                                          return (
                                            b.group.group_rank -
                                            a.group.group_rank
                                          );
                                        });

                                        this.recycling_array =
                                          this.grouped(array);
                                        this.recycling_show = true;
                                      }
                                    );
                                } else {
                                  userData.imgpath = null;
                                  //配列にプッシュ
                                  array.push(userData);

                                  array = array.filter((item) => {
                                    if (item.group) {
                                      if (item.group.group_rank == null) {
                                        item.group.group_rank = 0;
                                      }
                                    }
                                    return item;
                                  });
                                  let result = array.sort(function (a, b) {
                                    return a.last_name_kana > b.last_name_kana
                                      ? 1
                                      : -1;
                                  });

                                  array.sort(function (a, b) {
                                    if (a.order === 0 && b.order !== 0) {
                                      return 1;
                                    } else if (a.order !== 0 && b.order === 0) {
                                      return -1;
                                    } else {
                                      return a.order - b.order;
                                    }
                                  });

                                  array.sort(function (a, b) {
                                    return (
                                      b.group.group_rank - a.group.group_rank
                                    );
                                  });

                                  this.recycling_array = this.grouped(array);

                                  this.recycling_show = true;
                                }
                              }
                            }
                          )
                          .catch(() => {})
                          .finally(() => {});
              });
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.loader = false;
          });

        var underlength = this.recyclingUnder_array.length - 1;

        this.recyclingUnder_array.forEach((under_ob, under_i) => {
          User.showGroupUsers(under_ob.id)
            .then((response) => {
              if (response != null) {
                var length = response.data.data.group_users.length;
                var array = [];

                if (length == 0) {
                  this.recyclingUnder_array[under_i].users = [];
                }

                if (underlength == under_i) {
                  if (length == 0) {
                    this.recyclingUnder_show = true;
                  }
                }

                response.data.data.group_users.forEach((ob, i) => {
                  var userData = ob.user_data;

                          userData.introduction_com = "";

                          this.introduction.forEach((intro_ob) => {
                            if (ob.user_id == intro_ob.from_id) {
                              userData.introduction_com = intro_ob.content;
                            }
                          });

                          //プロフィール画像の挿入
                          User.showfile(ob.user_id, "key", "users")
                            .then(
                              (response) => {
                                if (response != "") {
                                  this.images = response.data.data;

                                  if (this.images.length > 0) {
                                    let path = this.images[0].storage_file_name;
                                    let fileApiPass = "/api/v1/file/" + path;

                                    Api()
                                      .get(fileApiPass, {
                                        responseType: "arraybuffer",
                                      })
                                      .then(
                                        (response) => {
                                          let blob = new Blob([response.data], {
                                            type: response.headers[
                                              "content-type"
                                            ],
                                          });
                                          let url =
                                            window.URL || window.webkitURL;
                                          let src = url.createObjectURL(blob);

                                          userData.imgpath = src;
                                          //配列にプッシュ
                                          array.push(userData);

                                          array = array.filter((item) => {
                                            if (item.group) {
                                              if (
                                                item.group.group_rank == null
                                              ) {
                                                item.group.group_rank = 0;
                                              }
                                            }
                                            return item;
                                          });

                                          let result = array.sort(function (
                                            a,
                                            b
                                          ) {
                                            return a.last_name_kana >
                                              b.last_name_kana
                                              ? 1
                                              : -1;
                                          });

                                          array.sort(function (a, b) {
                                            if (a.order === 0 && b.order !== 0) {
                                              return 1;
                                            } else if (a.order !== 0 && b.order === 0) {
                                              return -1;
                                            } else {
                                              return a.order - b.order;
                                            }
                                          });

                                          array.sort(function (a, b) {
                                            return (
                                              b.group.group_rank -
                                              a.group.group_rank
                                            );
                                          });

                                          this.recyclingUnder_array[
                                            under_i
                                          ].users = this.groupedUnder(array);

                                          this.recyclingUnder_show = true;
                                          if (underlength == under_i) {
                                            this.recyclingUnder_show = true;
                                          }
                                        },
                                      );
                                  } else {
                                    userData.imgpath = null;
                                    //配列にプッシュ
                                    array.push(userData);

                                    array = array.filter((item) => {
                                      if (item.group) {
                                        if (item.group.group_rank == null) {
                                          item.group.group_rank = 0;
                                        }
                                      }
                                      return item;
                                    });

                                    let result = array.sort(function (a, b) {
                                      return a.last_name_kana > b.last_name_kana
                                        ? 1
                                        : -1;
                                    });

                                    array.sort(function (a, b) {
                                      if (a.order === 0 && b.order !== 0) {
                                        return 1;
                                      } else if (a.order !== 0 && b.order === 0) {
                                        return -1;
                                      } else {
                                        return a.order - b.order;
                                      }
                                    });

                                    array.sort(function (a, b) {
                                      return (
                                        b.group.group_rank - a.group.group_rank
                                      );
                                    });

                                    this.recyclingUnder_array[under_i].users =
                                      this.groupedUnder(array);

                                    this.recyclingUnder_show = true;
                                    if (underlength == under_i) {
                                      this.recyclingUnder_show = true;
                                    }
                                  }
                                }
                              }
                            )
                            .catch(() => {})
                            .finally(() => {});
                });
              }
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              this.loader = false;
            });
        });
      }
    },
    grouped(base) {
      let split = "";
      var ua = navigator.userAgent;
      if (
        ua.indexOf("iPhone") > 0 ||
        (ua.indexOf("Android") > 0 && ua.indexOf("Mobile") > 0)
      ) {
        split = 1;
      } else {
        split = 4;
      }
      const grouped_array = [];
      for (let i = 0; i < Math.ceil(base.length / split); i++) {
        let multiple_cnt = i * split; //3の倍数
        // (i * 3)番目から(i * 3 + 3)番目まで取得
        let result = base.slice(multiple_cnt, multiple_cnt + split);
        grouped_array.push(result);
      }
      return grouped_array;
    },
    groupedUnder(base) {
      let split = "";
      var ua = navigator.userAgent;
      if (
        ua.indexOf("iPhone") > 0 ||
        (ua.indexOf("Android") > 0 && ua.indexOf("Mobile") > 0)
      ) {
        split = 2;
      } else {
        split = 5;
      }
      const grouped_array = [];
      for (let i = 0; i < Math.ceil(base.length / split); i++) {
        let multiple_cnt = i * split; //3の倍数
        // (i * 3)番目から(i * 3 + 3)番目まで取得
        let result = base.slice(multiple_cnt, multiple_cnt + split);
        grouped_array.push(result);
      }
      return grouped_array;
    },
    htmlText(msg) {
      if (!msg) return;
      return msg !== "" && msg !== null ? msg.replace(/\r?\n/g, "<br>") : "";
    },
    getImage(id, i) {
      User.showfile(id, "key", "users")
        .then(
          (response) => {
            if (response != "") {
              this.images = response.data.data;

              let path = this.images[0].storage_file_name;
              let fileApiPass = "/api/v1/file/" + path;

              Api()
                .get(fileApiPass, { responseType: "arraybuffer" })
                .then(
                  (response) => {
                    let blob = new Blob([response.data], {
                      type: response.headers["content-type"],
                    });
                    let url = window.URL || window.webkitURL;
                    let src = url.createObjectURL(blob);
                    this.users[i].imgpath = src;
                    //console.log(this.users);
                  },
                  (error) => {
                    console.log(error);
                  }
                );
            }
          },
          (error) => {
            console.log(error);

            //this.$router.push({ name: 'Front Error' });
          }
        )
        .catch(() => {
          //this.$router.push({ name: 'Front Error Catch' });
        })
        .finally(() => {});
    },
    compareObjects(object1, object2, key) {
      const obj1 = object1[key].toUpperCase();

      const obj2 = object2[key].toUpperCase();

      if (obj1 < obj2) {
        return -1;
      }

      if (obj1 > obj2) {
        return 1;
      }

      return 0;
    },
    sortGroupName(csUnder_array) {
      let result = csUnder_array.sort((tempObj1, tempObj2) => {
        return this.compareObjects(tempObj1, tempObj2, "name");
      });

      return this.moveLastArrayElementToFirstIndex(result);
    },
    moveLastArrayElementToFirstIndex(this_array) {
      var new_array = new Array();

      new_array[0] = this_array[this_array.length - 1];

      for (let i = 1; i < this_array.length; i++) {
        new_array[i] = this_array[i - 1];
      }

      return new_array;
    },
    csGroupClone(groupParentId) {
      Groups.cgGroupList(groupParentId).then((response) => {
        if (response) {
          this.csGroupStaffs = response.data.data;
          console.log(this.csGroupStaffs);
        }
      });
    },

    userProfirleGetByUerId() {
      // MFUX-1451 役員・社員紹介ページの改修  @auther Elavarasan @data 21/04/2022  staticProductionData

      this.staticProductionData.forEach((elements) => {
        elements.forEach((element) => {
          //プロフィール画像の挿入
          User.showfile(element.id, "key", "users")
            .then((response) => {
              if (response.data.data.length > 0) {
                let path = response.data.data[0].storage_file_name;
                let fileApiPass = "/api/v1/file/" + path;

                Api()
                  .get(fileApiPass, {
                    responseType: "arraybuffer",
                  })
                  .then((response) => {
                    let blob = new Blob([response.data], {
                      type: response.headers["content-type"],
                    });
                    let url = window.URL || window.webkitURL;
                    let src = url.createObjectURL(blob);

                    element.imgpath = src;
                  });
              }
            })
            .catch(() => {})
            .finally(() => {});
        });
      });
    },
    getStaticId(){
      let group_name = ['エリアサービス部','CS営業部', '環境サービス部', '経営企画室'];

      User.group_list().then((res)=>{
        res.data.data.forEach(element => {
          group_name.forEach(el => {
            if (element.name === el) {
              User.get_group(element.id).then((data) => {
                data.data.data.group_users.forEach(el=>{
                  User.show(el.user_id).then((data)=>{
                    switch (data.data.data.user_code) {
                      case '103':
                        this.staticId.areaGroup.targetId = data.data.data.id;
                        break;
                      case '103-1':
                        this.staticId.areaGroup.id = data.data.data.id;
                        break;
                      case '518':
                        this.staticId.csGroup.targetId = data.data.data.id;
                        break;
                      case '518-1':
                        this.staticId.csGroup.id = data.data.data.id;
                        break;
                    
                      default:
                        break;
                    }
                  })
                })
              })
            }
          })
        });
      })
    },

    getChallageSdgsByUserId(id) {
      this.sdgsChallgeApproved = [];
      this.sdgsChallengeApprovedLoader = true;
      
      if(id === this.staticId.areaGroup.id){
        id = this.staticId.areaGroup.targetId;
      }
      if(id === this.staticId.csGroup.id){
        id = this.staticId.csGroup.targetId;
      }

      //プロフィール画像の挿入
      message
        .getChallangeSdgsByUserId(id, "challenge,sdgs", this.yearPeriod())
        .then((response) => {
          this.sdgsChallengeApprovedLoader = false;
          if (response.data.data.length > 0) {
            this.sdgsChallgeApproved = response.data.data.filter((e) => {
              
              if (this.IsJsonString(e.content)) {
                let contentSplit = JSON.parse(e.content);

                e.option = contentSplit.option;

                var content = `<ol class="list-number">`+
                  (contentSplit.content != '' && contentSplit.content !=  undefined && contentSplit.content !=  'undefined' ? `<li>${striptags(contentSplit.content)} </li>` : '') +
                  (contentSplit.content_1 != ''&& contentSplit.content_1 !=  undefined && contentSplit.content !=  'undefined' ? `<li> ${striptags(contentSplit.content_1)}</li>` :'')+
                  (contentSplit.content_2 != ''&& contentSplit.content_2 !=  undefined && contentSplit.content !=  'undefined' ? `<li>${striptags(contentSplit.content_2)}</li>`:'')+
                   `</ol>`;

                e.content = content;

              } else {

                e.content = `<ol class="list-number">`+
                  (e.content != '' && e.content !=  undefined && e.content !=  'undefined' ? `<li>${striptags(e.content)} </li>` : '') +
                   `</ol>`;
              }
              return e;
            });
          }
        })
        .catch(() => {})
        .finally(() => {});
    },
    yearPeriod() {
      let date = new Date();

      let currentMonth = date.getMonth() + 1;

      if (currentMonth > 9) {
        return date.getFullYear();
      }

      return date.getFullYear() - 1;
    },
    IsJsonString(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
  },
  mounted() {
    // this.getChallageSdgsByUserId();

    document.body.className = "page_staff";
    this.$nextTick(function () {
      $(function () {
        $(".menu_icon").click(function () {
          if ($(".menu_list_wrap").hasClass("show")) {
            $(".menu_list_wrap").removeClass("show");
          } else {
            $(".menu_list_wrap").addClass("show");
          }
        });
      });
      $(document).click(function (event) {
        if (!$(event.target).closest(".menu_wrap").length) {
          $(".menu_list_wrap").removeClass("show");
        }
      });
      $(function () {
        var content_height = $("#app").height(); // コンテンツの高さを取得
        var pagetop_show = content_height - 1500; // ページトップを出すの高さを設定
        $(window).on("scroll", function () {
          var scroll = $(window).scrollTop();
          var windowHeight = $(window).height();
          //ページトップ表示
          if (scroll > pagetop_show && scroll > 60) {
            $(".pagetop_wrap").addClass("show");
          } else {
            $(".pagetop_wrap").removeClass("show");
          }
        });
      });

      $(function () {
        $("#js-loader").delay(300).fadeOut(600);
        $(window).scrollTop(0);
      });
      //アコーディオンの動作
      $(function () {
        // ターゲットをクリックしたとき
        $(document).on("click", ".staff_item", function () {
          // 「active」の有無による切り分け
          if ($(this).hasClass("active")) {
            // アイテムのクラス「active」を削除
            $(".staff_item").removeClass("active");
            $(".functions_detail_wrapper").removeClass("open");
            $(".functions_detail_wrapper").css("height", 0);
            $(".functions_detail").hide();
          } else {
            // アイテムのクラス「active」を削除
            $(".staff_item").removeClass("active");
            // クリックしたタブメニューにクラス「active」を付与
            $(this).addClass("active");

            //表示ターゲット取得
            var wrapperNum = $(this).data("wrapper");
            var wrapper = "#function_wrapper_" + wrapperNum;

            //コンテンツの表示
            var itemmodal = $(this).data("modal");
            var itemmodal_height;
            $(wrapper)
              .find(".functions_detail")
              .each(function () {
                // 変数「itemmodal」と、同じクラスがついたコンテンツに
                if ($(this).attr("class").indexOf(itemmodal) != -1) {
                  // 表示
                  $(this).show();
                  itemmodal_height = $(this).outerHeight();
                } else {
                  // 隠す
                  $(this).hide();
                }
              });

            //表示
            if ($(wrapper).hasClass("open")) {
              $(".functions_detail_wrapper").removeClass("open");
              $(".functions_detail_wrapper").css("height", 0);
              $(wrapper).addClass("open");
              $(wrapper).css("height", itemmodal_height);
            } else {
              $(".functions_detail_wrapper").removeClass("open");
              $(".functions_detail_wrapper").css("height", 0);
              $(wrapper).addClass("open");
              $(wrapper).css("height", itemmodal_height);
            }
          }
        });
      });
    });
  },
  updated() {
    //console.log('アップデート');
  },
};
</script>

<style scoped>
.hieght-45 {
  height: 45px;
}

/* 外部CSSスタイルシートを追加する */
</style>
